import React from "react";
import { Box, BoxProps } from "rebass";
import { StyledLoader } from "./Loader.styles";

const Loader: React.NamedExoticComponent<BoxProps> = React.memo(props => {
  return (
    <Box as={StyledLoader} {...props}>
      <div />
      <div />
    </Box>
  );
});

export default Loader;
