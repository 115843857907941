/** Lib */
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify';

/** Services */
import UsersService from "@/services/users/users.service";

/** Types */
import { IAdminAndEventCreatorProfileFormProps } from "./AdminAndEventCreatorProfileForm.component";
import formatPhoneNumber from "@/util/formatPhone";

export default (props: IAdminAndEventCreatorProfileFormProps) => {
  const { onSubmitSuccess, onSubmitError, user } = props;
  /** State */
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean | null>(
    null
  );
  const [submitError, setSubmitError] = React.useState<boolean | null>(null);

  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      cellNumber: formatPhoneNumber(user.cellNumber) as string
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("First Name required."),
      lastName: yup.string().required("Last Name required."),
      cellNumber: yup
        .string()
        .matches(
          /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
          "Please use (xxx) xxx-xxxx format"
        )
        .required("Phone Number is required")
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        setLoading(true);
        await UsersService.updateById(props.user.id, {
          ...values,
          cellNumber: values.cellNumber.replace(/\D/g,'').substr(-10),
          active: true
        });
        setSubmitSuccess(true);
        setLoading(false);
        formikHelpers.resetForm({ values });
        toast('Profile updated.')
        onSubmitSuccess();
      } catch (err) {
        setSubmitSuccess(false);
        setSubmitError(err.toString());
        setLoading(false);
        onSubmitError();
        console.error(
          "AdminProfileForm.hooks: onSubmit: Error Updating User.",
          err
        );
      }
    }
  });

  const onResetClick = React.useCallback(() => {
    formik.resetForm();
  }, [formik]);

  return {
    formik,
    submitSuccess,
    submitError,
    loading,
    onResetClick
  };
};
