//========================================================================================
/*                                                                                      *
 * IMPORTS                                                                              *
 *                                                                                      */
//========================================================================================

import React from "react";
import ReactSelect, { Props } from "react-select";
import { Text, Box, BoxProps } from "rebass";
import { reactSelectStyles, selectLabelStyles } from "./Select.styles.new";

//========================================================================================
/*                                                                                      *
 * TYPES                                                                                *
 *                                                                                      */
//========================================================================================
export type TSelectProps = Props<{ value: string; label: string }> & {
  options: Array<{ value: string; label: string }>;
  label: string;
  name: string;
  required?: boolean;
  error?: string;
  inputValue?: string;
  containerProps?: BoxProps;
  externalLabel?: boolean;
};

export type TSelectVal = { label: string; value: string } | undefined;

//========================================================================================
/*                                                                                      *
 * COMPONENT                                                                           *
 *                                                                                      */
//========================================================================================

const Select: React.FC<TSelectProps> = (props) => {
  const { containerProps, externalLabel = true, disabled, ...rest } = props;
  return (
    <Box
      className="select-container"
      {...containerProps}
      css={{
        position: "relative",
      }}
    >
      {rest.label && (
        <Text
          as="p"
          variant="sectionHeading"
          sx={selectLabelStyles(externalLabel)}
        >
          {rest.label}
        </Text>
      )}
      {rest.required && (
        <Text
          as="span"
          color="danger"
          sx={{
            position: "absolute",
            bottom: 14,
            right: 12,
            zIndex: 1,
            fontSize: 18
          }}
        >
          *
        </Text>
      )}
      <ReactSelect
        {...rest}
        isDisabled={disabled}
        styles={reactSelectStyles(props.error, externalLabel, disabled)}
      />
    </Box>
  );
};

export default Select;
