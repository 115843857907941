import { SxStyleProp } from "rebass";

export const tableStyles: Styles.CSSProp = {
  "table > tbody > tr": {
    cursor: "auto"
  }
};

export const borderStyles: SxStyleProp = {
  borderStyle: "solid",
  borderBottomWidth: "0px",
  borderTopWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
  borderTopColor: "#e3e3e3"
};
