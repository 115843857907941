import React from "react";
import isSimpleObject from "@/util/isSimpleObject";

function useMergeState<T>(initialState?: T) {
  const [mergeState, setMergeState] = React.useState<T>(
    initialState || ({} as T)
  );

  const setState = React.useCallback((newState: Partial<T>) => {
    if (isSimpleObject(newState)) {
      setMergeState((state: any) =>
        (state as T) ? { ...state, ...newState } : newState
      );
    }
  }, []);

  return [mergeState, setState] as [T | undefined, typeof setMergeState];
}

export default useMergeState;
