/** Lib */
import React from "react";

/** UI */
import { Box, Heading, Flex, Button } from "rebass";
import Input from "@/ui/common/form-elements/input/Input.common";
import Loader from "@/ui/common/loader/Loader.common";

/** Hooks */
import hooks from "./ManageBusiness.hooks";

/** Styles */
import { Container } from "@common/responsive";
import { tableStyles } from "./ManageBusiness.styles";

const ManageBusiness: React.FC<Router.RouteComponentProps> = (props) => {
  const { businessTable, formik, isLoading } = hooks(props);
  const { handleSubmit } = formik;

  return (
    <Container pt={4}>
      <Heading as="h1" variant="medium.center">
        Manage Business Entities
      </Heading>
      <Flex my={4}>
        <Box as="form" width={'100%'} onSubmit={handleSubmit as any}>
          <Flex justifyContent="space-between">
            <Flex
              sx={{
                alignItems: "center",
              }}
            >
              <Input
                containerProps={{
                  sx: {
                    position: "relative",
                    ".input-error": {
                      position: "absolute",
                    },
                    mr: 2
                  },
                }}
                name="accountName"
                label="Account Name"
                required
                value={formik.values.accountName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                containerProps={{
                  sx: {
                    position: "relative",
                    ".input-error": {
                      position: "absolute",
                    },
                    mr: 2
                  },
                }}
                name="address"
                label="Address"
                required
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                containerProps={{
                  sx: {
                    position: "relative",
                    ".input-error": {
                      position: "absolute",
                    },
                    mr: 2
                  },
                }}
                name="primaryEmail"
                label="Primary Email"
                required
                value={formik.values.primaryEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                containerProps={{
                  sx: {
                    position: "relative",
                    ".input-error": {
                      position: "absolute",
                    },
                  },
                }}
                name="phone"
                label="Phone Number"
                required
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

            </Flex>
            <Button
              alignSelf="flex-end"
              type="submit"
              disabled={isLoading}
            >
                {isLoading ? (
                  <Loader height="15px" width="15px" m="0 auto" />
                ) : (
                  "Add a Business Entity"
                )}
            </Button>
          </Flex>
        </Box>
      </Flex>
      <Heading variant="small" as="h2" mb={3}>
        Business Entities
      </Heading>

      <Box as="table" {...businessTable.getTableProps()} sx={tableStyles}>
        <thead>
          {businessTable.headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    (column as any).getSortByToggleProps()
                  )}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...businessTable.getTableBodyProps()}>
          {businessTable.rows.map((row, i) => {
            businessTable.prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Box>
    </Container>
  );
};

export default ManageBusiness;
