/** Lib */
import React from "react";
import * as firebase from "firebase/app";

/** Constants */
import { USER_ROLES } from "@/stores/auth/auth.constants";

/** Hooks */
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

export default (props: Router.RouteComponentProps) => {
  const user = useSubscribeToOwnUser();

  const [params, setParams] = React.useState<{
    role: AuthUserRoles;
    email: string;
  }>();

  const [loading, setLoading] = React.useState(false);

  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>();

  React.useEffect(() => {
    const _params = new URL(window.location.href).searchParams;
    const role = _params.get("role");
    const email = _params.get("email");

    if (!firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser?.getIdTokenResult()
        .then(res => {
          console.log("user token result", res);
        });
      if (
        !role ||
        !email ||
        ![
          USER_ROLES.admin,
          USER_ROLES.brandAmbassador,
          USER_ROLES.eventCreator
        ].includes(role as AuthUserRoles)
      ) {
        console.error("Invalid Role.", role, window.location.href);
        return props.history.push("/");
      }

      setLoading(true);
      setParams({ role: role as AuthUserRoles, email });

      (async () => {
        try {
          await firebase
            .auth()
            .signInWithEmailLink(email, window.location.href);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      })();
    }
  }, [props.history]);

  const onSubmitSuccess = React.useCallback(() => {
    setSubmitSuccess(true);
  }, []);

  const onSubmitError = React.useCallback(() => {
    setSubmitSuccess(false);
  }, []);

  return {
    params,
    user,
    submitSuccess,
    onSubmitSuccess,
    onSubmitError,
    loading
  };
};
