/** Constants */
import {
  SET_EVENTS,
  SET_OWN_EVENTS,
  SET_SUBSCRIBED_TO_ALL,
  SET_SUBSCRIBED_TO_OWN,
  SET_SUBSCRIBED_TO_OWN_AMBASSADOR,
  SET_OWN_AMBASSADOR_EVENTS
} from "./events.constants";

/** Types */
import { EventState, EventAction } from "./events.types";

export default (state: EventState, action: EventAction<any>) => {
  switch (action.type) {
    case SET_EVENTS: {
      return {
        ...state,
        allEvents: action.payload
      };
    }
    case SET_OWN_EVENTS: {
      return {
        ...state,
        ownEvents: action.payload
      };
    }
    case SET_OWN_AMBASSADOR_EVENTS: {
      return {
        ...state,
        ownAmbassadorEvents: {
          ...state.ownAmbassadorEvents,
          ...action.payload
        }
      };
    }
    case SET_SUBSCRIBED_TO_ALL: {
      return {
        ...state,
        subscribedToAll: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_OWN: {
      return {
        ...state,
        subscribedToOwn: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_OWN_AMBASSADOR: {
      return {
        ...state,
        subscribedToOwnAmbassador: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
