/** Lib */
import * as yup from "yup";

/** Constants */
import { USER_ROLES } from "@/stores/auth/auth.constants";

export const firestoreUserSchema = yup.object().shape({
  id: yup.string(),
  active: yup.boolean(),
  stars: yup.number(),
  firstName: yup.string(),
  lastName: yup.string(),
  role: yup
    .string()
    .oneOf([USER_ROLES.admin, USER_ROLES.brandAmbassador, USER_ROLES.eventCreator, USER_ROLES.superAdmin]),
  streetAddress: yup.string(),
  streetAddress2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipCode: yup.string(),
  cellNumber: yup.string(),
  email: yup.string(),
  birthDate: yup.object({
    day: yup.number(),
    month: yup.number(),
    year: yup.number(),
  }),
  hairColor: yup.string(),
  height: yup.string(),
  bilingual: yup.boolean(),
  languages: yup.array(yup.string()),
  willingToTravelDistance: yup.string(),
  ethnicity: yup.string(),
  instagram: yup.string(),
  textOptIn: yup.boolean(),
  emailOptIn: yup.boolean(),
  avatar: yup.string(),
  business: yup.string(),
});

export const adminFormSchema = yup.object().shape({
  firstName: yup.string().required("First Name required."),
  lastName: yup.string().required("Last Name required."),
  cellNumber: yup
    .string()
    .matches(
      /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
      "Please use (xxx) xxx-xxxx format"
    )
    .required("Phone Number is required"),
});

export const ambassadorFormSchema = adminFormSchema.concat(
  yup.object().shape({
    streetAddress: yup.string().required(),
    city: yup.string().required(),
    state: yup
      .object()
      .shape({
        value: yup.string().required("State is required."),
        label: yup.string(),
      })
      .required(),
    zipCode: yup
      .string()
      .matches(/^\d{5}$/, "Zip Code must be a 5 digit number.")
      .required("Zip Code is required."),
    hairColor: yup.string().required(),
    height: yup.string().required(),
    // bilingual: yup
    //   .object()
    //   .shape({
    //     value: yup.string().required("Required."),
    //     label: yup.string()
    //   })
    //   .required(),
    languages: yup
      .array(
        yup.object().shape({
          value: yup.string().required("Select at least one language."),
          label: yup.string(),
        })
      )
      .required(),
    willingToTravelDistance: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string(),
      })
      .required(),
    ethnicity: yup.string(),
    password: yup.string().matches(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,25}$/,
      "Password must be between 8 and 25 characters, contain 1 lowercase, 1 uppercase, 1 numeric, and 1 special character: !@#$%^&*"
    ),
  })
);
