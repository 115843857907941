//========================================================================================
/*                                                                                      *
 * APPROVE EVENT HOOKS                                                                  *
 *                                                                                      */
//========================================================================================

/**
 * * IMPORTS
 */
import React from "react";
import { useFormik } from "formik";
import eventsService from "@/services/events/events.service";
import { toast } from "react-toastify";
import { statuses } from "@/stores/events/events.constants";
import { firestoreEventProtectedSchema } from "@schemas/events/events.schemas";
import { TFirestoreEvent } from "@/typings/events/events.types";

/**
 * * EXPORT
 */
export default (props: Router.RouteComponentProps<{ id: string }>) => {
  const {
    match: {
      params: { id },
    },
    location: {
      state: theEvent
    },
    history,
  } = props;

  /**
   *
   * * STATE
   *
   */
  const [loading, setLoading] = React.useState(false);

  const isPastEvent: boolean | undefined = React.useMemo(() => {
    const event = theEvent as TFirestoreEvent;
    return event && new Date(event.eventStartTime.seconds * 1000) < new Date();
  }, [theEvent]);

  const formik = useFormik({
    initialValues: {
      budget: "",
      brandAmbassadorCount: "",
      actualBarSpend: "",
    },
    validationSchema: firestoreEventProtectedSchema,
    onSubmit: async (values, formikContext) => {
      try {
        setLoading(true);
        const eventData = await eventsService.getById(id);
        if (new Date((eventData.data() as TFirestoreEvent).eventStartTime.seconds * 1000) < new Date()) {
          toast('Cannot approve PAST event.');
          setLoading(false);
          return;
        }
        await eventsService.updateEventProtected(id, values);
        await eventsService.update(id, { status: statuses.approved });
        setLoading(false);
        toast("Event Approved!");
        history.push(`/events/${id}`);
      } catch (err) {
        setLoading(false);
        toast("Error: " + err.toString());
        formikContext.resetForm();
        console.error("Error updating protected event fields", err);
      }
    },
  });

  /**
   *
   * * HANDLERS
   *
   */
  const onCancelClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return { formik, onCancelClick, loading, isPastEvent };
};
