/** Types */
import { SxStyleProp } from "rebass";

export const radioContainerStyles: SxStyleProp = {
  paddingLeft: 0
};

export const radioInputStyles: SxStyleProp = {
  opacity: 0,
  width: 0,
  height: 0,
  "&:focus+label": {
    "&:before": {
      backgroundColor: "#e0e0e0"
    }
  },
  "&:checked + label:before": {
    borderColor: "primary",
    backgroundColor: "primary"
  },
  "&:checked + label:after": {
    borderColor: "primary",
    backgroundImage:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAAQAgMAAADsa5zLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTP///////////waf0AoAAAAEdFJOUwBG9tQE3MceAAAAVUlEQVQoz2NgGLKA0QGIDwDxBSCeAMEYgAWI2YCK2CagYgwgDcRSDhgYbAkKzsSKGdgakCyY6ADES7BiiCkgJ4PYyybgxAhQAsRZDrgxCpDEg4cAAAAp2ibhZRGLHgAAAABJRU5ErkJggg==)",
    backgroundColor: "primary",
    backgroundPosition: "left center",
    animationName: "checkbox",
    animationDuration: "320ms",
    animationTimingFunction: "steps(9)",
    animationFillMode: "forwards",
    animationIterationCount: 1
  }
};

export const radioLabelStyles = (disabled?: boolean): SxStyleProp => ({
  display: "inline-block",
  opacity: disabled ? 0.65 : 1,
  cursor: "pointer",
  position: "relative",
  paddingLeft: "25px",
  minWidth: "17px",
  minHeight: "17px",
  fontSize: "11px",
  lineHeight: "20px",
  marginBottom: 0,
  "&:before": {
    content: '""',
    boxSizing: "border-box",
    display: "inline-block",
    width: "16px",
    height: "16px",
    marginRight: "10px",
    position: "absolute",
    left: 0,
    border: "1px solid #c9c9c9",
    backgroundColor: "white",
    top: "1.4px",
    borderRadius: "100%",
    transition:
      "border 140ms linear 0s,color 140ms linear 0s,background-color 140ms linear 0s"
  },
  "&:after": {
    content: '""',
    position: "absolute",
    top: "1px",
    left: 0,
    borderRadius: '100%',
    borderRight: "0 solid transparent",
    borderBottom: "0 solid transparent",
    width: "16px",
    height: "16px",
    overflow: "hidden"
  },
  transition:
    "border 140ms linear 0s,color 140ms linear 0s,background-color 140ms linear 0s"
});
