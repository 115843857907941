/** Constants */
import {
  SET_SUBSCRIBED_TO_ALL,
  SET_SUBSCRIBED_TO_OWN,
  SET_SUBSCRIBED_TO_AMBASSADORS,
  SET_SUBSCRIBED_TO_EVENT_CREATORS,
  SET_ALL,
  SET_AMBASSADORS,
  SET_EVENT_CREATORS,
  SET_OWN
} from "./users.constants";

/** Types */
import { IUsersState, TUsersAction } from "./users.types";

export default (state: IUsersState, action: TUsersAction<any>): IUsersState => {
  switch (action.type) {
    case SET_ALL: {
      return {
        ...state,
        allUsers: action.payload
      };
    }
    case SET_OWN: {
      return {
        ...state,
        ownUser: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_ALL: {
      return {
        ...state,
        subscribedToAll: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_OWN: {
      return {
        ...state,
        subscribedToOwn: action.payload
      };
    }
    case SET_AMBASSADORS: {
      return {
        ...state,
        ambassadors: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_AMBASSADORS: {
      return {
        ...state,
        subscribedToAmbassadors: action.payload
      };
    }
    case SET_EVENT_CREATORS: {
      return {
        ...state,
        eventCreators: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_EVENT_CREATORS: {
      return {
        ...state,
        subscribedToEventCreators: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
