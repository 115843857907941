/** Lib */
import React from "react";

/** UI */
import { Heading, Flex } from "rebass";
import { Container } from "@common/responsive";
import Table from "@common/table/Table.common";
import Card from "@/ui/common/card/Card.common";
import AmbassadorDashboardFilters from "@components/dashboard/AmbassadorDashboardFilters.component";

/** Hooks */
import hooks from "./AmbassadorDashboard.hooks";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
// import CheckboxTable from "@/ui/common/checkbox-table/CheckboxTable.common";
import Loader from "@/ui/common/loader/Loader.common";

//import styles
import './AmbassadorDashboard.styles.scss';

const AmbassadorDashboard: React.FC<Router.RouteComponentProps> = props => {
  const {
    ownUser,
    columns,
    invitedColumns,
    events,
    invitedEvents,
    acceptedEvents,
    showFilterState,
    onRowClick,
    onFilterCheckboxChange,
    onSearchInputChange,
    loading,
    searchTerm
  } = hooks(props);

  return (
    <Container pt={4} className="dashboard ambassador">
      {ownUser ? (
        <>
          <AmbassadorDashboardFilters
            onSearchInputChange={onSearchInputChange}
            onFilterCheckboxChange={onFilterCheckboxChange}
            showFilterState={showFilterState}
            searchTerm={searchTerm}
            user={ownUser}
          />

          <Card p={[3, 3, 4]}>
            {!loading ? (
              <>
                {invitedEvents && showFilterState.invited && (
                  <>
                    <Heading variant="small" mb={3}>
                      Your Event Invitations
                    </Heading>

                    <Table<TFirestoreEvent>
                      columns={invitedColumns}
                      tableData={invitedEvents}
                      onRowClick={onRowClick}
                      filterValue={searchTerm}
                      css={{
                        minHeight: "375px"
                      }}
                    />
                  </>
                )}
                {acceptedEvents && showFilterState.accepted && (
                  <>
                    <Heading variant="small" mb={3}>
                      Accepted Invitations (Awaiting MTWO Confirmation)
                    </Heading>

                    <Table<TFirestoreEvent>
                      columns={columns}
                      tableData={acceptedEvents}
                      onRowClick={onRowClick}
                      filterValue={searchTerm}
                      css={{
                        minHeight: "375px"
                      }}
                    />
                  </>
                )}
                {events?.confirmedEvents && showFilterState.confirmed && (
                  <>
                    <Flex mb={3} flexWrap="wrap" justifyContent="space-between">
                      <Heading variant="small">Confirmed Events</Heading>
                    </Flex>

                    <Table<TFirestoreEvent>
                      columns={columns}
                      tableData={events.confirmedEvents}
                      onRowClick={onRowClick}
                      filterValue={searchTerm}
                      css={{
                        minHeight: "375px"
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <Flex justifyContent="center">
                <Loader className="la-3x" />
              </Flex>
            )}
          </Card>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      )}
    </Container>
  );
};

export default AmbassadorDashboard;
