//========================================================================================
/*                                                                                      *
 * IMPORTS                                                                              *
 *                                                                                      */
//========================================================================================

/** Lib */
import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

/** Services */
import UsersService from "@/services/users/users.service";

/** Types */
import { IAmbassadorProfileFormProps } from "./AmbassadorProfileForm.component";
import { ambassadorFormSchema } from "@schemas/users/users.schemas";
import {
  STATE_OPTIONS,
  WILLING_TO_TRAVEL_OPTIONS,
  // BILINGUAL_OPTIONS,
} from "./AmbassadorProfileForm.constants";
import { ValueType } from "react-select";
import { statesByName } from "@/util/states";

//========================================================================================
/*                                                                                      *
 * EXPORT                                                                               *
 *                                                                                      */
//========================================================================================

export default (props: IAmbassadorProfileFormProps) => {
  const { onSubmitSuccess, onSubmitError, user } = props;

  /**
   *
   * * REACT STATE
   *
   */
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean | null>(
    null
  );
  const [submitError, setSubmitError] = React.useState<boolean | null>(null);

  const [loading, setLoading] = React.useState(false);

  /**
   *
   * * FORMIK HOOKS
   *
   */
  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      cellNumber: user.cellNumber,
      streetAddress: user.streetAddress,
      city: user.city,
      state: user.state
        ? STATE_OPTIONS.find((opt) => {
          const val1 = user.state.toUpperCase();
          return opt.value === val1 || opt.value === (statesByName as any)[val1]
        })
        : undefined,
      zipCode: user.zipCode,
      hairColor: user.hairColor,
      height: user.height,
      // bilingual: user.bilingual
      //   ? { value: "yes", label: "Yes" }
      //   : { value: "no", label: "No" },
      languages: Array.isArray(user.languages)
        ? user.languages.map((lang) => ({ value: lang, label: lang }))
        : [{ value: "English", label: "English " }],
      willingToTravelDistance: user.willingToTravelDistance
        ? WILLING_TO_TRAVEL_OPTIONS.find(
            (opt) => opt.value === user.willingToTravelDistance
          )
        : undefined,
      ethnicity: user.ethnicity,
    },
    validationSchema: ambassadorFormSchema,
    onSubmit: async (values, formikHelpers) => {
      try {
        setLoading(true);
        await UsersService.updateById(props.user.id, {
          ...values,
          state: (values.state as any).value,
          bilingual: values.languages.length > 1 ? true : false,
          languages: values.languages.map((lang) => lang.value),
          willingToTravelDistance: (values.willingToTravelDistance as any)
            .value,
          active: true,
          cellNumber: values.cellNumber.replace(/\D/g, "").substr(-10),
        });
        setSubmitSuccess(true);
        setLoading(false);
        onSubmitSuccess();
        formikHelpers.resetForm({ values });
        toast("Profile updated.");
      } catch (err) {
        setSubmitSuccess(false);
        setSubmitError(err.toString());
        setLoading(false);
        onSubmitError();
        console.error(
          "AmbassadorProfileForm.hooks: onSubmit: Error Updating User.",
          err
        );
      }
    },
  });

  /**
   *
   * * EVENT HANDLERS
   *
   */

  const onResetClick = React.useCallback(() => {
    formik.resetForm();
  }, [formik]);

  const onLanguageChange = React.useCallback(
    (
      val: ValueType<{
        value: string;
        label: string;
      }>
    ) => {
      formik.setFieldValue("languages", val);
    },
    [formik]
  );

  return {
    formik,
    submitSuccess,
    submitError,
    loading,
    onResetClick,
    onLanguageChange,
  };
};
