/** Lib */
import React from "react";

/** Hooks */
import hooks from "./UserDetails.hooks";
import { Button, Box, Heading, Flex } from "rebass";
import { Container } from "@ui/common/responsive";
import Loader from "@/ui/common/loader/Loader.common";

const UserDetails: React.FC<Router.RouteComponentProps<
  TLocationParamsWithId
>> = props => {
  const {
    user,
    onDisableClick,
    onApproveClick,
    onStarChange,
    onUpdateUser,
    onFirstNameChange,
    formValues
  } = hooks(props);

  if (!user) {
    return (
      <Container>
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      </Container>
    );
  }
  return (
    <Container pt={5}>
      <ul>
        {Object.keys(user).map(key => {
          return (
            <li key={key}>
              {key}: {JSON.stringify(user[key as keyof IUser])}
            </li>
          );
        })}
      </ul>
      <Box>
        <Box
          as="input"
          defaultValue={user.firstName}
          name="firstName"
          onChange={onFirstNameChange}
        />
      </Box>
      {user.stars && (
        <Box>
          <Heading as="h4" fontSize={2}>
            Ambassador Level:{" "}
          </Heading>
          <Box
            as="select"
            defaultValue={user.stars}
            onChange={onStarChange}
            mb={3}
          >
            <option value={1}>1 Star</option>
            <option value={2}>2 Star</option>
            <option value={3}>3 Star</option>
            <option value={4}>4 Star</option>
            <option value={5}>5 Star</option>
          </Box>
        </Box>
      )}
      <Box mb={3}>
        {user.active ? (
          <Button
            type="button"
            variant="danger"
            onClick={onDisableClick(user.id)}
          >
            Disable
          </Button>
        ) : (
          <Button type="button" onClick={onApproveClick(user.id)}>
            Approve
          </Button>
        )}
        {formValues && <Button onClick={onUpdateUser}>Update</Button>}
      </Box>
    </Container>
  );
};

export default UserDetails;
