import React from "react";

function createStore<State, ActionObj, Actions>(
  initialState: State,
  reducer: (state: State, action: ActionObj) => State,
  actionCreators: { [key: string]: ActionCreator<State, ActionObj> }
) {
  const StoreContext = React.createContext<any>({
    state: initialState,
    dispatch: () => null
  });
  const StoreContextProvider: React.FC = props => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
      <StoreContext.Provider value={{ state, dispatch }}>
        {" "}
        {props.children}{" "}
      </StoreContext.Provider>
    );
  };
  const useStore = (() => {
    const { state, dispatch } = React.useContext(StoreContext);

    const actions = React.useMemo(() => {
      return Object.keys(actionCreators).reduce((acc, key) => {
        return {
          ...acc,
          [key]: (actionCreators as {
            [key: string]: ActionCreator<State, ActionObj>;
          })[key](state, dispatch)
        };
      }, {} as Actions);
    }, [state, dispatch]);

    return { state, actions };

  }) as () => { state: State; actions: Actions };

  return { StoreContext, StoreContextProvider, useStore };
}

export default createStore;
