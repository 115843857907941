/** Lib */
import React from "react";
import * as firebase from "firebase/app";
import { useFormik } from "formik";

/** Hooks */
import useSubscribeToAccounts from "@/hooks/useSubscribeToAccounts";
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";

/** Stores */
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/** Types */
import { firestoreEventFormSchema } from "@schemas/events/events.schemas";
import { serializeFormEvent } from "@/services/events/events.serializer";
import eventsService from "@/services/events/events.service";
import { USER_ROLES } from "@/constants/users.constants";
import { TSelectVal } from "@/ui/common/form-elements/select/Select.common.new";

export default () => {
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>();
  const [submitError, setSubmitError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const { accounts } = useSubscribeToAccounts();
  const { business } = useSubscribeToBusiness();

  const {
    state: { user },
  } = useAuthStore();

  const formik = useFormik({
    initialValues: {
      accountName: undefined as TSelectVal,
      fundingSource: "",
      requestersName: "",
      emailAddress: "",
      phoneNumber: "",
      accountSpend: "",
      wodLocation: "",
      eventStartTime: "",
      eventEndTime: "",
      streetAddress: "",
      business: undefined as TSelectVal,
      city: "",
      state: undefined as TSelectVal,
      premise: { label: "Off", value: "Off" } as TSelectVal,
      zipCode: "",
      dayofEventAccountContact: "",
      brand: "",
      activationPlusUp: "",
      typeofEventPromotion: "",
      eventNotes: "",
      status: "requested",
    },
    validationSchema: firestoreEventFormSchema,
    onSubmit: async (values, formikHelpers) => {
      setLoading(true);
      const response = await firebase
        .functions()
        .httpsCallable("getTimezones")([{ zip: values.zipCode }])
        .catch((err) => {
          console.error("Error getting timezone", err);
        });

      console.log(`got timezones`, response);
      if (!response || !response.data.length || !response.data[0].timezone) {
        setLoading(false);
        return formikHelpers.setFieldError("city", "City is invalid.");
      }

      console.log(`serializing`, user);
      const sEvent = serializeFormEvent({
        ...values,
        eventStartTime: values.eventStartTime as unknown as Date,
        eventEndTime: values.eventEndTime as unknown as Date,
        timezone: response.data[0].timezone,
        state: values.state!.value,
        accountName: values.accountName!.value,
        premise: values.premise!.value,
        business: user!.claims.role === USER_ROLES.admin ? values.business!.value : user!.claims.business,
      });
      const diffDays = (sEvent.eventStartTime - (new Date() as any)) / 1000 / 60 / 60 / 24;
      const isInTenDayWindow = diffDays >= 10;

      if (!isInTenDayWindow && user?.claims.role !== USER_ROLES.admin) {
        setLoading(false);
        return formikHelpers.setFieldError("eventStartTime", "Start Date/Time must be at least 10 days in the future");
      }

      if (sEvent.eventStartTime > sEvent.eventEndTime) {
        setLoading(false);
        return formikHelpers.setFieldError(
          "eventEndTime",
          "End Date/Time must be after Start Date/Time"
        );
      }

      try {
        await eventsService.create(sEvent);
        await eventsService.eventsCreated(1);
        setSubmitSuccess(true);
        setLoading(false);
      } catch (err) {
        setSubmitSuccess(false);
        setSubmitError(err.toString());
        setLoading(false);
        console.error("Error Scheduling Event: ", err);
      }
    },
  });

  const resetForm = React.useCallback(() => {
    setSubmitSuccess(undefined);
    setSubmitError(undefined);
    formik.handleReset(null);
  }, [formik]);

  return {
    formik,
    accounts,
    business,
    user,
    submitError,
    submitSuccess,
    resetForm,
    loading,
  };
};
