/** Lib */
import React from "react";

/** Stores */
import { useStore as useEventsStore } from "@stores/events/events.store";
import { useStore as useUsersStore } from "@stores/users/users.store";

/** Services */
import EventsService from "@services/events/events.service";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import { USER_ROLES } from "@/stores/auth/auth.constants";

export default () => {
  const { state, actions } = useEventsStore();
  const {
    state: { ownUser }
  } = useUsersStore();

  const { subscribedToOwn, ownAmbassadorEvents } = state;

  const invitedCallback = React.useCallback(
    (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => {
      actions.setOwnAmbassadorEvents({
        invitedEvents: snapshot.docs.map(doc => doc.data()) as TFirestoreEvent[]
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const acceptedCallback = React.useCallback(
    async (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => {
      actions.setOwnAmbassadorEvents({
        acceptedEvents: snapshot.docs.map(doc =>
          doc.data()
        ) as TFirestoreEvent[]
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // const declinedCallback = React.useCallback(
  //   (
  //     snapshot: firebase.firestore.QuerySnapshot<
  //       firebase.firestore.DocumentData
  //     >
  //   ) => {
  //     actions.setOwnAmbassadorEvents({
  //       declinedEvents: snapshot.docs.map(doc =>
  //         doc.data()
  //       ) as TFirestoreEvent[]
  //     });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );
  const confirmedCallback = React.useCallback(
    (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => {
      actions.setOwnAmbassadorEvents({
        confirmedEvents: snapshot.docs.map(doc =>
          doc.data()
        ) as TFirestoreEvent[]
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    if (
      !subscribedToOwn &&
      ownUser &&
      ownUser.role === USER_ROLES.brandAmbassador
    ) {
      EventsService.subscribeToOwnAmbassadorEvents(
        invitedCallback,
        acceptedCallback,
        // declinedCallback,
        confirmedCallback
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscribedToOwn,
    ownUser,
    invitedCallback,
    acceptedCallback,
    // declinedCallback,
    confirmedCallback
  ]);

  return ownAmbassadorEvents;
};
