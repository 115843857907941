import React from "react";
import { useStore as useAuthStore } from "@stores/auth/auth.store";
import * as firebase from "firebase/app";
import usersService from "@/services/users/users.service";
import { toast } from "react-toastify";

const params = new URL(window.location.href).searchParams;

const signInFromEmail = params.get("signInFromEmail");
const signInEmail = params.get("signInEmail");

export default (props: Router.RouteComponentProps) => {
  const [authCode, setAuthCode] = React.useState();
  const [signInWithEmail, setSignInWithEmail] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const {
    state: { authenticated },
  } = useAuthStore();
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passRef = React.useRef<HTMLInputElement>(null);

  const onEmailSignInClick = React.useCallback(() => {
    setSignInWithEmail(!signInWithEmail);
    setAuthCode(undefined);
  }, [signInWithEmail]);

  const onLoginFormSubmit = React.useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();
      if (
        !signInWithEmail &&
        emailRef.current?.value &&
        passRef.current?.value
      ) {
        try {
          setLoading(true);
          await firebase
            .auth()
            .signInWithEmailAndPassword(
              emailRef.current.value,
              passRef.current.value
            );
        } catch (err) {
          setLoading(false);
          console.error("Auth Error", err);
          setAuthCode(err.message);
        }
      } else if (emailRef.current?.value) {
        try {
          setLoading(true);
          await usersService.sendSignInLink(emailRef.current.value);
          toast("Login link sent. Please check your email.");
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setAuthCode(err.toString());
          console.error("Auth Error", err);
        }
      }
    },
    [signInWithEmail, emailRef, passRef]
  );

  React.useEffect(() => {
    if (authenticated) {
      const params = new URL(window.location.href).searchParams;
      const redirectUrl = params.get("redirectUrl");
      if (redirectUrl) {
        return props.history.replace(redirectUrl);
      }
      return props.history.replace("/dashboard");
    }
  }, [authenticated, props.history]);

  React.useEffect(() => {
    (async () => {
      try {
        if (signInFromEmail && signInEmail) {
          setLoading(true);
          await firebase
            .auth()
            .signInWithEmailLink(signInEmail, window.location.href);
          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error signing in with email link", err);
        toast(err.toString());
      }
    })();
  }, []);

  return {
    emailRef,
    passRef,
    onLoginFormSubmit,
    authCode,
    authenticated,
    signInWithEmail,
    onEmailSignInClick,
    loading,
  };
};
