/** Lib */
import React from "react";

/** UI */
import { Box, Button, Flex, Text, Heading } from "rebass";
import Input from "@common/form-elements/input/Input.common";
import FormRow from "@common/form-elements/form-row/FormRow.common";

/** Hooks */
import hooks from "./AddSingleAccountForm.hooks";
import Loader from "@/ui/common/loader/Loader.common";

const inputContainerProps = {
  mb: [4, 0],
  flex: 1,
  px: 2
};

const AddSingleAccountForm: React.FC = props => {
  const {
    formik,
    submitError,
    submitSuccess,
    onTryAgainClick,
    possibleDuplicate,
    handleSubmit,
    loading,
    formRef
  } = hooks();

  if (submitSuccess === false) {
    return (
      <Box>
        <Heading color="danger" variant="small" mb={2}>
          Add Account Failed
        </Heading>
        <Text mb={4}>{submitError}</Text>
        <Button type="button" variant="primary" onClick={onTryAgainClick}>
          Try Again
        </Button>
      </Box>
    );
  }

  if (submitSuccess === true) {
    return (
      <Box>
        <Heading color="success" variant="small" mb={2}>
          Account Added
        </Heading>
        <Text mb={4}>
          Account {formik.values.accountName} added successfully.
        </Text>
        <Button type="button" variant="secondary" onClick={onTryAgainClick}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box as="form" onSubmit={handleSubmit() as any} ref={formRef}>
      <Box mb={4} display={{ display: possibleDuplicate ? "none" : "block" }}>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            name="accountName"
            label="Account Name"
            required
            value={formik.values.accountName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.accountName && formik.errors.accountName
                ? formik.errors.accountName
                : undefined
            }
          />
        </FormRow>
      </Box>
      <Flex
        display={{ display: possibleDuplicate ? "none !important" : "block" }}
        sx={{
          display: possibleDuplicate ? "none" : "block",
          button: {
            marginX: 1,
            "&:first-of-type": {
              marginLeft: "0px"
            },
            "&:last-of-type": {
              marginRight: "0px"
            }
          }
        }}
      >
        <Button type="submit">
          {loading ? (
            <Loader height="15px" width="15px" m="0 auto" />
          ) : (
            "Submit"
          )}
        </Button>
        <Button type="button" variant="secondary" onClick={formik.handleReset}>
          Clear Form
        </Button>
      </Flex>
      {possibleDuplicate && (
        <>
          <Box>
            <Heading color="danger" variant="small" mb={2}>
              Possible Duplicate Account
            </Heading>
            <Text mb={4}>
              {possibleDuplicate} is very similar to {formik.values.accountName}
              , would you still like to proceed?
            </Text>
          </Box>
          <Flex
            sx={{
              display: possibleDuplicate ? "none" : "block",
              button: {
                marginX: 1,
                "&:first-of-type": {
                  marginLeft: "0px"
                },
                "&:last-of-type": {
                  marginRight: "0px"
                }
              }
            }}
          >
            <Button type="button" onClick={handleSubmit(true)}>
              {loading ? (
                <Loader height="15px" width="15px" m="0 auto" />
              ) : (
                "Submit Anyway"
              )}
            </Button>
            <Button type="button" variant="secondary" onClick={onTryAgainClick}>
              Try Again
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default AddSingleAccountForm;
