import {
  SET_EVENTS,
  SET_OWN_EVENTS,
  SET_SUBSCRIBED_TO_ALL,
  SET_SUBSCRIBED_TO_OWN,
  SET_SUBSCRIBED_TO_OWN_AMBASSADOR,
  SET_OWN_AMBASSADOR_EVENTS,
} from "./events.constants";
import { statuses } from "./events.constants";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import {
  EventState,
  EventAction,
  IOwnEvents,
  IOwnAmbassadorEvents,
} from "./events.types";

/**
 * Action Creators (For use by store, _do not import these directly into components_)
 */

export const setEvents: ActionCreator<
  EventState,
  EventAction<TFirestoreEvent[]>
> = (state, dispatch) => (value: TFirestoreEvent[]) => {
  dispatch({
    type: SET_EVENTS,
    payload: value,
  });
};

export const setOwnAmbassadorEvents: ActionCreator<
  EventState,
  EventAction<IOwnAmbassadorEvents>
> = (state, dispatch) => (value: IOwnAmbassadorEvents) => {
  dispatch({
    type: SET_OWN_AMBASSADOR_EVENTS,
    payload: value,
  });
};

export const setOwnEvents: ActionCreator<
  EventState,
  EventAction<IOwnEvents>
> = (state, dispatch) => (value: TFirestoreEvent[]) => {
  const ownEvents = value.reduce(
    (ownEventsAcc, event) => {
      if (event.status.toLowerCase() === statuses.requested) {
        return {
          ...ownEventsAcc,
          requested: [...ownEventsAcc.requested, event],
        };
      }
      if (event.status.toLowerCase() === statuses.approved) {
        return {
          ...ownEventsAcc,
          approved: [...ownEventsAcc.approved, event],
        };
      }
      if (event.status.toLowerCase() === statuses.booked) {
        return {
          ...ownEventsAcc,
          booked: [...ownEventsAcc.booked, event],
        };
      }
      if (event.status.toLowerCase() === statuses.denied) {
        return {
          ...ownEventsAcc,
          denied: [...ownEventsAcc.denied, event],
        };
      }
      if (event.status.toLowerCase() === statuses.executed) {
        return {
          ...ownEventsAcc,
          executed: [...ownEventsAcc.executed, event],
        };
      }
      if (event.status.toLowerCase() === statuses.cancelled) {
        return {
          ...ownEventsAcc,
          cancelled: [...ownEventsAcc.cancelled, event],
        };
      }
      return ownEventsAcc;
    },
    {
      requested: [],
      approved: [],
      booked: [],
      denied: [],
      executed: [],
      cancelled: [],
    } as IOwnEvents
  );
  dispatch({
    type: SET_OWN_EVENTS,
    payload: ownEvents,
  });
};

export const setSubscribedToAll: ActionCreator<
  EventState,
  EventAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_ALL,
    payload: value,
  });
};

export const setSubscribedToOwn: ActionCreator<
  EventState,
  EventAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_OWN,
    payload: value,
  });
};

export const setSubscribedToOwnAmbassador: ActionCreator<
  EventState,
  EventAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_OWN_AMBASSADOR,
    payload: value,
  });
};
