/** Constants */
import {
  SET_ACCOUNTS,
  SET_SUBSCRIBED_TO_ALL,
} from "./accounts.constants";

/** Types */
import { IAccountsState, TAccountsAction } from './accounts.types';

export default (state: IAccountsState, action: TAccountsAction<any>) => {
  switch (action.type) {
    case SET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_ALL: {
      return {
        ...state,
        subscribedToAll: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
