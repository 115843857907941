import {
  TAccountExcelParsed,
  TAccountForm
} from "@typings/accounts/accounts.types";

export const serializeAccount = (
  account: TAccountExcelParsed
): TAccountForm => {
  return {
    accountName: account.accountName
  };
};

export default (
  accounts: TAccountExcelParsed[] | TAccountExcelParsed
): TAccountForm | TAccountForm[] => {
  if (Array.isArray(accounts)) {
    return accounts.map(event => {
      return serializeAccount(event);
    });
  }
  return serializeAccount(accounts);
};
