/** Lib */
import React from "react";

/** Hooks */
import hooks from "./AdminAndEventCreatorProfileForm.hooks";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";

/** UI */
import Input from "@/ui/common/form-elements/input/Input.common";
import { Flex, Button } from "rebass";
import FormRow from "@/ui/common/form-elements/form-row/FormRow.common";
import Loader from "@/ui/common/loader/Loader.common";

const inputContainerProps = {
  mb: [4, 0],
  flex: 1,
  px: 2,
  maxWidth: "300px"
};

export interface IAdminAndEventCreatorProfileFormProps {
  user: TUserFirestore;
  onSubmitSuccess: () => void;
  onSubmitError: () => void;
}

const AdminAndEventCreatorProfileForm: React.FC<IAdminAndEventCreatorProfileFormProps> = props => {
  const { formik, loading, onResetClick } = hooks(props);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormRow>
        <Input
          containerProps={inputContainerProps}
          name="firstName"
          label="First Name"
          required
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : undefined
          }
        />
        <Input
          containerProps={inputContainerProps}
          name="lastName"
          label="Last Name"
          required
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : undefined
          }
        />
      </FormRow>
      <FormRow>
        <Input
          containerProps={inputContainerProps}
          name="cellNumber"
          label="Cell Number"
          placeholder="(xxx) xxx-xxxx"
          required
          value={formik.values.cellNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.cellNumber && formik.errors.cellNumber
              ? formik.errors.cellNumber
              : undefined
          }
        />
      </FormRow>

      <Flex>
        <Button
          mx={2}
          type="submit"
          disabled={loading || !formik.dirty}
          variant={formik.dirty && !loading ? "primary" : "primary.disabled"}
        >
          {loading ? (
            <Loader height="15px" width="15px" m="0 auto" />
          ) : (
            "Submit"
          )}
        </Button>
        {formik.dirty && !loading && (
          <Button variant="secondary" onClick={onResetClick}>
            Reset Form
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default AdminAndEventCreatorProfileForm;
