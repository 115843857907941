/** Lib */
import React from "react";
/** UI */
import { Box, SxStyleProp, Flex, BoxProps } from "rebass";
import Pagination from "./Pagination";
/** Types */
import { Column, Row } from "react-table";
/** Hooks */
import hooks from "./Table.hooks";
/** Styles */
import { tableStyles, StyledTh } from "./Table.styles";

export interface ITableProps<RowDataType extends {}> {
  tableData: Array<RowDataType>;
  columns: Array<Column<RowDataType>>;
  defaultSortBy?: { id: string; desc: boolean }[];
  onRowClick?: (row: Row<any>) => void;
  sx?: SxStyleProp;
  filterValue?: string;
}

function Table<RowDataType>(
  props: React.PropsWithChildren<ITableProps<RowDataType> & BoxProps>
) {
  const { table, onRowClick } = hooks<RowDataType>(props);

  const { onRowClick: ignore, sx, ...restProps } = props;

  const {
    // getTableProps,
    // getTableBodyProps,
    // headerGroups,
    // prepareRow,
    // page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = table;

  return (
    <Box
      overflowX="scroll"
      maxWidth="100%"
      sx={
        props.sx
          ? { table: { ...tableStyles.table as SxStyleProp, ...props.sx } }
          : tableStyles
      }
      {...restProps}
    >
      <Flex flexDirection="column" justifyContent="space-between">
        <Box as="table" {...table.getTableProps()}>
          <thead>
            {table.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTh
                    {...column.getHeaderProps(
                      (column as any).getSortByToggleProps()
                    )}
                    id={column.id}
                  >
                    {column.render("Header")}
                  </StyledTh>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...table.getTableBodyProps()}>
            {table.page.map((row, i) => {
              table.prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={onRowClick(row)}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        id={cell.column.id}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Box>
        <Flex justifyContent="center">
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default Table;
