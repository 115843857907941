/** Lib */
import React from "react";
import ReactTooltip from "react-tooltip";
import { hot } from "react-hot-loader/root";
import { ToastContainer } from "react-toastify";
import { Switch, Route, withRouter } from "react-router-dom";
import * as firebase from "firebase/app"

/** Stores */
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/** UI */
import { Box } from "rebass";
import LoginScreen from "./login/Login.screen";
import DashboardScreen from "./dashboard/Dashboard.screen";
import EventDetailsScreen from "./event-details/EventDetails.screen";
import ManageUsersScreen from "./manage-users/ManageUsers.screen";
import ManageBusinessScreen from "./manage-business/ManageBusiness.screen";
import UserDetailsScreen from "./user-details/UserDetails.screen";
import ScheduleEventsExcelScreen from "./schedule-events-excel/ScheduleEventsExcel.screen";
import ScheduleSingleEventScreen from "./schedule-single-event/ScheduleSingleEvent.screen";
import CalendarScreen from "./calendar/Calendar.screen";
import AccountsScreen from "./accounts/Accounts.screen";
import AccountDetailsScreen from "@screens/account-details/AccountDetails.screen";
import AddSingleAccountScreen from "./add-single-account/AddSingleAccount.screen";
import Header from "@components/header/Header.component";
import SideNavigation from "@components/side-navigation/SideNavigation.component";
import AddAccountsExcelScreen from "./add-accounts-excel/AddAccountsExcel.screen";
import StaffEventScreen from "@screens/staff-event/StaffEvent.screen";
import ApproveEventScreen from "./approve-event/ApproveEvent.screen";
import ProfileScreen from "./profile/Profile.screen";
import FinishSignUpScreen from "./finish-sign-up/FinishSignUp.screen";
import EventRecapFormScreen from "./event-recap/EventRecap.screen";
import EventRecapSubmitSmsScreen from "./event-recap/EventRecapSubmitSms.screen";

/** Styles */
import theme from "../theme";
import { ThemeProvider } from "emotion-theming";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const App: React.FC<Router.RouteComponentProps> = props => {
  const { actions } = useAuthStore();
  const [mobileMenuExpanded, setMobileMenuExpanded] = React.useState(false);

  React.useEffect(() => {
    /**
     * onAuthStateChange triggered only on
     * login and logout. Redirect user based on logged in status
     * and user role.
     */
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        actions.setAuthenticated(false);
        actions.setUserData(null);
        if (!props.location.pathname.match(/finish-sign-up|event-recap-submit-sms|login/)) {
          return props.history.push("/login");
        }
        return;
      }

      console.log('auth state changed', user);
      actions.setAuthenticated(true);

      user.getIdTokenResult().then(val => {
        console.log('token result', val);
        actions.setUserData({
          ...user,
          claims: val.claims
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history]);
  const isLoginPage = React.useMemo(() => {
    return props.location.pathname === "/login" || props.location.pathname === "/";
  }, [props.location.pathname]);
  return (
    <ThemeProvider theme={theme}>
      <Box
        id="App"
        sx={{
          pl: [0, 0, 0, !isLoginPage ? "70px" : 0]
        }}
        onClick={ev => {
          const path = ev.nativeEvent.composedPath() as HTMLElement[];
          const sideNavClicked = path.find(path => {
            return (
              typeof path.className === "string" &&
              path.className.includes("side-navigation")
            );
          });

          if (!sideNavClicked && mobileMenuExpanded) {
            setMobileMenuExpanded(false);
          }
        }}
      >
        {!isLoginPage && (
          <SideNavigation
            {...props}
            mobileMenuExpanded={mobileMenuExpanded}
            setMobileMenuExpanded={setMobileMenuExpanded}
          />
        )}
        <ToastContainer position="bottom-center" autoClose={2000} />
        <ReactTooltip />
        <Header
          mobileMenuExpanded={mobileMenuExpanded}
          setMobileMenuExpanded={setMobileMenuExpanded}
        />
        <Box>
          <Switch>
            <Route path="/dashboard" component={DashboardScreen} />
            <Route path="/profile" component={ProfileScreen} />
            <Route path="/finish-sign-up" component={FinishSignUpScreen} />
            <Route path="/users/:id" component={UserDetailsScreen} />
            <Route path="/events/:id" component={EventDetailsScreen} />
            <Route path="/accounts/:id" component={AccountDetailsScreen} />
            <Route path="/staff-event/:id" component={StaffEventScreen} />
            <Route path="/approve-event/:id" component={ApproveEventScreen} />
            <Route path="/event-recap-form/:id" component={EventRecapFormScreen} />
            <Route path="/er/:id" component={EventRecapFormScreen} />
            <Route path="/event-recap-submit-sms/:id" component={EventRecapSubmitSmsScreen} />
            <Route path="/manage-users" component={ManageUsersScreen} />
            <Route path="/manage-business" component={ManageBusinessScreen} />
            <Route
              path="/schedule-events-excel"
              component={ScheduleEventsExcelScreen}
            />
            <Route
              path="/schedule-event"
              component={ScheduleSingleEventScreen}
            />
            <Route path="/add-account" component={AddSingleAccountScreen} />
            <Route
              path="/add-accounts-excel"
              component={AddAccountsExcelScreen}
            />
            <Route path="/accounts" component={AccountsScreen} />
            <Route path="/calendar" component={CalendarScreen} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/" component={LoginScreen} />
          </Switch>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default hot(withRouter(App));
