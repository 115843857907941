/** Lib */
import React from "react";

/** UI */
import { Flex, Box, Text, Button, Heading } from "rebass";

/** Types */
import { IScheduleEventsFormProps } from "./ScheduleEventsForm.types";
import Loader from "@/ui/common/loader/Loader.common";
import { dropzoneSx } from "@/styles/shared/dropzone.styles";

const ScheduleEventsForm: React.FC<IScheduleEventsFormProps> = props => {
  const {
    dropzoneState,
    rejectedFilesLocal,
    isFileTooLarge,
    workbook,
    workbookValid,
    resetForm,
    loading,
    eventData,
    onUploadSubmit
  } = props;

  const { isDragActive, getRootProps, getInputProps } = dropzoneState;

  return (
    <Box as="form" id="schedule-events" onSubmit={onUploadSubmit}>
      {!workbook && workbookValid !== false ? (
        <Flex
          {...getRootProps()}
          mb={4}
          justifyContent="center"
          alignItems="center"
          sx={dropzoneSx}
        >
          <input {...getInputProps()} />
          {!isDragActive && !rejectedFilesLocal && (
            <Box>
              <Text>Click here or drop a file to upload!</Text>
            </Box>
          )}
          {isDragActive && !rejectedFilesLocal && (
            <Box>
              <Text>Drop it like it's hot!</Text>
            </Box>
          )}
          {rejectedFilesLocal && (
            <Box>
              <Text color="danger">
                Sorry, file type not accepted, please download template .xlsx
                file for use.
              </Text>
              <Button type="button" onClick={resetForm}>Try Again</Button>
            </Box>
          )}
          {isFileTooLarge && <Text color="danger">File is too large.</Text>}
        </Flex>
      ) : workbookValid === false ? (
        <Box mb={5}>
          <Text color="danger" mb={2}>
            File is corrupt, formatted incorrectly, or is missing required
            columns. Please download template .xlsx file for use.
          </Text>
          <Button type="button" onClick={resetForm}>Try Again</Button>
        </Box>
      ) : null}
      {workbook && loading && (
        <Heading variant="small" my={4}>
          <Loader className="la-3x" m="0 auto" />
        </Heading>
      )}
      {workbook && !loading && (
        <>
          <Heading variant="xsmall" mb={4}>
            <Text as="span" color="success">
              {eventData.reduce(
                (countAcc, sheet) => countAcc + sheet.data.length,
                0
              )}{" "}
              events passed validation.{" "}
            </Text>
            <Text as="span" color="danger">
              {eventData.reduce(
                (countAcc, sheet) => countAcc + sheet.failed.length,
                0
              )}{" "}
              events failed validation.
            </Text>
          </Heading>
          <Box mb={4}>
            <Button
              type="button"
              backgroundColor="lightGray"
              color="darkGray"
              sx={{
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "gray",
                  color: "white"
                }
              }}
              onClick={resetForm}
            >
              Cancel
            </Button>
            <Button type="submit">
              {!loading ? (
                "Submit " +
                eventData.reduce(
                  (countAcc, sheet) => countAcc + sheet.data.length,
                  0
                ) +
                " Events"
              ) : (
                <Loader height="15px" width="15px" m="0 auto" />
              )}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ScheduleEventsForm;
