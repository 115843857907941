/** Lib */
import React from "react";
import * as firebase from "firebase/app"

/** Hooks */
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

export default () => {
  const ownUser = useSubscribeToOwnUser();

  const onLogoutClick = React.useCallback((ev: React.MouseEvent) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
      }).catch(err => {
        console.error(err);
        window.location.reload();
      });
  }, []);

  return { onLogoutClick, ownUser };
};
