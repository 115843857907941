/** Lib */
import React from "react";

/** UI */
import { Flex, FlexProps } from "rebass";

const FormRow: React.NamedExoticComponent<FlexProps> = React.memo(props => {
  return (
    <Flex
      flexDirection={["column", "row"]}
      {...props}
      sx={{
        mb: [0, '45px'],
        ...props.sx
      }}
    >
      {props.children}
    </Flex>
  );
});

export default FormRow;
