//========================================================================================
/*                                                                                      *
 * S T A F F  E V E N T :  H O O K S                                                    *
 *                                                                                      */
//========================================================================================

/**
 *
 * * IMPORTS
 *
 */
import React from "react";
import { toast } from "react-toastify";
import { Image, Text, Button } from "rebass";
/**
 * * IMPORTS: Hooks
 */
import useSubscribeToAmbassadors from "@/hooks/useSubscribeToAmbassadors";
import useSubscribeToSingleEvent from "@/hooks/useSubscribeToSingleEvent";
import useSubscribeToSingleEventProtected from "@/hooks/useSubscribeToSingleEventProtected";
import useSubscribeToAllEvents from "@/hooks/useSubscribeToAllEvents";
/**
 * * IMPORTS: Types
 */
import { TUserFirestore } from "@typings/users/users.types";
import { Row } from "react-table";
/**
 * * IMPORTS: Services
 */
import usersService from "@/services/users/users.service";

/**
 * * IMPORTS: Util
 */
import { dateRangeOverlaps } from "@/util/dateRangeOverlaps";
import useLocalStorage from "@/hooks/useLocalStorage";
import ReactTooltip from "react-tooltip";
import { confirmAlert } from "react-confirm-alert";
import eventsService from "@/services/events/events.service";

/**
 * * VARIABLES: Types
 */
export interface IStaffFiltersState {
  available: boolean;
  invited: boolean;
  accepted: boolean;
  confirmed: boolean;
}

/**
 * * VARIABLES: Constants
 */
export const SHOW_INITIAL: IStaffFiltersState = {
  available: true,
  invited: false,
  accepted: false,
  confirmed: false,
};

export const SHOW_RESET: IStaffFiltersState = {
  available: false,
  invited: false,
  accepted: false,
  confirmed: false,
};

/**
 *
 * * EXPORTS
 *
 */
export default (
  props: Router.RouteComponentProps<{
    id: string;
  }>
) => {
  // * Props
  const {
    match: {
      params: { id: eventId },
    },
  } = props;

  // * Hooks
  const ambassadors = useSubscribeToAmbassadors();
  const event = useSubscribeToSingleEvent(eventId);
  const eventProtected = useSubscribeToSingleEventProtected(eventId);
  const { allEvents } = useSubscribeToAllEvents();

  // * State
  const [selectedAmbassadors, setSelectedAmbassadors] = React.useState<
    Row<TUserFirestore>[]
  >([]);
  const [
    selectedAcceptedAmbassadors,
    setSelectedAcceptedAmbassadors,
  ] = React.useState<Row<TUserFirestore>[]>([]);
  const [
    selectedConfirmedAmbassadors,
    setSelectedConfirmedAmbassadors,
  ] = React.useState<Row<TUserFirestore>[]>([]);
  const [showFilterState, setShowFilterState] = useLocalStorage<
    IStaffFiltersState
  >("STAFF_FILTERS", SHOW_INITIAL);

  const [availableAmbassadors, setAvailableAmbassadors] = React.useState<
    TUserFirestore[]
  >([]);
  const [invitedAmbassadors, setInvitedAmbassadors] = React.useState<
    TUserFirestore[]
  >([]);
  const [acceptedAmbassadors, setAcceptedAmbassadors] = React.useState<
    TUserFirestore[]
  >([]);
  // const [declinedAmbassadors, setDeclinedAmbassadors] = React.useState<
  //   TUserFirestore[]
  // >([]);
  const [confirmedAmbassadors, setConfirmedAmbassadors] = React.useState<
    TUserFirestore[]
  >([]);

  const [searchTerm, setSearchTerm] = React.useState("");
  const [acceptedSearchTerm, setAcceptedSearchTerm] = React.useState("");
  const [confirmedSearchTerm, setConfirmedSearchTerm] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  /**
   * * Handlers
   */
  const onSearchChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(ev.target.value);
    },
    []
  );
  const onSearchAcceptedChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setAcceptedSearchTerm(ev.target.value);
    },
    []
  );
  const onSearchConfirmedChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmedSearchTerm(ev.target.value);
    },
    []
  );

  const onSelectionChange = React.useCallback(
    (selection: Row<TUserFirestore>[]) => {
      setSelectedAmbassadors(selection);
    },
    []
  );
  const onAcceptedSelectionChange = React.useCallback(
    (selection: Row<TUserFirestore>[]) => {
      setSelectedAcceptedAmbassadors(selection);
    },
    []
  );
  const onConfirmedSelectionChange = React.useCallback(
    (selection: Row<TUserFirestore>[]) => {
      setSelectedConfirmedAmbassadors(selection);
    },
    []
  );

  const columns = React.useMemo(() => {
    return [
      {
        Header: "",
        accessor: "avatar",
        id: "avatar",
        Cell: (context: { row: { original: TUserFirestore } }) => {
          return (
            <Button
              type="button"
              variant="blank"
              onClick={async () => {
                if (
                  event &&
                  context.row.original.id !== event.designatedAmbassador
                ) {
                  confirmAlert({
                    title: `Make ${context.row.original.firstName} the designated ambassador?`,
                    message:
                      "The designated ambassador receives the notification to complete the recap form.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: async () => {
                          try {
                            setLoading(true);
                            await eventsService.update(eventId, {
                              designatedAmbassador: context.row.original.id,
                            });
                            setLoading(false);
                          } catch (err) {
                            const msg = "Error updating designated ambassador";
                            console.error(msg, err);
                            toast(msg);
                          }
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {
                          // exit
                        },
                      },
                    ],
                  });
                }
              }}
              sx={{ position: "relative" }}
            >
              <Image
                src={context.row.original.avatar}
                width="50px"
                height="auto"
                sx={{
                  borderRadius: "100%",
                }}
              />
              {event && context.row.original.id === event.designatedAmbassador && (
                <Button
                  type="button"
                  variant="blank"
                  className="designated-overlay"
                  data-tip="Designated ambassador"
                  sx={{
                    position: "absolute",
                    height: "40px",
                    width: "40px",
                    top: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                  }}
                >
                  {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                  <Text as="span" role="img" aria-label="check">
                    {"\u2713"}
                  </Text>
                  <ReactTooltip />
                </Button>
              )}
            </Button>
          );
        },
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Travel Distance",
        accessor: "willingToTravelDistance",
      },
      {
        Header: "Bilingual",
        accessor: (row: TUserFirestore) => {
          return row.bilingual ? "bilingual" : "no";
        },
        Cell: (context: { row: { original: TUserFirestore } }) => {
          return context.row.original.bilingual ? "bilingual" : "no";
        },
      },
      {
        Header: "Languages",
        accessor: "languages",
        Cell: (context: { row: { original: TUserFirestore } }) => {
          return Array.isArray(context.row.original.languages)
            ? context.row.original.languages.join(", ")
            : "";
        },
      },
      {
        Header: "Hair Color",
        accessor: "hairColor",
      },
      {
        Header: "Height",
        accessor: "height",
      },
      {
        Header: "Ethnicity",
        accessor: "ethnicity",
      },
      {
        Header: "Phone",
        accessor: "cellNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ];
  }, [event, eventId]);

  const onFilterCheckboxChange = React.useCallback(
    (filterType: keyof IStaffFiltersState) => (
      ev: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (ev.target.checked && !showFilterState[filterType]) {
        setShowFilterState({
          ...SHOW_RESET,
          [filterType]: ev.target.checked,
        });
      }
    },
    [setShowFilterState, showFilterState]
  );

  React.useEffect(() => {
    if (event && allEvents) {
      const filtered = ambassadors.filter((amb) => {
        // only show ambassadors that have not been previously invited, accepted, declined, or manually confirmed.
        // also only show ambassadors who do not have other confirmed events that have times overlapping with this event.

        /**
         * @var hasOverlappingConfirmedEvent - Does ambassador have a confirmed event that overlaps
         * with this one?
         */
        let hasOverlappingConfirmedEvent: boolean = false;

        /**
         * @var hasHistoryOnThisEvent - Does ambassador have any history on this event?
         */
        const hasHistoryOnThisEvent =
          event.invitedUsers?.includes(amb.id) ||
          event.acceptedInviteUsers?.includes(amb.id) ||
          event.declinedInviteUsers?.includes(amb.id) ||
          event.confirmedByAdminUsers?.includes(amb.id);

        // Loop through all events and check if any confirmed events overlap with this event.
        allEvents
          .filter(
            (ev) =>
              Array.isArray(ev.confirmedByAdminUsers) &&
              ev.confirmedByAdminUsers.includes(amb.id)
          )
          .forEach((ev) => {
            const startA = event.eventStartTime.toDate() as Date;
            const endA = event.eventEndTime.toDate() as Date;
            const startB = ev.eventStartTime.toDate() as Date;
            const endB = ev.eventEndTime.toDate() as Date;
            if (dateRangeOverlaps(startA, endA, startB, endB)) {
              hasOverlappingConfirmedEvent = true;
            }
          });

        // return true if no event history and no overlapping confirmed event and ambassador is active.
        return (
          !hasHistoryOnThisEvent && !hasOverlappingConfirmedEvent && amb.active
        );
      });
      const invited = ambassadors.filter((amb) => {
        return event.invitedUsers?.includes(amb.id);
      });
      const accepted = ambassadors.filter((amb) => {
        return event.acceptedInviteUsers?.includes(amb.id);
      });
      // const declined = ambassadors.filter(amb => {
      //   return event.declinedInviteUsers?.includes(amb.id);
      // });
      const confirmed = ambassadors.filter((amb) => {
        return event?.confirmedByAdminUsers?.includes(amb.id);
      });
      setAvailableAmbassadors(filtered);
      setInvitedAmbassadors(invited);
      setAcceptedAmbassadors(accepted);
      setConfirmedAmbassadors(confirmed);
    }
  }, [ambassadors, event, allEvents]);

  const onInviteClick = React.useCallback(async () => {
    try {
      setLoading(true);
      await usersService.inviteUsersToEvent(
        eventId,
        selectedAmbassadors.map((amb) => amb.original.id)
      );
      toast("Invites Sent!");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("StaffEvent.hooks: Error notifying Ambassadors", err);
    }
  }, [eventId, selectedAmbassadors]);

  const onConfirmAcceptedInvitesClick = React.useCallback(async () => {
    try {
      setLoading(true);
      const selectedCount = selectedAcceptedAmbassadors.length;
      await usersService.confirmUsersToEvent(
        eventId,
        selectedAcceptedAmbassadors.map((amb) => amb.original.id)
      );
      toast(`${selectedCount} Ambassadors Confirmed.`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("StaffEvent.hooks: Error confirming Ambassadors", err);
    }
  }, [eventId, selectedAcceptedAmbassadors]);

  const onRevokeConfirmedClick = React.useCallback(async () => {
    try {
      setLoading(true);
      const selectedCount = selectedConfirmedAmbassadors.length;
      await usersService.revokeUsersConfirmationToEvent(
        eventId,
        selectedConfirmedAmbassadors.map((amb) => amb.original.id)
      );
      toast(`${selectedCount} Confirmation(s) Revoked.`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("StaffEvent.hooks: Error revoking Confirmations", err);
    }
  }, [eventId, selectedConfirmedAmbassadors]);

  const confirmSelectionMax = React.useMemo(() => {
    if (eventProtected && event) {
      const ambassadorCount = eventProtected.brandAmbassadorCount
        ? Number(eventProtected.brandAmbassadorCount)
        : 0;
      const alreadyConfirmed = event.confirmedByAdminUsers?.length || 0;
      console.log(ambassadorCount - alreadyConfirmed);
      return ambassadorCount - alreadyConfirmed;
    }
  }, [eventProtected, event]);

  const isPastEvent = React.useMemo(() => {
    return event && new Date(event.eventStartTime.seconds * 1000) < new Date();
  }, [event]);

  const eventTooFarInFuture = React.useMemo(() => {
    if (event) {
      const threshold = new Date().valueOf() / 1000 + 60 * 60 * 24 * 30; // current datetime + 30 days in seconds
      const isAfterThreshold =
        new Date(event.eventStartTime.seconds * 1000).valueOf() / 1000 >
        threshold; // is event start time within the next 30 days (720 hours is limit for task scheduling)
      return isAfterThreshold;
    }
    return true;
  }, [event]);

  return {
    event,
    isPastEvent,
    eventTooFarInFuture,
    eventProtected,
    availableAmbassadors,
    acceptedAmbassadors,
    invitedAmbassadors,
    confirmedAmbassadors,
    // declinedAmbassadors,
    columns,
    confirmSelectionMax,
    onSelectionChange,
    onAcceptedSelectionChange,
    onConfirmedSelectionChange,
    selectedAmbassadors,
    selectedAcceptedAmbassadors,
    selectedConfirmedAmbassadors,
    onSearchChange,
    onSearchAcceptedChange,
    onSearchConfirmedChange,
    searchTerm,
    acceptedSearchTerm,
    confirmedSearchTerm,
    onInviteClick,
    onConfirmAcceptedInvitesClick,
    onRevokeConfirmedClick,
    loading,
    showFilterState,
    onFilterCheckboxChange,
  };
};
