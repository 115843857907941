import React from "react";
import { Box, BoxProps } from "rebass";

/** Types */
import { InterpolationWithTheme } from "@emotion/core";

const containerStyles = {
  maxWidth: ["540px", "720px", "960px", "1140px"],
  marginX: "auto",
  px: "15px"
};

const Container: React.FC<BoxProps & {
  css?: InterpolationWithTheme<any>;
}> = props => {
  return (
    <Box
      {...props}
      {...containerStyles}
      className={
        typeof props.className === "string"
          ? `c-container ${props.className}`
          : "c-container"
      }
    >
      {props.children}
    </Box>
  );
};

export default Container;
