/** Lib */
import React from "react";
import xlsx from "xlsx";
import { useDropzone } from "react-dropzone";
import { camelCase } from "camel-case";

/** Services */
import AccountsService from "@services/accounts/accounts.service";

/** Serializers */
import serializeAccounts from "@services/accounts/accounts.serializer";

/** Validators */
import { validateExcelParsedAccount } from "@schemas/accounts/accounts.schemas";

/** Util */
import getHeadersFromExcelSheet from "@util/getHeadersFromExcelSheet";
import { REQUIRED_EXCEL_HEADERS } from "@constants/accounts.constants";

/** Types */
import { TAccountExcelParsed, TAccountForm } from "@typings/accounts/accounts.types";
import { TAccountFirestoreData, IValidationResult } from "./AddAccountsExcel.types";

/** Constants */
const MAX_FILE_SIZE = 2000000;
const EXCEL_OFFSET = 2; // offset for excel sheet headers.

export default () => {
  /**
   * State
   */
  const [loading, setLoading] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState<{
    added: TAccountForm[];
    duplicates: TAccountForm[];
  }>();
  const [accountData, setAccountData] = React.useState<TAccountFirestoreData>([]);
  const [workbook, setWorkbook] = React.useState<xlsx.WorkBook>();
  const [rejectedFilesLocal, setRejectedFilesLocal] = React.useState<File[]>();
  const [workbookValid, setWorkbookValid] = React.useState<
    boolean | undefined
  >();

  /**
   * set local state for rejected files
   * when file drop fails.
   */
  const onDropRejected = React.useCallback(rejectedFiles => {
    setRejectedFilesLocal(rejectedFiles);
  }, []);

  const onDrop = React.useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length) {
      const reader = new FileReader();
      reader.readAsBinaryString(acceptedFiles[0]);

      reader.onload = () => {
        // parse file data, convert to json, and return it to client.
        try {
          const parsed = xlsx.read(reader.result, { type: "binary" });

          const isWorkbookValid = parsed.SheetNames.every(name => {
            for (let count = 0; count <= 100; count++) {
              const cell =
                parsed.Sheets[name][xlsx.utils.encode_cell({ r: 2, c: count })];
              if (!cell) break;
              Object.keys(cell).forEach(key => {
                cell[key] = `${cell[key]}`.replace(/(\r\n|\n|\r)/gm, "");
              });
            }
            const headers = getHeadersFromExcelSheet(
              parsed.Sheets[name],
              EXCEL_OFFSET
            );
            // If legend sheet, skip validation
            if (name === "Legend") {
              return true;
            }

            return REQUIRED_EXCEL_HEADERS.every(header => {
              return headers.includes(header);
            });
          });

          if (isWorkbookValid) {
            setWorkbook(parsed);
            setWorkbookValid(true);
          } else {
            setWorkbook(undefined);
            setWorkbookValid(false);
          }
        } catch (err) {
          setWorkbook(undefined);
          setWorkbookValid(false);
        }
      };
    }
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    onDropRejected,
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"
    ],
    minSize: 0,
    maxSize: MAX_FILE_SIZE
  });

  const onUploadSubmit = React.useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();
      if (accountData.length) {
        try {
          setLoading(true);
          const data = accountData
            .filter(sheet => {
              return !!sheet.data.length;
            })
            .reduce((evArr, sheet) => {
              return [...evArr, ...sheet.data];
            }, [] as TAccountForm[]);
          const response = await AccountsService.addAccounts(data);
          setLoading(false);
          setUploadResponse(response);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      }
    },
    [accountData]
  );

  React.useEffect(() => {
    if (workbook) {
      const sheets: TAccountFirestoreData = workbook.SheetNames.filter(
        sName => sName !== "Legend"
      ).map(name => {
        const sheet = workbook.Sheets[name];
        // strip headers (row 3) of special characters, and camelCase.
        for (let count = 0; count <= 100; count++) {
          const cell = sheet[xlsx.utils.encode_cell({ r: 2, c: count })];
          if (!cell) break;
          Object.keys(cell).forEach(key => {
            cell[key] = camelCase(`${cell[key]}`.replace(/[^A-Za-z0-9]/g, ""));
          });
        }

        const json: Array<any> = xlsx.utils.sheet_to_json(sheet, {
          range: EXCEL_OFFSET
        });

        const validation: IValidationResult<TAccountExcelParsed> = json.reduce(
          (valAcc, row, index) => {
            try {
              const trimmedRow = Object.keys(row).reduce((evAcc, key) => {
                const ev = row as any;
                return {
                  ...evAcc,
                  [key]: typeof ev[key] === "string" ? ev[key].trim() : ev[key]
                };
              }, {}) as TAccountExcelParsed;

              const result = validateExcelParsedAccount(trimmedRow);
              if (result) {
                return {
                  failed: valAcc.failed,
                  passed: [...valAcc.passed, row]
                };
              }
            } catch (err) {
              console.error(err);
              return {
                passed: valAcc.passed,
                failed: [
                  ...valAcc.failed,
                  { ...err, row: index + EXCEL_OFFSET * 2 }
                ]
              };
            }
            return valAcc;
          },
          { passed: [], failed: [] } as IValidationResult<TAccountExcelParsed>
        );

        return {
          name,
          data: serializeAccounts(validation.passed) as TAccountForm[],
          failed: validation.failed
        };
      });

      setAccountData(sheets);
    }
  }, [workbook]);


  const resetForm = React.useCallback(() => {
    setWorkbookValid(undefined);
    setWorkbook(undefined);
    setAccountData([]);
    setRejectedFilesLocal(undefined);
    setLoading(false);
    setUploadResponse(undefined);
  }, []);

  const isFileTooLarge = React.useMemo(
    () =>
      rejectedFilesLocal &&
      rejectedFilesLocal.length > 0 &&
      rejectedFilesLocal[0].size > MAX_FILE_SIZE,
    [rejectedFilesLocal]
  );

  return {
    dropzoneState,
    isFileTooLarge,
    onUploadSubmit,
    accountData,
    workbookValid,
    workbook,
    rejectedFilesLocal,
    resetForm,
    loading,
    uploadResponse
  };
};
