import React from "react";
import { Flex, Box, Text } from "rebass";

export interface IPaginationProps {
  gotoPage: any;
  previousPage: any;
  nextPage: any;
  pageIndex: any;
  pageOptions: any;
  pageSize: any;
  setPageSize: any;
  canPreviousPage: any;
  canNextPage: any;
  pageCount: any;
}

const Pagination: React.FC<IPaginationProps> = props => {
  const {
    gotoPage,
    previousPage,
    nextPage,
    pageIndex,
    pageOptions,
    pageSize,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount
  } = props;
  return (
    <Flex
      className="pagination"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      mb={4}
      sx={{
        "> *": {
          p: "10px"
        }
      }}
    >
      <Flex width="auto" sx={{ button: { lineHeight: "1.5" } }}>
        <Box>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
        </Box>
        <Box>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
        </Box>
        <Box>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
        </Box>
        <Box>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </Box>
      </Flex>
      <Box>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </Box>
      <Flex width="auto" alignItems="center">
        <Text mr={1}>Go to page: </Text>
        <Box
          as="input"
          type="number"
          defaultValue={pageIndex + 1}
          py={1}
          onChange={(e: any) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px" }}
        />
      </Flex>
      <Box>
        <Box
          as="select"
          value={pageSize}
          onChange={(e: any) => {
            setPageSize(Number(e.target.value));
          }}
          py={1}
          width="100px"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default Pagination;
