import { SxStyleProp } from "rebass";

export const avatarContainerStyles: SxStyleProp = {
  position: "relative",
  ".__react_component_tooltip": {
    padding: 0
  },
  ".__react_component_tooltip:before, .__react_component_tooltip:after": {
    border: "none"
  },
  ".__react_component_tooltip.type-light.border": {
    borderColor: "lightGray"
  }
};

export const avatarButtonStyles: SxStyleProp = {
  padding: 0,
  marginRight: "5px",
  borderRadius: "50%",
  cursor: "pointer",
  backgroundColor: "transparent",
  border: "none",
  lineHeight: 0,
  transition: "all cubic-bezier(.4,0,.2,1) 180ms",
  "&:hover": {
    boxShadow: "0 0 0 4px rgba(33,33,33,.14)"
  }
};

export const avatarSpanStyles: SxStyleProp = {
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  overflow: "hidden",
  display: "inline-block",
  "&:after": {
    content: '""',
    boxSizing: 'border-box',
    borderRadius: "100%",
    height: "16px",
    width: "16px",
    backgroundColor: "#19ad79",
    position: "absolute",
    bottom: "-1px",
    right: "1px",
    border: "2px solid #fff",
    opacity: 0.98
  }
};
export const avatarImageStyles: SxStyleProp = {
  lineHeight: "32px",
  verticalAlign: "middle",
  width: "100%",
  height: "100%",
  textAlign: "center"
};
