/** Lib */
import React from "react";

/** UI */
import { Flex, BoxProps, Box } from "rebass";
import Radio from "@/ui/common/form-elements/radio/Radio.common";
import Input from "@common/form-elements/input/Input.common";
import Select from "@common/form-elements/select/Select.common.new";

/** Types */
import { IShowFilterState } from "@/ui/screens/dashboard/admin-dashboard/AdminDashboard.hooks";
import { TUserFirestore } from "@typings/users/users.types";
import { TBusinessFirestore } from "@/typings/business/business.types";

/** Styles */
const CHECKBOX_CONTAINER_PROPS: BoxProps = {
  sx: {
    cursor: "pointer",
    ml: 0,
    my: 0,
    mr: 0,
    "&:first-of-type": {
      ml: 0,
    },
    "&:last-of-type": {
      mr: 0,
    },
  },
};

export interface IDashboardFiltersProps {
  onSearchInputChange: (ev: React.ChangeEvent<any>) => void;
  onSearchBusinessSelectChange?: Function;
  searchTerm: string;
  searchKeyBusiness?: string;
  onFilterCheckboxChange: (filterType: keyof IShowFilterState) => (ev: React.ChangeEvent<HTMLInputElement>) => void;
  showFilterState: IShowFilterState;
  user: TUserFirestore;
  business?: Array<TBusinessFirestore>;
}

const DashboardFilters: React.NamedExoticComponent<IDashboardFiltersProps> = React.memo((props) => {
  const {
    onFilterCheckboxChange,
    showFilterState,
    onSearchInputChange,
    onSearchBusinessSelectChange = () => {},
    searchTerm,
    searchKeyBusiness,
    business,
  } = props;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={3} flexWrap="wrap">
        <Box
          width={[1, 1 / 2]}
          mb={[3, 0]}
          sx={{
            display: "grid",
            // gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
            gridTemplateColumns: [
              "min-content min-content",
              "min-content min-content",
              "min-content min-content min-content",
              "min-content min-content min-content min-content",
              "min-content min-content min-content min-content min-content",
            ],
            gridAutoRows: "20px",
            gridColumnGap: "20px",
            gridRowGap: "10px",
          }}
        >
          <Radio
            containerProps={CHECKBOX_CONTAINER_PROPS}
            name="requested"
            id="requested"
            label="Requested"
            onChange={onFilterCheckboxChange("requested")}
            checked={showFilterState.requested}
          />
          <Radio
            containerProps={CHECKBOX_CONTAINER_PROPS}
            name="approved"
            id="approved"
            label="Approved"
            onChange={onFilterCheckboxChange("approved")}
            checked={showFilterState.approved}
          />

          <Radio
            containerProps={CHECKBOX_CONTAINER_PROPS}
            name="booked"
            id="booked"
            label="Booked"
            onChange={onFilterCheckboxChange("booked")}
            checked={showFilterState.booked}
          />
          <Radio
            containerProps={CHECKBOX_CONTAINER_PROPS}
            name="executed"
            id="executed"
            label="Executed"
            onChange={onFilterCheckboxChange("executed")}
            checked={showFilterState.executed}
          />
          <Radio
            containerProps={CHECKBOX_CONTAINER_PROPS}
            name="denied"
            id="denied"
            label="Denied"
            onChange={onFilterCheckboxChange("denied")}
            checked={showFilterState.denied}
          />
        </Box>
        <Flex width={[1, 1 / 3]} justifyContent={["flex-start", "flex-end"]} mb={[3, 0]}>
          {!!business && !!business.length && (
            <Box width={[1, 1 / 2]} mx={2}>
              <Select
                name="business"
                label="Business Entity"
                options={business
                  .map((item) => ({
                    label: item.accountName,
                    value: item.id,
                  }))
                  .concat({ label: "All", value: "" })}
                value={{
                  value: searchKeyBusiness || "",
                  label: business.find((item) => item.id === searchKeyBusiness)?.accountName || "All",
                }}
                onChange={(val) => {
                  onSearchBusinessSelectChange(val);
                }}
              />
            </Box>
          )}
          <Box width={[1, 1 / 2]}>
            <Input
              name="dashboard-search"
              label="Event Search"
              onChange={onSearchInputChange}
              value={searchTerm}
              minWidth="265px"
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
});

export default DashboardFilters;
