/** Lib */
import * as firebase from "firebase/app";
import md5 from "md5";
import { convertToLocalTime } from "date-fns-timezone";

/** Util */
import convertExcelDate from "@util/convertExcelDate";

/** Types */
import {
  TExcelEventParsed,
  TFirestoreEvent,
  TFirestoreFormEvent,
  TFirestoreUpdateFormEvent,
} from "@typings/events/events.types";
import { addDays } from "date-fns";

export const serializeEvent = (event: TExcelEventParsed): TFirestoreEvent => {
  const concat = `${event.eventStartTime}${event.eventEndTime}${event.accountName}${event.brand}${event.streetAddress}${event.city}${event.state}`;
  const id = md5(concat);

  let eventStartTime =
    typeof event.eventDate === "number" && typeof event.eventStartTime === "number"
      ? convertExcelDate(event.eventDate, event.eventStartTime)
      : "";

  let amEventEndTime =
    typeof event.eventDate === "number" && typeof event.eventStartTime === "number"
      ? convertExcelDate(event.eventDate, event.eventEndTime)
      : "";

  // Account for events that go into the A.M. (listed as single day in Excel)
  if (event.eventEndTime < event.eventStartTime) {
    amEventEndTime = addDays(amEventEndTime as Date, 1);
  }

  const { businessEntity, ...rest } = event;

  return {
    ...rest,
    id,
    // convert zip to string.
    zipCode: event.zipCode ? `${event.zipCode}` : "",
    createdBy: firebase.auth().currentUser?.uid as string,
    status: event.status.toLowerCase(),
    eventStartTime,
    eventEndTime: amEventEndTime,
    invitedUsers: [],
    acceptedInviteUsers: [],
    declinedInviteUsers: [],
    declinedByAdminUsers: [],
    confirmedByAdminUsers: [],
    designatedAmbassador: "",
    business: businessEntity,
  };
};

export const serializeFormEvent = (event: TFirestoreFormEvent): TFirestoreEvent => {
  const concat = `${event.eventStartTime}${event.eventEndTime}${event.accountName}${event.brand}${event.streetAddress}${event.city}${event.state}`;
  const id = md5(concat);

  const convertedEventStartTime = convertToLocalTime(event.eventStartTime, {
    timeZone: event.timezone,
  });
  const convertedEventEndTime = convertToLocalTime(event.eventEndTime, {
    timeZone: event.timezone,
  });

  return {
    ...event,
    id,
    // convert zip to string.
    zipCode: event.zipCode ? `${event.zipCode}` : "",
    createdBy: firebase.auth().currentUser?.uid as string,
    status: event.status.toLowerCase(),
    eventStartTime: convertedEventStartTime,
    eventEndTime: convertedEventEndTime,
    invitedUsers: [],
    acceptedInviteUsers: [],
    declinedInviteUsers: [],
    declinedByAdminUsers: [],
    confirmedByAdminUsers: [],
    designatedAmbassador: "",
  };
};

export const serializeUpdateFormEvent = (event: TFirestoreUpdateFormEvent): Partial<TFirestoreEvent> => {
  const convertedEventStartTime = convertToLocalTime(event.eventStartTime, {
    timeZone: event.timezone,
  });
  const convertedEventEndTime = convertToLocalTime(event.eventEndTime, {
    timeZone: event.timezone,
  });

  return {
    ...event,
    zipCode: event.zipCode ? `${event.zipCode}` : "",
    status: event.status.toLowerCase(),
    eventStartTime: convertedEventStartTime,
    eventEndTime: convertedEventEndTime,
  };
};

export default (events: TExcelEventParsed[] | TExcelEventParsed): TFirestoreEvent | TFirestoreEvent[] => {
  if (Array.isArray(events)) {
    return events.map((event) => {
      return serializeEvent(event);
    });
  }
  return serializeEvent(events);
};
