/** Lib */
import React from "react";

/** Services */
import EventsService from "@services/events/events.service";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";

export default (eventId: string) => {
  const [subscribed, setSubscribed] = React.useState(false);
  const [event, setEvent] = React.useState<TFirestoreEvent>();

  React.useEffect(() => {
    if (!subscribed) {
      EventsService.subscribeToSingle(eventId, doc => {
        const data = doc.data() as TFirestoreEvent;
        setEvent(data);
        setSubscribed(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribed]);

  return event;
};
