/** Lib */
import React from "react";

import { Box } from 'rebass';

/** Types */
import { TAccountFirestore } from "@typings/accounts/accounts.types";

export interface IAccountDetailsProps {
  account: TAccountFirestore;
}

const AccountDetails: React.FC<IAccountDetailsProps> = (props) => {
  const { account } = props;
  return (
    <div>
      <Box>
        <ul>
          {Object.keys(account).map((key) => {
            return (
              <li key={key}>
                {key}: {JSON.stringify((account as any)[key])}
              </li>
            );
          })}
        </ul>
      </Box>
    </div>
  );
};

export default AccountDetails;
