/** Lib */
import React from "react";
import {
  useTable,
  useRowSelect,
  useSortBy,
  usePagination,
  useGlobalFilter,
  Hooks
} from "react-table";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";
import { ICheckboxTableProps } from "./CheckboxTable.common";
import Checkbox from "../form-elements/checkbox/Checkbox.common";

function useCheckboxTableHooks<RowDataType>(
  props: ICheckboxTableProps<RowDataType>
) {
  const {
    tableData,
    columns,
    defaultSortBy,
    onSelectionChange,
    filterValue,
    onRowClick: onRowClickProp
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    page,
    // setFilter,
    setGlobalFilter,
    state: { selectedRowIds, pageIndex, pageSize }
  } = useTable(
    {
      columns: columns || [],
      data: tableData || [],
      initialState: defaultSortBy
        ? {
            pageSize: 10,
            sortBy: defaultSortBy
          }
        : {}
    },

    useGlobalFilter,
    useSortBy,
    useRowSelect,
    usePagination,
    (hooks: Hooks<TUserFirestore>) => {
      hooks.flatColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: (context: any) => {
            const allProps = context.getToggleAllRowsSelectedProps();
            return (
              <div>
                <Checkbox
                  id={`select-all-${props.id}`}
                  {...allProps}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                      typeof props.selectionMax === "number" &&
                      (context.selectedFlatRows.length === props.selectionMax ||
                        context.rows.length > props.selectionMax)
                    ) {
                      return false;
                    }
                    return allProps.onChange(ev);
                  }}
                  labelSx={{
                    mr: 0,
                    width: "100%"
                  }}
                />
              </div>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: (context: any) => {
            const rowProps = context.row.getToggleRowSelectedProps();
            if (typeof props.selectionMax === "number") {
              return (
                <div>
                  <Checkbox
                    id={context.row.original.id}
                    {...rowProps}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        typeof props.selectionMax === "number" &&
                        rowProps.checked === false &&
                        context.selectedFlatRows.length === props.selectionMax
                      ) {
                        // changing from unchecked to checked AND selection is already at max, set this row to false.
                        return context.row.toggleRowSelected(false);
                      }
                      // else proceed as normal.
                      return rowProps.onChange(ev);
                    }}
                    labelSx={{
                      mr: 0,
                      width: "100%"
                    }}
                  />
                </div>
              );
            }
            return (
              <div>
                <Checkbox
                  id={context.row.original.id}
                  {...rowProps}
                  labelSx={{
                    mr: 0,
                    width: "100%"
                  }}
                />
              </div>
            );
          }
        },
        ...columns
      ]);
    }
  );

  const onRowClick = React.useCallback(
    (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (onRowClickProp) {
        onRowClickProp(row);
      }
    },
    [onRowClickProp]
  );

  React.useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedFlatRows);
    }
  }, [selectedFlatRows, onSelectionChange]);

  React.useEffect(() => {
    if (typeof filterValue === "string") {
      setGlobalFilter(filterValue);
    }

    // eslint-disable-next-line
  }, [filterValue]);

  return {
    onRowClick,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    selectedRowIds,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageSize,
    pageIndex
  };
}

export default useCheckboxTableHooks;
