/** Lib */
import React, { HTMLProps } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

/** UI */
import { Box, Text, BoxProps } from "rebass";

/** Styles */
import {
  inputContainerStyles,
  inputLabelStyles,
  datePickerStyles,
} from "../FormElement.styles";

/** Types */
import { InterpolationWithTheme } from "@emotion/core";

export type TDatePickerProps = {
  name: string;
  id?: string;
  label: string;
  required?: boolean;
  error?: string;
  containerProps?: BoxProps;
  css?: InterpolationWithTheme<any>;
} & BoxProps &
  ReactDatePickerProps &
  HTMLProps<HTMLInputElement>;

const CustomDateInput: React.NamedExoticComponent<any> = React.forwardRef(
  (props, ref) => (
    <input
      {...props}
      ref={ref as any}
      style={{ color: (props as any).disabled ? "rgb(98,98,98)" : undefined }}
    />
  )
);

const DatePicker: React.ForwardRefExoticComponent<TDatePickerProps> = React.forwardRef(
  (props, ref) => {
    /**
     * Props
     */
    const {
      onFocus,
      name,
      onBlur,
      required,
      error,
      disabled,
      containerProps,
    } = props;
    /**
     * State
     */
    const [focused, setFocused] = React.useState(false);

    const onDatePickerFocus = React.useCallback(
      (ev: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(ev);
        }
        setFocused(true);
      },
      [onFocus]
    );

    const onDatePickerBlur = React.useCallback(
      (ev: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(ev);
        }
        setFocused(false);
      },
      [onBlur]
    );

    return (
      <Box className="form-control" {...(containerProps ? containerProps : {})}>
        <Box
          className="datepicker-container"
          sx={inputContainerStyles(focused, !!required, error, disabled)}
          overflow="visible"
        >
          <Text as="label" variant="sectionHeading" sx={inputLabelStyles}>
            {props.label}
          </Text>
          <Box sx={datePickerStyles}>
            <ReactDatePicker
              {...props}
              customInput={<CustomDateInput {...props} name={name} ref={ref} />}
              onFocus={onDatePickerFocus}
              onBlur={onDatePickerBlur}
              autoComplete="off"
            />
          </Box>
        </Box>
        {error && (
          <Text color="danger" fontSize={1} pt={1} pl={1}>
            {error}
          </Text>
        )}
      </Box>
    );
  }
);

export default DatePicker;
