import * as actionCreators from "./events.actions";
import eventReducer from "./events.reducer";
import createStore from "@util/createStore";

/** Types */
import { EventState, EventAction, EventActions } from "./events.types";

export const EVENT_INITIAL_STATE = {
  allEvents: [],
  ownEvents: undefined,
  ownAmbassadorEvents: undefined,
  subscribedToAll: false,
  subscribedToOwn: false,
  subscribedToOwnAmbassador: false
};

const { StoreContext, StoreContextProvider, useStore } = createStore<
  EventState,
  EventAction<any>,
  EventActions
>(EVENT_INITIAL_STATE, eventReducer, actionCreators);

export {
  StoreContext as EventsContext,
  StoreContextProvider as EventsContextProvider,
  useStore
};
