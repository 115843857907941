/** Lib */
import React from "react";
import moment from "moment";

/** Hooks */
import hooks from "./Calendar.hooks";
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

/** UI */
import { Box } from "rebass";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import Select from "@common/form-elements/select/Select.common.new";

/** Styles */
import "./Calendar.styles.scss";

const localizer = momentLocalizer(moment);

export type TCalendarProps = Router.RouteComponentProps & { auth: AuthUser };

const Calendar: React.FC<TCalendarProps> = props => {
  const { events, onSearchKeyChange, searchKeyBusiness } = hooks(props);
  const { business } = useSubscribeToBusiness();
  const ownUser = useSubscribeToOwnUser();

  return (
    <Box pt={4} px="20px">
      <Box 
        className={`business-selector ${ownUser?.role}`}
        width={[1, 1/6]}
      >
        <Select
          name="business"
          label="Business Entity"
          options={
            business.map((item) => ({
              label: item.accountName,
              value: item.id,
            })).concat({label: 'All', value: ''})
          }
          value={{value: searchKeyBusiness, label: business.find(item => item.id === searchKeyBusiness)?.accountName || 'ALL'}}
          onChange={(ev) => onSearchKeyChange(ev)}
        />
      </Box>

      <Box height={800} backgroundColor="white">
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week", "day"]}
          onSelectEvent={ev => {
            props.history.push(`events/${ev.id}`, ev);
          }}
          eventPropGetter={event => {
            return {
              style: {
                backgroundColor: event.bgColor
              }
            };
          }}
        />
      </Box>
    </Box>
  );
};

export default Calendar;
