/** Lib */
import React from "react";

/** Stores */
import { useStore } from "@stores/events/events.store";
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/** Services */
import EventsService from "@services/events/events.service";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import { USER_ROLES } from "@/stores/auth/auth.constants";

export default () => {
  const { state, actions } = useStore();
  const {
    state: { user: auth },
  } = useAuthStore();

  const { subscribedToAll } = state;

  React.useEffect(() => {
    if (!subscribedToAll && auth && [USER_ROLES.admin, USER_ROLES.superAdmin].includes(auth.claims.role)) {
      EventsService.subscribeToAll((events) => {
        const data = events.docs.map((doc) => doc.data()) as TFirestoreEvent[];
        actions.setEvents(data);
        actions.setSubscribedToAll(true);
      });
    } else if (auth?.claims.role === USER_ROLES.eventCreator) {
      EventsService.subscribeToOwn((events) => {
        const data = events.docs.map((doc) => doc.data()) as TFirestoreEvent[];
        actions.setEvents(data);
        actions.setSubscribedToAll(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToAll, auth]);

  return state;
};
