import React from "react";
import { Box, SxStyleProp, BoxProps } from "rebass";

/** Types */
import { InterpolationWithTheme } from "@emotion/core";

const Column: React.FC<BoxProps & {
  fluidWidth?: boolean;
  col?: number | number[];
  css?: InterpolationWithTheme<any>;
}> = props => {
  const { className, fluidWidth, col, sx } = props;

  const _className = React.useMemo(() => {
    const baseClassName = fluidWidth ? "c-col-fluid" : "c-col";
    return typeof className === "string"
      ? `${baseClassName} ${className}`
      : baseClassName;
  }, [className, fluidWidth]);

  const _sx = React.useMemo<SxStyleProp>(() => {
    let fValue = {
      ...sx
    };
    if (Array.isArray(col)) {
      fValue = {
        ...fValue,
        flex: col.map(_col => `0 0 ${(_col / 16) * 100}%`),
        maxWidth: col.map(_col => `${(_col / 16) * 100}%`)
      };
    } else if (typeof col === "number") {
      fValue = {
        ...fValue,
        flex: `0 0 ${(col / 16) * 100}%`,
        maxWidth: `${(col / 16) * 100}%`
      };
    }
    return {
      ...fValue,
      ...(fluidWidth
        ? {
            flex: ["initial", "0 0 auto"],
            width: "auto",
            maxWidth: "100%"
          }
        : {
            flexBasis: ["initial", 0],
            flexGrow: ["initial", 1],
            maxWidth: "100%"
          })
    };
  }, [col, sx, fluidWidth]);

  return <Box {...props} px="15px" sx={_sx} className={_className}></Box>;
};

export default Column;
