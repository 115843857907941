/** Types */
import { SxStyleProp } from "rebass";

/** Shared styles */

export const formControlStyles: SxStyleProp = {
  border: "none",
  height: "25px",
  minHeight: "25px",
  padding: 0,
  marginTop: "-4px",
  background: "0 0",
  WebkitAppearance: "none",
  color: "darkGray",
  outline: 0,
  verticalAlign: "middle",
  fontSize: "16px",
  "&::placeholder": {
    color: "#b9b9b9"
  }
};

export const formContainerStyles: (
  focused: boolean,
  required: boolean,
  error?: string,
  disabled?: boolean
) => SxStyleProp = (focused, required, error, disabled) => ({
  backgroundColor: focused ? "lighterGray" : disabled ? "hsl(0,0%,95%)" :"white",
  position: "relative",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: error && error.length ? "danger" : "lightGray",
  borderRadius: "2px",
  paddingTop: "7px",
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingBottom: "4px",
  overflow: "hidden",
  width: "100%",
  transition: "background-color .2s ease",
  "&:after": required
    ? {
        color: "danger",
        content: '"*"',
        fontSize: "20px",
        position: "absolute",
        right: "12px",
        top: "6px"
      }
    : {}
});

/** Input */

export const inputStyles: SxStyleProp = {
  ...formControlStyles,
  width: "100%"
};

export const inputLabelStyles: SxStyleProp = {
  lineHeight: 1.25,
  display: "block"
};

export const inputContainerStyles = formContainerStyles;

/**
 * Text Area
 */

export const textAreaStyles: SxStyleProp = {
  ...formControlStyles,
  width: "100%",
  minHeight: "100px"
};

export const textAreaLabelStyles: SxStyleProp = inputLabelStyles;

export const textAreaContainerStyles: typeof formContainerStyles = (...params) => ({ ...formContainerStyles(...params), paddingTop: '12px' });

/** Datepicker */
export const datePickerStyles = {
  input: {
    ...inputStyles
  },
  ".react-datepicker-popper": {
    zIndex: 2
  },
  ".react-datepicker-wrapper": {
    width: "100%"
  }
};
