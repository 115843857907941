import { StylesConfig } from "react-select";
import { SxStyleProp } from "rebass";

export const reactSelectStyles: (
  error?: string,
  externalLabel?: boolean,
  disabled?: boolean
) => StylesConfig = (error, externalLabel, disabled) => ({
  control: provided => ({
    ...provided,
    minHeight: !externalLabel ? "53px" : "25px",
    borderRadius: 0,
    borderColor: error ? "#F55753" : "#e5e5e5",
    alignItems: !externalLabel ? "flex-end" : "center",
    cursor: "pointer",
    fontSize: '16px'
  }),
  valueContainer: provided => ({
    ...provided,
    marginTop: !externalLabel ? "20px" : 0
  }),
  input: provided => ({
    ...provided
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2
  }),
  multiValueLabel: provided => ({
    ...provided,
    zIndex: 2
  }),
  indicatorSeparator: provided => ({
    opacity: 0
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: !externalLabel ? provided.padding : "6px"
  }),
  singleValue: provided => !disabled ? provided : ({
    ...provided,
    color: "rgb(98,98,98)"
  }),
});

export const selectLabelStyles = (externalLabel?: boolean): SxStyleProp => {
  return {
    position: !externalLabel ? "absolute" : undefined,
    top: "7px",
    left: "20px",
    mb: 1,
    zIndex: 1
  };
};
