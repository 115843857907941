/** Lib */
import * as firebase from "firebase/app"

/** Constants */
import { FIREBASE_USERS_RESOURCE_NAME } from "@constants/users.constants";
import { USER_ROLES } from "@/stores/auth/auth.constants";
import { IUserUpdate, TUserFirestore } from "@/typings/users/users.types";

const UsersService = {
  getAll() {
    return firebase
      .firestore()
      .collection("UserInfo")
      .where("role", "in", ["admin", "brandAmbassador", "eventCreator"])
      .get();
  },
  getById(id: string) {
    return firebase
      .firestore()
      .collection("UserInfo")
      .doc(id)
      .get();
  },
  getByEmail(email: string) {
    return firebase
      .firestore()
      .collection("UserInfo")
      .where("role", "in", ["admin", "brandAmbassador", "eventCreator"])
      .where('email', '==', email)
      .limit(1)
      .get();
  },
  async inviteUser(email: string, role: AuthUserRoles, businessEntityId: string) {
    return firebase
      .functions()
      .httpsCallable("inviteUser")({
        email,
        role,
        businessEntityId
      })
      .catch(err => {
        console.error("UsersService: Error inviting user", err);
      });
  },
  async confirmUsersToEvent(eventId: string, ambassadors: TUserFirestore[]) {
    return firebase
      .functions()
      .httpsCallable("confirmUsersToEvent")({
        eventId,
        ambassadors
      })
      .catch(err => {
        console.error("UsersService: Error confirming users to Event", err);
      });
  },
  async revokeUsersConfirmationToEvent(
    eventId: string,
    ambassadors: TUserFirestore[]
  ) {
    return firebase
      .functions()
      .httpsCallable("revokeUsersConfirmationToEvent")({
        eventId,
        ambassadors
      })
      .catch(err => {
        console.error("UsersService: Error revoking users to Event", err);
      });
  },
  async sendSignInLink(email: string) {
    return firebase
      .functions()
      .httpsCallable("sendSignInLink")({
        email
      })
      .catch(err => {
        console.error("UsersService: Error sending sign in link", err);
      });
  },
  async resendInviteUser(id: string, email: string, role: AuthUserRoles) {
    return firebase
      .functions()
      .httpsCallable("resendInvite")({
        id,
        email,
        role
      })
      .catch(err => {
        console.error("UsersService: Error notifying ambassador", err);
      });
  },
  subscribeToAllUsers(
    snapshotCallback: (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_USERS_RESOURCE_NAME)
      .where("role", "in", [
        USER_ROLES.admin,
        USER_ROLES.brandAmbassador,
        USER_ROLES.eventCreator
      ])
      .onSnapshot(snapshotCallback, err => {
        console.error(`Error occurred subscribing to users`, err);
      });
  },
  subscribeToUserById(
    id: string,
    snapshotCallback: (
      snapshot: firebase.firestore.DocumentSnapshot<
        firebase.firestore.DocumentData
      >
    ) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_USERS_RESOURCE_NAME)
      .doc(id)
      .onSnapshot(snapshotCallback, err => {
        console.error(`Error occurred subscribing to user`, err);
      });
  },
  subscribeToUsersByRole(
    role: AuthUserRoles,
    snapshotCallback: (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_USERS_RESOURCE_NAME)
      .where("role", "==", role)
      .onSnapshot(snapshotCallback, err => {
        console.error(`Error occurred subscribing to users`, err);
      });
  },
  updateOwnPassword(password: string) {
    return firebase.auth().currentUser?.updatePassword(password);
  },
  updateById(id: string, payload: IUserUpdate) {
    return firebase
      .firestore()
      .collection("UserInfo")
      .doc(id)
      .update(payload);
  },
  delete(id: string) {
    return firebase
      .firestore()
      .collection("UserInfo")
      .doc(id)
      .delete();
  },
  inviteUsersToEvent(eventId: string, ambassadors: string[]) {
    return firebase
      .functions()
      .httpsCallable("inviteUsersToEvent")({
        eventId,
        ambassadors
      })
      .catch(err => {
        console.error("UsersService: Error notifying ambassador", err);
      });
  }
};

export default UsersService;
