/** Lib */
import React from "react";

/** UI */
import DashboardFilters from "@/ui/components/dashboard/DashboardFilters.component";
import { Heading, Flex } from "rebass";

/** Hooks */
import hooks from "./EventCreatorDashboard.hooks";
import { Container } from "@common/responsive";
import Table from "@common/table/Table.common";
import Card from "@/ui/common/card/Card.common";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import Loader from "@/ui/common/loader/Loader.common";

/** Style */
import './EventCreatorDashboard.styles.scss';

const EventCreatorDashboardScreen: React.FC<Router.RouteComponentProps> = props => {
  const {
    ownEvents,
    columns,
    onRowClick,
    onSearchInputChange,
    onFilterCheckboxChange,
    showFilterState,
    searchTerm,
    user
  } = hooks(props);
  return (
    <Container pt={4} className="dashboard event">
      {user ? (
        <>
          <DashboardFilters
            onSearchInputChange={onSearchInputChange}
            onFilterCheckboxChange={onFilterCheckboxChange}
            showFilterState={showFilterState}
            searchTerm={searchTerm}
            user={user}
          />

          <Card p={[3, 3, 4]}>
            {ownEvents && showFilterState.requested && (
              <>
                <Flex mb={3} flexWrap="wrap" justifyContent="space-between">
                  <Heading variant="small">Requested Events</Heading>
                </Flex>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={ownEvents.requested}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {ownEvents && showFilterState.approved && (
              <>
                <Heading variant="small" mb={3}>
                  Approved Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={ownEvents.approved}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {ownEvents && showFilterState.booked && (
              <>
                <Heading variant="small" mb={3}>
                  Booked Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={ownEvents.booked}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {ownEvents && showFilterState.executed && (
              <>
                <Heading variant="small" mb={3}>
                  Executed Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={ownEvents.executed}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {ownEvents && showFilterState.denied && (
              <>
                <Heading variant="small" mb={3}>
                  Denied Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={ownEvents.denied}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
          </Card>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      )}
    </Container>
  );
};

export default EventCreatorDashboardScreen;
