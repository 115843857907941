import { shade, tint } from "polished";

const baseColors = {
  primary: "#663399",
  gray: "#626262",
  complete: "#48B0F7",
  success: "#10CFBD",
  warning: "#F8D053",
  danger: "#F55753",
  info: "#3B4752"
};

const colors = {
  // base colors
  primary: baseColors.primary,
  primaryDark: shade(0.165, baseColors.primary),
  primaryDarker: shade(0.4, baseColors.primary),
  primaryLight: tint(0.2, baseColors.primary),
  primaryLighter: tint(0.8, baseColors.primary),
  complete: baseColors.complete,
  success: baseColors.success,
  warning: baseColors.warning,
  danger: baseColors.danger,
  info: baseColors.info,
  // shades
  white: "#ffffff",
  lightGray: tint(0.84, baseColors.gray),
  lighterGray: tint(0.915, baseColors.gray),
  lightestGray: tint(0.97, baseColors.gray),
  gray: baseColors.gray,
  darkGray: shade(0.55, baseColors.gray),
  darkerGray: shade(0.73, baseColors.gray),
  darkestGray: shade(0.88, baseColors.gray),
  // special colors
  slateGray: "rgb(120, 129, 149)",
  sharkGray: "rgb(33, 37, 44)",
  charadeGray: "rgb(43, 48, 59)"
};

const theme = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1600px"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    ...colors,
    border: colors.lightGray,
    text: colors.gray,
    heading: colors.darkGray
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body:
      "-apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;",
    heading:
      "-apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;",
    sectionHeading: "montserrat"
  },
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 500
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)"
  },
  variants: {},
  text: {
    heading: {
      color: "darkGray"
    },
    sectionHeading: {
      fontFamily: "sectionHeading",
      fontWeight: 500,
      fontSize: "10.5px",
      color: "gray",
      letterSpacing: ".06em",
      textTransform: "uppercase"
    },
    xlarge: {
      fontSize: 6,
      center: {
        fontSize: 6,
        textAlign: "center"
      }
    },
    large: {
      fontSize: 5,
      center: {
        fontSize: 5,
        textAlign: "center"
      }
    },
    medium: {
      fontSize: 4,
      center: {
        fontSize: 4,
        textAlign: "center"
      }
    },
    small: {
      fontSize: 3,
      center: {
        fontSize: 3,
        textAlign: "center"
      }
    },
    xsmall: {
      fontSize: 2,
      center: {
        fontSize: 2,
        textAlign: "center"
      }
    }
  },
  buttons: {
    blank: {
      color: "inherit",
      bg: "transparent",
      cursor: "pointer",
      borderRadius: "0",
      p: 0
    },
    anchor: {
      color: "primary",
      bg: "transparent",
      display: "inline",
      cursor: "pointer",
      borderRadius: "0",
      textDecoration: "underline",
      p: 0,
      m: 0
    },
    primary: {
      color: "white",
      bg: "primary",
      transition: "background-color 150ms",
      cursor: "pointer",
      "&:hover": {
        bg: "primaryDark"
      },
      disabled: {
        opacity: 0.65
      }
    },
    secondary: {
      bg: "lightestGray",
      borderColor: "lightGray",
      borderStyle: "solid",
      borderWidth: "1px",
      color: "gray",
      cursor: "pointer",
      "&:hover": {
        bg: "lightGray"
      }
    },
    warning: {
      color: "darkGray",
      bg: "warning",
      transition: "background-color 150ms",
      cursor: "pointer",
      "&:hover": {
        bg: shade(0.165, baseColors.warning)
      }
    },
    danger: {
      color: "white",
      bg: "danger",
      transition: "background-color 150ms",
      cursor: "pointer",
      "&:hover": {
        bg: shade(0.165, baseColors.danger)
      },
      disabled: {
        opacity: 0.65,
        color: "white",
        bg: "danger",
        transition: "background-color 150ms",
        cursor: "pointer",
        "&:hover": {
          bg: shade(0.165, baseColors.danger)
        }
      }
    }
  }
};

export type TTheme = typeof theme;

export default theme as TTheme;
