/** Lib */
import React from "react";

/** Hooks */
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

export default () => {
  const ownUser = useSubscribeToOwnUser();

  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>();

  const onSubmitSuccess = React.useCallback(() => {
    setSubmitSuccess(true);
  }, []);
  const onSubmitError = React.useCallback(() => {
    setSubmitSuccess(false);
  }, []);

  return {
    ownUser,
    submitSuccess,
    onSubmitSuccess,
    onSubmitError
  };
};
