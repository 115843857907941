/**
 * Constants
 */
export const SET_ALL = "SET_ALL";
export const SET_OWN = "SET_OWN";
export const SET_SUBSCRIBED_TO_ALL = "SET_SUBSCRIBED_TO_ALL";
export const SET_SUBSCRIBED_TO_OWN = "SET_SUBSCRIBED_TO_OWN";
export const SET_EVENT_CREATORS = "SET_EVENT_CREATORS";
export const SET_SUBSCRIBED_TO_EVENT_CREATORS =
  "SET_SUBSCRIBED_TO_EVENT_CREATORS";
export const SET_AMBASSADORS = "SET_AMBASSADORS";
export const SET_SUBSCRIBED_TO_AMBASSADORS = "SET_SUBSCRIBED_TO_AMBASSADORS";
