/** Lib */
import React from "react";
import { Link as RouterLink } from "react-router-dom";

/** Hooks */
import hooks from "./Header.hooks";

/** UI */
import { Box, Heading, Flex } from "rebass";
import { Container } from "@common/responsive";
import { IconHamburger } from "@/ui/common/icon/Icons.common";
import UserAvatar from "./avatar/UserAvatar";

/** Types */
export type THeaderProps = {
  setMobileMenuExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  mobileMenuExpanded: boolean;
};

const Header: React.FC<THeaderProps> = (props) => {
  const { onLogoutClick, ownUser } = hooks();

  return (
    <Flex
      className="header"
      height="60px"
      bg="white"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: "lightGray",
      }}
    >
      <Container className="header-container" flex="1">
        <Flex justifyContent="space-between">
          <Box
            as="button"
            className="hamburger-menu"
            display={["block", "block", "block", "none"]}
            sx={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setMobileMenuExpanded(!props.mobileMenuExpanded);
            }}
          >
            <IconHamburger />
          </Box>
          {ownUser && ownUser.active && (
            <Flex
              className="logo-container"
              alignItems="center"
              sx={{
                a: {
                  color: "info",
                  textDecoration: "none",
                  transition: "color 150ms ease-in-out",
                  "&:hover": {
                    color: "primary",
                  },
                },
              }}
            >
              <RouterLink to="/">
                <Heading
                  as="h2"
                  variant="xsmall"
                  lineHeight={1}
                  display={["none", "none", "none", "inline"]}
                >
                  Hi, {`${ownUser.firstName} ${ownUser.lastName}`}
                </Heading>
              </RouterLink>
            </Flex>
          )}
          {ownUser && ownUser.active && (
            <Flex alignItems="center">
              <UserAvatar user={ownUser} onLogoutClick={onLogoutClick} />
            </Flex>
          )}
        </Flex>
      </Container>
    </Flex>
  );
};

export default Header;
