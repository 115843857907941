//========================================================================================
/*                                                                                      *
 * IMPORTS                                                                              *
 *                                                                                      */
//========================================================================================

/**
 * * LIB IMPORTS
 */
import React from "react";

/**
 * * HOOKS IMPORTS
 */
import hooks from "./AmbassadorProfileForm.hooks";

/**
 * * TYPES IMPORTS
 */
import { TUserFirestore } from "@typings/users/users.types";

/**
 * * CONSTANTS IMPORTS
 */
import {
  // BILINGUAL_OPTIONS,
  STATE_OPTIONS,
  WILLING_TO_TRAVEL_OPTIONS,
  LANGUAGE_OPTIONS
} from "./AmbassadorProfileForm.constants";

/**
 * * UI IMPORTS
 */
import Input from "@/ui/common/form-elements/input/Input.common";
import { Flex, Box, Button } from "rebass";
import FormRow from "@/ui/common/form-elements/form-row/FormRow.common";
import Select from "@/ui/common/form-elements/select/Select.common.new";
import Loader from "@/ui/common/loader/Loader.common";

/**
 * * CONSTANTS
 */
const INPUT_CONTAINER_PROPS = {
  mb: [4, 0],
  flex: 1,
  px: 2
};

/**
 * * TYPES
 */
export interface IAmbassadorProfileFormProps {
  user: TUserFirestore;
  onSubmitSuccess: () => void;
  onSubmitError: () => void;
}

//========================================================================================
/*                                                                                      *
 * COMPONENT                                                                     *
 *                                                                                      */
//========================================================================================

const AmbassadorProfileForm: React.FC<IAmbassadorProfileFormProps> = props => {
  const { formik, loading, onResetClick, onLanguageChange } = hooks(props);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box maxWidth="600px">
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="firstName"
            label="First Name"
            required
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : undefined
            }
          />
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="lastName"
            label="Last Name"
            required
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="hairColor"
            label="Hair Color"
            placeholder="Blonde, Brown, Red, etc."
            required
            value={formik.values.hairColor}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.hairColor && formik.errors.hairColor
                ? formik.errors.hairColor
                : undefined
            }
          />
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="height"
            label="Height"
            placeholder="x'x"
            required
            value={formik.values.height}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.height && formik.errors.height
                ? formik.errors.height
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          {/* <Select
            containerProps={INPUT_CONTAINER_PROPS}
            name="bilingual"
            label="Bilingual?"
            required
            onChange={val => {
              formik.setFieldValue("bilingual", val);
            }}
            value={formik.values.bilingual}
            onBlur={formik.handleBlur}
            options={BILINGUAL_OPTIONS}
            error={
              formik.touched.bilingual && formik.errors.bilingual
                ? (formik.errors.bilingual as string)
                : undefined
            }
          /> */}
          <Select
            containerProps={INPUT_CONTAINER_PROPS}
            isMulti={true}
            name="languages"
            label="Languages"
            required
            onChange={onLanguageChange}
            value={formik.values.languages}
            onBlur={formik.handleBlur}
            options={LANGUAGE_OPTIONS}
            error={
              formik.touched.languages && formik.errors.languages
                ? (formik.errors.languages as string)
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="ethnicity"
            label="Ethnicity"
            value={formik.values.ethnicity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ethnicity && formik.errors.ethnicity
                ? formik.errors.ethnicity
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="streetAddress"
            label="Street Address"
            placeholder="123 Main St."
            required
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.streetAddress && formik.errors.streetAddress
                ? formik.errors.streetAddress
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="city"
            label="City"
            required
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.city && formik.errors.city
                ? formik.errors.city
                : undefined
            }
          />
          <Select
            containerProps={INPUT_CONTAINER_PROPS}
            options={STATE_OPTIONS}
            name="state"
            label="State"
            required
            value={formik.values.state}
            onChange={val => formik.setFieldValue("state", val)}
            onBlur={formik.handleBlur}
            error={
              formik.touched.state && formik.errors.state
                ? (formik.errors.state as string)
                : undefined
            }
          />
        </FormRow>

        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="zipCode"
            label="Zip Code"
            placeholder="xxxxx"
            required
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.zipCode && formik.errors.zipCode
                ? formik.errors.zipCode
                : undefined
            }
          />
          <Select
            containerProps={INPUT_CONTAINER_PROPS}
            sx={{
              maxWidth: "300px"
            }}
            name="willingToTravelDistance"
            label="Willing to Travel Distance"
            required
            value={formik.values.willingToTravelDistance}
            onChange={val =>
              formik.setFieldValue("willingToTravelDistance", val)
            }
            onBlur={formik.handleBlur}
            options={WILLING_TO_TRAVEL_OPTIONS}
            error={
              formik.touched.willingToTravelDistance &&
              formik.errors.willingToTravelDistance
                ? (formik.errors.willingToTravelDistance as string)
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="cellNumber"
            label="Cell Number"
            placeholder="(xxx) xxx-xxxx"
            required
            value={formik.values.cellNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.cellNumber && formik.errors.cellNumber
                ? formik.errors.cellNumber
                : undefined
            }
          />
        </FormRow>

        <Flex>
          <Button
            mx={2}
            type="submit"
            disabled={loading || !formik.dirty}
            variant={formik.dirty && !loading ? "primary" : "primary.disabled"}
          >
            {loading ? (
              <Loader height="15px" width="15px" m="0 auto" />
            ) : (
              "Submit"
            )}
          </Button>
          {formik.dirty && !loading && (
            <Button variant="secondary" type="button" onClick={onResetClick}>
              Reset Form
            </Button>
          )}
        </Flex>
      </Box>
    </form>
  );
};

export default AmbassadorProfileForm;
