import React from "react";
import { Box } from "rebass";
import LoginForm from "@components/login-form/LoginForm.component";
import { Container, Row, Column } from "@common/responsive";

const LoginScreen: React.FC<Router.RouteComponentProps> = props => {
  return (
    <Box py={50}>
      <Container className="login-container">
        <Row className="login-row">
          <Column>
            <LoginForm {...props} />
          </Column>
        </Row>
      </Container>
    </Box>
  );
};

export default LoginScreen;
