/** Lib */
import React from "react";
import { Link } from "react-router-dom";

/** Hooks */
import hooks from "./ScheduleEventsExcel.hooks";

/** UI */
import { Heading, Box, Button, Link as Anchor } from "rebass";
import { Container } from "@common/responsive";

/** Components */
import ScheduleEventsUploadResults from "@components/schedule-events/upload-results/ScheduleEventsUploadResults.component";
import ScheduleEventsForm from "@components/schedule-events/form/ScheduleEventsForm.component";
import ScheduleEventsValidationResults from "@components/schedule-events/validation-results/ScheduleEventsValidationResults.component";

const ScheduleEventsExcel: React.FC<Router.RouteComponentProps> = props => {
  const {
    dropzoneState,
    isFileTooLarge,
    onUploadSubmit,
    eventData,
    workbook,
    workbookValid,
    resetForm,
    rejectedFilesLocal,
    loading,
    uploadResponse
  } = hooks();

  return (
    <>
      <Container pt={4}>
        <Heading variant="medium.center" mb={4}>
          Schedule Events from Excel <Anchor fontSize={2} href="/event-upload-template.xlsx">(download template)</Anchor>
        </Heading>
        {!uploadResponse ? (
          <>
            <ScheduleEventsForm
              dropzoneState={dropzoneState}
              resetForm={resetForm}
              onUploadSubmit={onUploadSubmit}
              workbook={workbook}
              workbookValid={workbookValid}
              rejectedFilesLocal={rejectedFilesLocal}
              isFileTooLarge={isFileTooLarge}
              loading={loading}
              eventData={eventData}
            />
            {!!eventData.length && (
              <ScheduleEventsValidationResults eventData={eventData} />
            )}
          </>
        ) : (
          <ScheduleEventsUploadResults
            resetForm={resetForm}
            uploadResponse={uploadResponse}
          />
        )}
        {!eventData.length && (
          <Box>
            <Link to="dashboard">
              <Button type="button" mr={2}>
                Dashboard
              </Button>
            </Link>
            <Link to="calendar">
              <Button variant="secondary">View Calendar</Button>
            </Link>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ScheduleEventsExcel;
