/**
 * * Imports
 */

// * Imports: Hooks
import useAuthFromUrl from "@/hooks/useAuthFromUrl";

export default (props: Router.RouteComponentProps<{ id: string }>) => {
  const { user, loginError } = useAuthFromUrl();

  return {
    user,
    loginError,
  };
};
