import React from "react";
import { Heading, Box, Text, Flex, Button, Image } from "rebass";
import FormRow from "@/ui/common/form-elements/form-row/FormRow.common";
import Select from "@/ui/common/form-elements/select/Select.common.new";
import Input from "@/ui/common/form-elements/input/Input.common";
import TextArea from "@/ui/common/form-elements/text-area/TextArea.common";
import { dropzoneSx } from "@/styles/shared/dropzone.styles";
import InputRange from "react-input-range";
import Modal from "react-modal";
import Swiper from "swiper";

import hooks from "./EventRecapForm.hooks";
import Loader from "@/ui/common/loader/Loader.common";
import { format } from "date-fns";
import { TIMEZONE_NAMES, TTimezones } from "@/constants/events.constants";

import "swiper/css/swiper.min.css";
import "react-input-range/lib/css/index.css";
import { USER_ROLES } from "@/stores/auth/auth.constants";
import ReactTooltip from "react-tooltip";

const INPUT_CONTAINER_PROPS = {
  mb: [4, 0],
  flex: 1,
  px: 2,
};

export type TEventRecapFormProps = Router.RouteComponentProps<{
  id: string;
}> & { user: AuthUser };

type TEventRecapForm = React.FC<TEventRecapFormProps>;

let picturesSwiper: Swiper;
let receiptsSwiper: Swiper;

Modal.setAppElement("#root");

const EventRecapForm: TEventRecapForm = (props) => {
  const {
    ambassadorOptions,
    formik,
    event,
    dropzonePicturesState,
    dropzonePictures,
    dropzoneReceiptsState,
    dropzoneReceipts,
    isSubmitReady,
    onPictureRemoveClick,
    onReceiptRemoveClick,
    onTryAgainPicturesClick,
    onTryAgainReceiptsClick,
    onApproveClick,
    onUnapproveClick,
    onResetFormClick,
    existingPictures,
    existingReceipts,
    eventRecap,
    loading,
    allFieldsDisabled,
  } = hooks(props);

  const [modalOpen, setModalOpen] = React.useState<"receipts" | "pictures">();
  const [swiperPicturesEl, setSwiperPicturesEl] = React.useState<HTMLElement>();
  const [swiperReceiptsEl, setSwiperReceiptsEl] = React.useState<HTMLElement>();

  React.useEffect(() => {
    if (swiperPicturesEl && existingPictures) {
      if (!picturesSwiper) {
        picturesSwiper = new Swiper(swiperPicturesEl, {
          slidesPerView: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      } else {
        picturesSwiper.destroy(true, true);
        picturesSwiper = new Swiper(swiperPicturesEl, {
          slidesPerView: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }
    }
    return () => {
      if (picturesSwiper) {
        picturesSwiper.destroy(true, true);
      }
    };
  }, [swiperPicturesEl, existingPictures]);

  React.useEffect(() => {
    if (swiperReceiptsEl && existingReceipts) {
      if (!receiptsSwiper) {
        receiptsSwiper = new Swiper(swiperReceiptsEl, {
          slidesPerView: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      } else {
        receiptsSwiper.destroy(true, true);
        receiptsSwiper = new Swiper(swiperReceiptsEl, {
          slidesPerView: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }
    }
    return () => {
      if (receiptsSwiper) {
        receiptsSwiper.destroy(true, true);
      }
    };
  }, [swiperReceiptsEl, existingReceipts]);

  const openModal = React.useCallback(
    (which: "receipts" | "pictures" | undefined) => () => {
      setModalOpen(which);
    },
    []
  );

  const closeModal = React.useCallback(() => {
    setModalOpen(undefined);
  }, []);

  if (loading || !event || !eventRecap || !props.user) {
    return <Loader className="la-3x" m="0 auto" />;
  }

  if (
    props.user?.claims.role === USER_ROLES.eventCreator &&
    !eventRecap.approved
  ) {
    return <Heading variant="small">Event Recap is pending approval.</Heading>;
  }

  return (
    <>
      {existingPictures && (
        <Modal
          style={{ overlay: { zIndex: 21 } }}
          isOpen={modalOpen === "pictures"}
          contentLabel="Example Modal"
          onRequestClose={closeModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          contentRef={(ref) =>
            ref
              ? setSwiperPicturesEl(
                  ref.querySelector(".swiper-container") as HTMLElement
                )
              : undefined
          }
        >
          <Button
            type="button"
            variant="blank"
            fontSize={48}
            onClick={closeModal}
            sx={{ position: "absolute", top: "4px", right: "24px", zIndex: 2 }}
          >
            &times;
          </Button>
          <Heading variant="medium">Event Photos</Heading>
          <Box
            className="swiper-container"
            maxHeight="calc(100vh - 120px)"
            height="100%"
            overflow="scroll"
          >
            <Flex className="swiper-wrapper" alignItems="center">
              {existingPictures.map((src) => {
                return (
                  <Flex
                    className="swiper-slide"
                    justifyContent="center"
                    alignItems="center"
                    key={src}
                  >
                    <Image
                      display="block"
                      src={src}
                      width="auto"
                      maxWidth="100%"
                      height="auto"
                    />
                  </Flex>
                );
              })}
            </Flex>
            <Button
              type="button"
              variant="blank"
              className="swiper-button-next"
            ></Button>
            <Button
              type="button"
              variant="blank"
              className="swiper-button-prev"
            ></Button>
          </Box>
        </Modal>
      )}
      {existingReceipts && (
        <Modal
          style={{ overlay: { zIndex: 21 } }}
          isOpen={modalOpen === "receipts"}
          contentLabel="Example Modal"
          onRequestClose={closeModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          contentRef={(ref) =>
            ref
              ? setSwiperReceiptsEl(
                  ref.querySelector(".swiper-container") as HTMLElement
                )
              : undefined
          }
        >
          <Button
            type="button"
            variant="blank"
            fontSize={48}
            onClick={closeModal}
            sx={{ position: "absolute", top: "4px", right: "24px", zIndex: 2 }}
          >
            &times;
          </Button>
          <Heading variant="medium">Event Receipts</Heading>
          <Box
            className="swiper-container"
            maxHeight="calc(100vh - 120px)"
            height="100%"
            overflow="scroll"
          >
            <Flex className="swiper-wrapper" alignItems="center">
              {existingReceipts.map((src) => {
                return (
                  <Flex
                    className="swiper-slide"
                    justifyContent="center"
                    alignItems="center"
                    key={src}
                  >
                    <Image
                      display="block"
                      src={src}
                      width="auto"
                      maxWidth="100%"
                      height="auto"
                    />
                  </Flex>
                );
              })}
            </Flex>
            <Button
              type="button"
              variant="blank"
              className="swiper-button-next"
            ></Button>
            <Button
              type="button"
              variant="blank"
              className="swiper-button-prev"
            ></Button>
          </Box>
        </Modal>
      )}
      <Flex justifyContent="space-between" flexWrap="wrap" mb={4}>
        <Heading onClick={openModal("pictures")}>
          Event Recap{" "}
          {eventRecap.submitted === true &&
            `(${eventRecap?.approved ? "Approved" : "Pending Approval"})`}
        </Heading>
        {props.user.claims.role === USER_ROLES.admin && eventRecap.submitted && (
          <Box
            data-tip="Cannot Approve when form has pending changes. Submit changes, reset form, or refresh page to approve."
            data-tip-disable={!formik.dirty}
          >
            {!eventRecap.approved ? (
              <Button
                type="button"
                disabled={formik.dirty}
                variant={formik.dirty ? "primary.disabled" : "primary"}
                onClick={onApproveClick}
              >
                Approve
              </Button>
            ) : (
              <Button
                type="button"
                variant="primary"
                onClick={onUnapproveClick}
              >
                Unapprove
              </Button>
            )}
            <ReactTooltip />
          </Box>
        )}
      </Flex>
      <Box>
        <Heading variant="xsmall" mb={1}>
          {event.accountName}, {event.brand}{" "}
        </Heading>
        <Text>
          {format(event.eventStartTime.toDate(), "E, M/dd h:mm a")} -{" "}
          {format(event.eventEndTime.toDate(), "h:mm a")}
          <br />
          {TIMEZONE_NAMES[event.timezone as TTimezones]}
          {" Time"}
        </Text>
      </Box>
      <Box height="1px" width="100%" backgroundColor="lightGray" my={4} />
      <Box as="form" onSubmit={formik.handleSubmit as any}>
        <FormRow>
          <Select
            containerProps={INPUT_CONTAINER_PROPS}
            isMulti={true}
            name="brandAmbassadorsWhoWorked"
            label="Brand Ambassadors who worked"
            externalLabel
            required
            disabled={allFieldsDisabled}
            value={formik.values.brandAmbassadorsWhoWorked}
            onChange={(val) =>
              formik.setFieldValue("brandAmbassadorsWhoWorked", val)
            }
            onBlur={formik.handleBlur}
            options={ambassadorOptions}
            error={
              formik.touched.brandAmbassadorsWhoWorked &&
              formik.errors.brandAmbassadorsWhoWorked
                ? (formik.errors.brandAmbassadorsWhoWorked as string)
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="numberOfConsumersEntered"
            label="How many consumers entered the account?"
            externalLabel
            placeholder="0"
            required
            disabled={allFieldsDisabled}
            value={formik.values.numberOfConsumersEntered}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.numberOfConsumersEntered &&
              formik.errors.numberOfConsumersEntered
                ? formik.errors.numberOfConsumersEntered
                : undefined
            }
          />
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="numberOfConsumersSampled"
            label="How many consumers sampled the products?"
            externalLabel
            placeholder="0"
            required
            disabled={allFieldsDisabled}
            value={formik.values.numberOfConsumersSampled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.numberOfConsumersSampled &&
              formik.errors.numberOfConsumersSampled
                ? formik.errors.numberOfConsumersSampled
                : undefined
            }
          />
        </FormRow>
        <FormRow flexDirection="column">
          <Input
            containerProps={{ ...INPUT_CONTAINER_PROPS, mb: 1 }}
            name="numberOfImpressions"
            label="How many impressions?"
            externalLabel
            placeholder="0"
            required
            disabled={allFieldsDisabled}
            value={formik.values.numberOfImpressions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.numberOfImpressions &&
              formik.errors.numberOfImpressions
                ? formik.errors.numberOfImpressions
                : undefined
            }
          />
          <Text px={8} mb={[4, 0]} sx={{ fontStyle: "italic" }}>
            (# of consumers that sampled and or just walked by and saw
            activation, total number of all this # will be higher than consumers
            sampled)
          </Text>
        </FormRow>
        {event!.premise && event!.premise.toLowerCase() === "off" && (
          <>
            <FormRow>
              <Input
                containerProps={INPUT_CONTAINER_PROPS}
                name="number24PksPurchased"
                label="How many 24 pks were purchased during event?"
                externalLabel
                placeholder="0"
                required
                disabled={allFieldsDisabled}
                value={formik.values.number24PksPurchased}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number24PksPurchased &&
                  formik.errors.number24PksPurchased
                    ? formik.errors.number24PksPurchased
                    : undefined
                }
              />
              <Input
                containerProps={INPUT_CONTAINER_PROPS}
                name="number12PksPurchased"
                label="How many 12 pks were purchased during event?"
                externalLabel
                placeholder="0"
                required
                disabled={allFieldsDisabled}
                value={formik.values.number12PksPurchased}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number12PksPurchased &&
                  formik.errors.number12PksPurchased
                    ? formik.errors.number12PksPurchased
                    : undefined
                }
              />
            </FormRow>
            <FormRow>
              <Input
                containerProps={INPUT_CONTAINER_PROPS}
                name="number6PksPurchased"
                label="How many 6 pks were purchased during event?"
                externalLabel
                placeholder="0"
                required
                disabled={allFieldsDisabled}
                value={formik.values.number6PksPurchased}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number6PksPurchased &&
                  formik.errors.number6PksPurchased
                    ? formik.errors.number6PksPurchased
                    : undefined
                }
              />
              <Input
                containerProps={INPUT_CONTAINER_PROPS}
                name="numberSinglesPurchased"
                label="How many singles were purchased during event?"
                externalLabel
                placeholder="0"
                required
                disabled={allFieldsDisabled}
                value={formik.values.numberSinglesPurchased}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.numberSinglesPurchased &&
                  formik.errors.numberSinglesPurchased
                    ? formik.errors.numberSinglesPurchased
                    : undefined
                }
              />
            </FormRow>
          </>
        )}
        {event!.premise && event!.premise.toLowerCase() === "on" && (
          <>
            <FormRow>
              <Input
                containerProps={INPUT_CONTAINER_PROPS}
                name="numberBottlesDraftsPurchased"
                label="How many bottles/drafts were purchased during the event?"
                externalLabel
                placeholder="0"
                required
                disabled={allFieldsDisabled}
                value={formik.values.numberBottlesDraftsPurchased}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.numberBottlesDraftsPurchased &&
                  formik.errors.numberBottlesDraftsPurchased
                    ? formik.errors.numberBottlesDraftsPurchased
                    : undefined
                }
              />
            </FormRow>
          </>
        )}
        <FormRow>
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="accountSpendWithTip"
            label="What was the account spend WITH tip? ($)"
            externalLabel
            placeholder="0"
            required
            disabled={allFieldsDisabled}
            value={formik.values.accountSpendWithTip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.accountSpendWithTip &&
              formik.errors.accountSpendWithTip
                ? formik.errors.accountSpendWithTip
                : undefined
            }
          />
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="accountSpendWithoutTip"
            label="What was the account spend WITHOUT tip? ($)"
            externalLabel
            placeholder="0"
            required
            disabled={allFieldsDisabled}
            value={formik.values.accountSpendWithoutTip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.accountSpendWithoutTip &&
              formik.errors.accountSpendWithoutTip
                ? formik.errors.accountSpendWithoutTip
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          {/* <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="ageRangeOfConsumers"
            label="What was the age range of consumers?"
            externalLabel
            placeholder=""
            required
            value={formik.values.ageRangeOfConsumers}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ageRangeOfConsumers &&
              formik.errors.ageRangeOfConsumers
                ? formik.errors.ageRangeOfConsumers
                : undefined
            }
          />
          <Input
            containerProps={INPUT_CONTAINER_PROPS}
            name="maleToFemaleRatio"
            label="Male to Female Ratio?"
            externalLabel
            placeholder="e.g. 2 to 1"
            required
            value={formik.values.numberOfImpressions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.numberOfImpressions &&
              formik.errors.numberOfImpressions
                ? formik.errors.numberOfImpressions
                : undefined
            }
          /> */}
          <Box
            flex="1"
            maxWidth="500px"
            mb={50}
            mt={30}
            pl={8}
            sx={{
              "*": {
                transitionDuration: "100ms",
              },
              ".input-range__slider-container": {
                ".input-range__label-container": {
                  left: "-100%",
                },
                "&:last-of-type": {
                  ".input-range__label-container": {
                    left: 0,
                  },
                },
              },
            }}
          >
            <Text variant="sectionHeading" mb={4}>
              What was the age range of consumers? &nbsp;
              <Text as="span" fontWeight="700" fontSize={14}>
                {formik.values.ageRangeOfConsumers &&
                  formik.values.ageRangeOfConsumers.min}{" "}
                -{" "}
                {formik.values.ageRangeOfConsumers &&
                  formik.values.ageRangeOfConsumers.max}
              </Text>
            </Text>
            <InputRange
              name="ageRangeOfConsumers"
              minValue={18}
              maxValue={100}
              step={1}
              disabled={allFieldsDisabled}
              allowSameValues={false}
              value={formik.values.ageRangeOfConsumers}
              onChange={(val) => {
                formik.setFieldValue("ageRangeOfConsumers", val);
              }}
            />
            {formik.touched.accountFeedback && formik.errors.accountFeedback && (
              <Text my={2} color="danger">
                {formik.errors.accountFeedback}
              </Text>
            )}
          </Box>
        </FormRow>
        <FormRow flexDirection="column">
          <TextArea
            containerProps={{ ...INPUT_CONTAINER_PROPS, mb: 1 }}
            name="accountFeedback"
            label="Account Feedback"
            externalLabel
            required
            disabled={allFieldsDisabled}
            value={formik.values.accountFeedback}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.accountFeedback && formik.errors.accountFeedback
                ? formik.errors.accountFeedback
                : undefined
            }
          />
          <Text px={8} mb={[4, 0]} sx={{ fontStyle: "italic" }}>
            (Please leave any feedback from the staff, manager, or owner from
            the account you were working at)
          </Text>
        </FormRow>
        <FormRow flexDirection="column">
          <TextArea
            containerProps={INPUT_CONTAINER_PROPS}
            name="brandAmbassadorFeedback"
            label="Brand Ambassador Feedback"
            externalLabel
            required
            disabled={allFieldsDisabled}
            value={formik.values.brandAmbassadorFeedback}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.brandAmbassadorFeedback &&
              formik.errors.brandAmbassadorFeedback
                ? formik.errors.brandAmbassadorFeedback
                : undefined
            }
          />
        </FormRow>
        {existingPictures && (
          <>
            <Heading
              variant="small"
              mb={3}
              sx={{ borderBottom: "1px solid #e5e5e5", pb: "8px" }}
            >
              Event Photos
            </Heading>
            <Flex mb={5} alignItems="flex-start" flexWrap="wrap">
              {existingPictures.map((src) => {
                return (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    key={src}
                    width={300}
                    height={300}
                    onClick={openModal("pictures")}
                    sx={{ cursor: "pointer" }}
                  >
                    <Image
                      display="block"
                      src={src}
                      width="100%"
                      height="100%"
                      sx={{
                        objectFit: "cover",
                        transition: "opacity 150ms ease-in",
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </>
        )}
        {existingReceipts && (
          <>
            <Heading
              variant="small"
              mb={3}
              sx={{ borderBottom: "1px solid #e5e5e5", pb: "8px" }}
            >
              Event Receipts
            </Heading>
            <Flex mb={5} alignItems="flex-start" flexWrap="wrap">
              {existingReceipts.map((src) => {
                return (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    key={src}
                    width={300}
                    height={300}
                    onClick={openModal("receipts")}
                    sx={{ cursor: "pointer" }}
                  >
                    <Image
                      display="block"
                      src={src}
                      width="100%"
                      height="100%"
                      sx={{
                        objectFit: "cover",
                        transition: "opacity 150ms ease-in",
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </>
        )}
        {!allFieldsDisabled && (
          <>
            <Heading mb={1} variant="sectionHeading">
              Upload Event Photos
            </Heading>
            <FormRow>
              <Flex
                {...dropzonePictures.getRootProps()}
                mb={4}
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={dropzoneSx}
              >
                <input {...dropzonePictures.getInputProps()} />
                {!dropzonePictures.isDragActive &&
                  !dropzonePicturesState?.rejectedFilesLocal &&
                  (!dropzonePicturesState?.acceptedFiles ||
                    (dropzonePicturesState?.acceptedFiles &&
                      !dropzonePicturesState?.acceptedFiles?.length)) && (
                    <Box>
                      <Text>Click here or drop a file to upload!</Text>
                    </Box>
                  )}
                {dropzonePictures.isDragActive &&
                  !dropzonePicturesState?.rejectedFilesLocal && (
                    <Box>
                      <Text>Drop it like it's hot!</Text>
                    </Box>
                  )}
                {dropzonePicturesState?.rejectedFilesLocal && (
                  <Box>
                    <Text color="danger" mb={2}>
                      Sorry, file type not accepted, please upload image files
                      only.
                    </Text>
                    <Button type="button" onClick={onTryAgainPicturesClick}>
                      Try Again
                    </Button>
                  </Box>
                )}
                {dropzonePicturesState?.isFileTooLarge && (
                  <Box>
                    <Text color="danger" mb={2}>
                      File is too large.
                    </Text>
                    <Button type="button" onClick={onTryAgainPicturesClick}>
                      Try Again
                    </Button>
                  </Box>
                )}
                {dropzonePicturesState?.acceptedFiles && (
                  <Flex>
                    {dropzonePicturesState.acceptedFiles.map((fil) => {
                      return (
                        <Box
                          key={fil.name}
                          width="75px"
                          height="75px"
                          sx={{
                            position: "relative",
                            border: "1px solid #ddd",
                          }}
                        >
                          <Image
                            key={fil.name}
                            src={window.URL.createObjectURL(fil)}
                            maxWidth="100%"
                          />
                          <Button
                            type="button"
                            variant="blank"
                            width="20px"
                            height="20px"
                            onClick={onPictureRemoveClick(fil.name)}
                            sx={{
                              borderRadius: "50%",
                              position: "absolute",
                              backgroundColor: "white",
                              top: "2px",
                              right: "2px",
                              "& > span": {
                                position: "relative",
                                top: "-1px",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <span>&times;</span>
                          </Button>
                        </Box>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </FormRow>
            <Heading mb={1} variant="sectionHeading">
              Upload Event Receipt(s)
            </Heading>
            <FormRow>
              <Flex
                {...dropzoneReceipts.getRootProps()}
                mb={4}
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={dropzoneSx}
              >
                <input {...dropzoneReceipts.getInputProps()} />
                {!dropzoneReceipts.isDragActive &&
                  !dropzoneReceiptsState?.rejectedFilesLocal &&
                  (!dropzoneReceiptsState?.acceptedFiles ||
                    (dropzoneReceiptsState?.acceptedFiles &&
                      !dropzoneReceiptsState?.acceptedFiles?.length)) && (
                    <Box>
                      <Text>Click here or drop a file to upload!</Text>
                    </Box>
                  )}
                {dropzoneReceipts.isDragActive &&
                  !dropzoneReceiptsState?.rejectedFilesLocal && (
                    <Box>
                      <Text>Drop it like it's hot!</Text>
                    </Box>
                  )}
                {dropzoneReceiptsState?.rejectedFilesLocal && (
                  <Box>
                    <Text color="danger" mb={2}>
                      Sorry, file type not accepted, please upload image files
                      only.
                    </Text>
                    <Button type="button" onClick={onTryAgainReceiptsClick}>
                      Try Again
                    </Button>
                  </Box>
                )}
                {dropzoneReceiptsState?.isFileTooLarge && (
                  <Box>
                    <Text color="danger" mb={2}>
                      File is too large.
                    </Text>
                    <Button type="button" onClick={onTryAgainReceiptsClick}>
                      Try Again
                    </Button>
                  </Box>
                )}
                {dropzoneReceiptsState?.acceptedFiles && (
                  <Flex>
                    {dropzoneReceiptsState.acceptedFiles.map((fil) => {
                      return (
                        <Box
                          key={fil.name}
                          width="75px"
                          height="75px"
                          sx={{
                            position: "relative",
                            border: "1px solid #ddd",
                          }}
                        >
                          <Image
                            key={fil.name}
                            src={window.URL.createObjectURL(fil)}
                            maxWidth="100%"
                          />
                          <Button
                            type="button"
                            variant="blank"
                            width="20px"
                            height="20px"
                            onClick={onReceiptRemoveClick(fil.name)}
                            sx={{
                              borderRadius: "50%",
                              position: "absolute",
                              backgroundColor: "white",
                              top: "2px",
                              right: "2px",
                              "& > span": {
                                position: "relative",
                                top: "-1px",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <span>&times;</span>
                          </Button>
                        </Box>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </FormRow>
          </>
        )}
        <Flex
          sx={{
            button: {
              marginX: 1,
              "&:first-of-type": {
                marginLeft: "0px",
              },
              "&:last-of-type": {
                marginRight: "0px",
              },
            },
          }}
        >
          {isSubmitReady && (
            <>
              <Button
                type="submit"
                disabled={loading}
                variant={loading ? "primary.disabled" : "primary"}
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <Loader height="15px" width="15px" m="0 auto" />
                )}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={onResetFormClick}
              >
                Reset Form
              </Button>
            </>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default EventRecapForm;
