/** Lib */
import React from "react";

/** UI */
import { Flex, BoxProps, Box } from "rebass";
import Radio from "@/ui/common/form-elements/radio/Radio.common";
import Input from "@common/form-elements/input/Input.common";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";
import { IShowAmbassadorFilterState } from "@/ui/screens/dashboard/ambassador-dashboard/AmbassadorDashboard.hooks";

/** Styles */
const CHECKBOX_CONTAINER_PROPS: BoxProps = {
  sx: {
    cursor: "pointer",
    ml: 0,
    my: 0,
    mr: 0,
    "&:first-of-type": {
      ml: 0
    },
    "&:last-of-type": {
      mr: 0
    }
  }
};

export interface IDashboardFiltersProps {
  onSearchInputChange: (ev: React.ChangeEvent<any>) => void;
  searchTerm: string;
  onFilterCheckboxChange: (
    filterType: keyof IShowAmbassadorFilterState
  ) => (ev: React.ChangeEvent<HTMLInputElement>) => void;
  showFilterState: IShowAmbassadorFilterState;
  user: TUserFirestore;
}

const AmbassadorDashboardFilters: React.NamedExoticComponent<IDashboardFiltersProps> = React.memo(
  props => {
    const {
      onFilterCheckboxChange,
      showFilterState,
      onSearchInputChange,
      searchTerm
    } = props;

    return (
      <>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          flexWrap="wrap"
        >
          <Box
            width={[1, 1 / 2]}
            mb={[3, 0]}
            sx={{
              display: "grid",
              // gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
              gridTemplateColumns: [
                "min-content min-content",
                "min-content min-content",
                "min-content min-content min-content",
                "min-content min-content min-content min-content",
                "min-content min-content min-content min-content min-content"
              ],
              gridAutoRows: "20px",
              gridColumnGap: "20px",
              gridRowGap: "10px"
            }}
          >
            <Radio
              containerProps={CHECKBOX_CONTAINER_PROPS}
              name="invited"
              id="invited"
              label="Invited"
              onChange={onFilterCheckboxChange("invited")}
              checked={showFilterState.invited}
            />
            <Radio
              containerProps={CHECKBOX_CONTAINER_PROPS}
              name="accepted"
              id="accepted"
              label="Accepted"
              onChange={onFilterCheckboxChange("accepted")}
              checked={showFilterState.accepted}
            />
            <Radio
              containerProps={CHECKBOX_CONTAINER_PROPS}
              name="confirmed"
              id="confirmed"
              label="Confirmed"
              onChange={onFilterCheckboxChange("confirmed")}
              checked={showFilterState.confirmed}
            />
          </Box>
          <Flex
            width={[1, 1 / 4]}
            justifyContent={["flex-start", "flex-end"]}
            mb={[3, 0]}
          >
            <Input
              name="dashboard-search"
              label="Event Search"
              onChange={onSearchInputChange}
              value={searchTerm}
              minWidth="265px"
            />
          </Flex>
        </Flex>
      </>
    );
  }
);

export default AmbassadorDashboardFilters;
