/** Store dependencies */
import * as actionCreators from "./business.actions";
import businessReducer from "./business.reducer";
import createStore from "@util/createStore";

/** Types */
import {
  TBusinessAction,
  IBusinessState,
  IBusinessActions
} from "./business.types";

export const BUSINESS_INITIAL_STATE = {
  business: [],
  subscribedToAll: false
};

const { StoreContext, StoreContextProvider, useStore } = createStore<
  IBusinessState,
  TBusinessAction<any>,
  IBusinessActions
>(BUSINESS_INITIAL_STATE, businessReducer, actionCreators);

export {
  StoreContext as BusinessContext,
  StoreContextProvider as BusinessContextProvider,
  useStore
};
