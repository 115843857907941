/** Lib */
import React from "react";
import ReactTooltip from "react-tooltip";

/** UI */
import { Text, Box, Button, Flex } from "rebass";
import MenuSection from "./MenuSection";
import { TUserFirestore } from "@typings/users/users.types";

/** Styles */
import {
  avatarContainerStyles,
  avatarButtonStyles,
  avatarSpanStyles,
  avatarImageStyles
} from "./UserAvatar.styles";
import { Link } from "react-router-dom";

const UserAvatar: React.FC<{
  user: TUserFirestore;
  onLogoutClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
}> = props => {
  return (
    <Box className="avatar-container" sx={avatarContainerStyles}>
      <ReactTooltip
        id="avatar-menu"
        effect="solid"
        clickable={true}
        place={"bottom"}
        border={true}
        type={"light"}
        globalEventOff="click"
      >
        <Flex>
          <Box>
            <MenuSection>
              <Text>Signed in as </Text>
              <Text fontWeight="700">
                {props.user.firstName} {props.user.lastName}
              </Text>
            </MenuSection>
            <MenuSection>
              <Flex>
                <Link to="/profile">Your Profile</Link>
              </Flex>
            </MenuSection>
            <MenuSection>
              <Flex justifyContent="center">
                <Button type="button" onClick={props.onLogoutClick}>
                  Logout
                </Button>
              </Flex>
            </MenuSection>
          </Box>
        </Flex>
      </ReactTooltip>
      <Box
        type="button"
        as="button"
        data-tip="Test"
        data-for="avatar-menu"
        data-event="click"
        className="avatar"
        sx={avatarButtonStyles}
      >
        <Box as="span" sx={avatarSpanStyles}>
          <Box
            as="img"
            src={props.user.avatar as string}
            sx={avatarImageStyles}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserAvatar;
