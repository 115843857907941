import { SxStyleProp } from "rebass";

export const dropzoneSx: SxStyleProp = {
  borderStyle: "dashed",
  borderWidth: "2px",
  borderColor: "lightGray",
  minHeight: "150px",
  bg: "white",
  cursor: "pointer",
  transition: "border 150ms ease-in-out",
  "&:hover, &:focus": {
    borderColor: "primary"
  },
  "&:focus": {
    outline: "none"
  }
};
