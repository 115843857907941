/**
 * * Imports
 */

//* Imports: Lib
import React from "react";
import * as firebase from "firebase/app";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as yup from "yup";

// * Imports: Hooks
import useSubscribeToSingleEvent from "@/hooks/useSubscribeToSingleEvent";
import useMergeState from "@/hooks/useMergeState";

// * Imports: Services
import UsersService from "@/services/users/users.service";

// * Imports: Types
import { TUserFirestore } from "@/typings/users/users.types";
import useSubscribeToSingleEventRecap from "@/hooks/useSubscribeToSingleEventRecap";
import eventsService from "@/services/events/events.service";
import { toast } from "react-toastify";
import { TEventRecapFormProps } from "./EventRecapForm.component";
import { USER_ROLES } from "@/stores/auth/auth.constants";
/**
 * set local state for rejected files
 * when file drop fails.
 */
const onDropRejected = (
  setMergeState: React.Dispatch<
    React.SetStateAction<
      Partial<{
        rejectedFilesLocal: File[];
        isFileTooLarge: boolean;
      }>
    >
  >,
  currentRejectedFilesLocal: File[] | undefined
) => (rejectedFilesLocal: File[] | undefined) => {
  rejectedFilesLocal &&
    setMergeState(
      currentRejectedFilesLocal
        ? {
            rejectedFilesLocal: [
              ...currentRejectedFilesLocal,
              ...rejectedFilesLocal,
            ],
          }
        : { rejectedFilesLocal }
    );
};

const initalFormValues = {
  brandAmbassadorsWhoWorked: [] as Array<{ label: string; value: string }>,
  numberOfConsumersEntered: undefined,
  numberOfConsumersSampled: undefined,
  numberOfImpressions: undefined,
  number24PksPurchased: undefined,
  number12PksPurchased: undefined,
  number6PksPurchased: undefined,
  numberSinglesPurchased: undefined,
  numberBottlesDraftsPurchased: undefined,
  accountSpendWithTip: undefined,
  accountSpendWithoutTip: undefined,
  ageRangeOfConsumers: { min: 0, max: 0 },
  accountFeedback: undefined,
  brandAmbassadorFeedback: undefined,
};

export default (props: TEventRecapFormProps) => {
  const eventId = props.match.params.id;
  const { user } = props;

  const event = useSubscribeToSingleEvent(eventId);
  const eventRecap = useSubscribeToSingleEventRecap(eventId);

  const [loading, setLoading] = React.useState(false);
  const [ambassadors, setAmbassadors] = React.useState<Array<TUserFirestore>>(
    []
  );
  const [existingPictures, setExistingPictures] = React.useState<string[]>();
  const [existingReceipts, setExistingReceipts] = React.useState<string[]>();

  const [dropzonePicturesState, setDropzoneStatePictures] = useMergeState<
    Partial<{
      rejectedFilesLocal: File[];
      isFileTooLarge: boolean;
      acceptedFiles: File[];
    }>
  >({});
  const [dropzoneReceiptsState, setDropzoneStateReceipts] = useMergeState<
    Partial<{
      rejectedFilesLocal: File[];
      isFileTooLarge: boolean;
      acceptedFiles: File[];
    }>
  >({});

  const onDropRejectedPictures = React.useMemo(
    () =>
      onDropRejected(
        setDropzoneStatePictures,
        dropzonePicturesState?.rejectedFilesLocal
      ),
    [dropzonePicturesState, setDropzoneStatePictures]
  );

  const onDropRejectedReceipts = React.useMemo(
    () =>
      onDropRejected(
        setDropzoneStateReceipts,
        dropzoneReceiptsState?.rejectedFilesLocal
      ),
    [dropzoneReceiptsState, setDropzoneStateReceipts]
  );

  const onDropPictures = React.useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        if (dropzonePicturesState?.acceptedFiles) {
          return setDropzoneStatePictures({
            acceptedFiles: [
              ...dropzonePicturesState?.acceptedFiles,
              ...acceptedFiles,
            ],
          });
        }
        return setDropzoneStatePictures({ acceptedFiles });
      }
    },
    [dropzonePicturesState, setDropzoneStatePictures]
  );

  const onDropReceipts = React.useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        if (dropzoneReceiptsState?.acceptedFiles) {
          return setDropzoneStateReceipts({
            acceptedFiles: [
              ...dropzoneReceiptsState?.acceptedFiles,
              ...acceptedFiles,
            ],
          });
        }
        return setDropzoneStateReceipts({ acceptedFiles });
      }
    },
    [dropzoneReceiptsState, setDropzoneStateReceipts]
  );

  const onPictureRemoveClick = React.useCallback(
    (fileName: string) => (ev: React.MouseEvent) => {
      ev.stopPropagation();
      const newFilesFiltered = dropzonePicturesState?.acceptedFiles?.filter(
        (fil) => fil.name !== fileName
      );

      setDropzoneStatePictures({ acceptedFiles: newFilesFiltered });
    },
    [dropzonePicturesState, setDropzoneStatePictures]
  );

  const onReceiptRemoveClick = React.useCallback(
    (fileName: string) => (ev: React.MouseEvent) => {
      ev.stopPropagation();
      const newFilesFiltered = dropzoneReceiptsState?.acceptedFiles?.filter(
        (fil) => fil.name !== fileName
      );

      setDropzoneStateReceipts({ acceptedFiles: newFilesFiltered });
    },
    [dropzoneReceiptsState, setDropzoneStateReceipts]
  );

  const dropzonePictures = useDropzone({
    onDrop: onDropPictures,
    onDropRejected: onDropRejectedPictures,
    accept: ["image/jpg", "image/jpeg", "image/png"],
    minSize: 0,
    maxSize: 20000000,
  });

  const dropzoneReceipts = useDropzone({
    onDrop: onDropReceipts,
    onDropRejected: onDropRejectedReceipts,
    accept: ["image/jpg", "image/jpeg", "image/png"],
    minSize: 0,
    maxSize: 20000000,
  });

  const onTryAgainPicturesClick = React.useCallback(
    (ev) => {
      ev.stopPropagation();
      setDropzoneStatePictures({ rejectedFilesLocal: undefined });
    },
    [setDropzoneStatePictures]
  );

  const onTryAgainReceiptsClick = React.useCallback(
    (ev) => {
      ev.stopPropagation();
      setDropzoneStateReceipts({ rejectedFilesLocal: undefined });
    },
    [setDropzoneStateReceipts]
  );

  const ambassadorOptions = React.useMemo(() => {
    return ambassadors.map((amb) => ({
      value: amb.id,
      label: `${amb.firstName} ${amb.lastName}`,
    }));
  }, [ambassadors]);

  React.useEffect(() => {
    if (!event) {
      setLoading(true);
    }
    if (event && Array.isArray(event.confirmedByAdminUsers)) {
      (async () => {
        try {
          const usersResult = await Promise.all(
            event.confirmedByAdminUsers.map((userId) => {
              return (async () => {
                try {
                  const user = await UsersService.getById(userId);
                  if (user.exists) {
                    return user.data();
                  }
                  return;
                } catch (err) {
                  console.error(err);
                  return;
                }
              })();
            })
          );
          setAmbassadors(
            usersResult.filter((usr) => !!usr) as Array<TUserFirestore>
          );
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      })();
    }
  }, [event]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      brandAmbassadorsWhoWorked: yup.array().required(),
      numberOfConsumersEntered: yup
        .string()
        .matches(/^[1-9]\d*$|^20$/, "number"),
      numberOfConsumersSampled: yup
        .string()
        .matches(/^[1-9]\d*$|^20$/, "number"),
      numberOfImpressions: yup.string().matches(/^[1-9]\d*$|^20$/, "number"),
      number24PksPurchased: yup.string().matches(/^[1-9]\d*$|^20$/, "number"),
      number12PksPurchased: yup.string().matches(/^[1-9]\d*$|^20$/, "number"),
      number6PksPurchased: yup.string().matches(/^[1-9]\d*$|^20$/, "number"),
      numberSinglesPurchased: yup.string().matches(/^[1-9]\d*$|^20$/, "number"),
      numberBottlesDraftsPurchased: yup
        .string()
        .matches(/^[1-9]\d*$|^20$/, "number"),
      accountSpendWithTip: yup
        .string()
        .matches(
          /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
          "Format: 100 or 100.00"
        )
        .required("Required"),
      accountSpendWithoutTip: yup
        .string()
        .matches(
          /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
          "Format: 100 or 100.00"
        )
        .required("Required"),
      ageRangeOfConsumers: yup
        .object()
        .shape({ min: yup.number(), max: yup.number() })
        .required(),
      accountFeedback: yup.string().required("Required"),
      brandAmbassadorFeedback: yup.string().required("Required"),
    }),
    initialValues: !eventRecap
      ? initalFormValues
      : {
          ...eventRecap,
          ageRangeOfConsumers: eventRecap.ageRangeOfConsumers
            ? eventRecap.ageRangeOfConsumers
            : { min: 21, max: 24 },
          brandAmbassadorsWhoWorked:
            eventRecap.brandAmbassadorsWhoWorked && ambassadors
              ? eventRecap.brandAmbassadorsWhoWorked.reduce((ac, id) => {
                  const amb = ambassadors.find((usr) => usr.id === id);
                  return amb
                    ? [
                        ...ac,
                        {
                          label: `${amb.firstName} ${amb.lastName}`,
                          value: amb.id,
                        },
                      ]
                    : ac;
                }, [] as Array<{ label: string; value: string }>)
              : [],
        },
    async onSubmit(values) {
      if (event) {
        // Create a root reference
        var storageRef = firebase.storage().ref();

        if (
          (dropzonePicturesState &&
            dropzonePicturesState.acceptedFiles &&
            dropzoneReceiptsState &&
            dropzoneReceiptsState.acceptedFiles) ||
          (existingReceipts?.length && existingPictures?.length)
        )
          try {
            setLoading(true);

            if (dropzonePicturesState && dropzonePicturesState.acceptedFiles) {
              await Promise.all(
                dropzonePicturesState.acceptedFiles.map(async (fil) => {
                  const imageRef = storageRef.child(
                    `images/event-recaps/${event.id}/photos/${fil.name}`
                  );
                  return await imageRef.put(fil);
                })
              );
            }

            if (dropzoneReceiptsState && dropzoneReceiptsState.acceptedFiles) {
              await Promise.all(
                dropzoneReceiptsState.acceptedFiles.map(async (fil) => {
                  const imageRef = storageRef.child(
                    `images/event-recaps/${event.id}/receipts/${fil.name}`
                  );
                  return await imageRef.put(fil);
                })
              );
            }

            const submitValues = {
              ...values,
              numberOfConsumersEntered: values.numberOfConsumersEntered
                ? Number(values.numberOfConsumersEntered)
                : undefined,
              numberOfConsumersSampled: values.numberOfConsumersSampled
                ? Number(values.numberOfConsumersSampled)
                : undefined,
              numberOfImpressions: values.numberOfImpressions
                ? Number(values.numberOfImpressions)
                : undefined,
              number24PksPurchased: values.number24PksPurchased
                ? Number(values.number24PksPurchased)
                : undefined,
              number12PksPurchased: values.number12PksPurchased
                ? Number(values.number12PksPurchased)
                : undefined,
              number6PksPurchased: values.number6PksPurchased
                ? Number(values.number6PksPurchased)
                : undefined,
              numberSinglesPurchased: values.numberSinglesPurchased
                ? Number(values.numberSinglesPurchased)
                : undefined,
              numberBottlesDraftsPurchased: values.numberBottlesDraftsPurchased
                ? Number(values.numberBottlesDraftsPurchased)
                : undefined,
              accountSpendWithTip: values.accountSpendWithTip
                ? Number(values.accountSpendWithTip)
                : undefined,
              accountSpendWithoutTip: values.accountSpendWithoutTip
                ? Number(values.accountSpendWithoutTip)
                : undefined,
              brandAmbassadorsWhoWorked: values.brandAmbassadorsWhoWorked.map(
                (obj) => obj.value
              ),
              submitted: true,
              approved: false,
              submittedBy: user!.uid,
            };

            const toastMessage = eventRecap?.submitted
              ? "Updated"
              : "Submitted";

            await eventsService.updateEventRecap(eventId, submitValues);

            toast(`Event Recap ${toastMessage}`);
            formik.resetForm();
            const resetPayload = {
              acceptedFiles: undefined,
              rejectedFilesLocal: undefined,
            };
            setDropzoneStatePictures(resetPayload);
            setDropzoneStateReceipts(resetPayload);
            loadStorageAssets();
          } catch (err) {
            console.error("Error submitting recap form.", err);
          }
      }
    },
  });

  const isSubmitReady = React.useMemo(() => {
    if (dropzonePicturesState && dropzoneReceiptsState) {
      const { acceptedFiles: pictureFiles } = dropzonePicturesState;
      const { acceptedFiles: receiptFiles } = dropzoneReceiptsState;

      if (eventRecap && eventRecap.submitted) {
        return !!(formik.dirty || pictureFiles?.length || receiptFiles?.length);
      }

      return !!(formik.dirty && pictureFiles?.length && receiptFiles?.length);
    }
    return false;
  }, [formik, dropzonePicturesState, dropzoneReceiptsState, eventRecap]);

  const onResetFormClick = React.useCallback(
    (ev) => {
      const resetPayload = {
        acceptedFiles: undefined,
        rejectedFilesLocal: undefined,
      };
      setDropzoneStatePictures(resetPayload);
      setDropzoneStateReceipts(resetPayload);
      formik.handleReset(ev);
    },
    [formik, setDropzoneStatePictures, setDropzoneStateReceipts]
  );

  const loadStorageAssets = React.useCallback(async () => {
    var storageRef = firebase.storage().ref();

    const imagePhotosRef = storageRef.child(
      `images/event-recaps/${eventId}/photos`
    );
    const imageReceiptsRef = storageRef.child(
      `images/event-recaps/${eventId}/receipts`
    );

    try {
      setLoading(true);
      const resultPhotos = await imagePhotosRef.list();
      const resultReceipts = await imageReceiptsRef.list();

      if (resultReceipts.items.length) {
        const urls = await Promise.all(
          resultReceipts.items.map((ref) => ref.getDownloadURL())
        );
        setExistingReceipts(urls as string[]);
      }
      if (resultPhotos.items.length) {
        const urls = await Promise.all(
          resultPhotos.items.map((ref) => ref.getDownloadURL())
        );
        setExistingPictures(urls as string[]);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Failed to load existing photos", err);
    }
  }, [eventId]);

  React.useEffect(() => {
    if (eventRecap && eventId) {
      loadStorageAssets();
    }
  }, [eventRecap, eventId, loadStorageAssets]);

  const onApproveClick = React.useCallback(async () => {
    setLoading(true);
    try {
      await eventsService.updateEventRecap(eventId, { approved: true });
      setLoading(false);
    } catch (err) {
      console.error("EventRecapForm:OnApproveClick: ", err);
    }
  }, [eventId]);

  const onUnapproveClick = React.useCallback(async () => {
    setLoading(true);
    try {
      await eventsService.updateEventRecap(eventId, { approved: false });
      setLoading(false);
    } catch (err) {
      console.error("EventRecapForm:OnUnapproveClick: ", err);
    }
  }, [eventId]);

  const allFieldsDisabled = React.useMemo(() => {
    if (!user || !eventRecap || user.claims.role === USER_ROLES.eventCreator || eventRecap.approved) {
      return true;
    }
    return false;
  }, [user, eventRecap]);

  return {
    ambassadors,
    ambassadorOptions,
    formik,
    event,
    dropzonePicturesState,
    dropzonePictures,
    dropzoneReceiptsState,
    dropzoneReceipts,
    isSubmitReady,
    onPictureRemoveClick,
    onReceiptRemoveClick,
    onTryAgainPicturesClick,
    onTryAgainReceiptsClick,
    onApproveClick,
    onUnapproveClick,
    onResetFormClick,
    existingPictures,
    existingReceipts,
    eventRecap,
    allFieldsDisabled,
    loading,
  };
};
