import React from "react";
import { Text } from "rebass";
import { Container } from "@/ui/common/responsive";
import Card from "@/ui/common/card/Card.common";
import EventRecapForm from "@/ui/components/event-recap-form/EventRecapForm.component";

import hooks from "./EventRecapSubmitSms.hooks";
import Loader from "@/ui/common/loader/Loader.common";

const EventRecapSubmitSmsScreen: React.FC<Router.RouteComponentProps<{
  id: string;
}>> = (props) => {
  const { user, loginError } = hooks(props);

  if (loginError) {
    return (
      <Container pt={4}>
        <Card p={4} maxWidth="800px" m="0 auto">
          <Text>
            Login link expired or has already been used. Please login here:{" "}
            <a
              href={`/login?redirectUrl=/event-recap-form/${props.match.params.id}`}
            >
              Login
            </a>
          </Text>
        </Card>
      </Container>
    );
  }

  if (!user) {
    return (
      <Container pt={4}>
        <Card p={4} maxWidth="800px" m="0 auto">
          <Loader className="la-3x" m="0 auto" />
        </Card>
      </Container>
    );
  }

  return (
    <Container pt={4}>
      <Card p={4} maxWidth="800px" m="0 auto">
        <EventRecapForm {...props} user={user} />
      </Card>
    </Container>
  );
};

export default EventRecapSubmitSmsScreen;
