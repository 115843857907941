import React from "react";
import { useStore as useAuthStore } from "@/stores/auth/auth.store";
/** Hooks */
import useSubscribeToSingleEventProtected from "@/hooks/useSubscribeToSingleEventProtected";
import useSubscribeToSingleEvent from "@/hooks/useSubscribeToSingleEvent";
import useSubscribeToSingleEventRecap from "@/hooks/useSubscribeToSingleEventRecap";
import { USER_ROLES } from "@/stores/auth/auth.constants";
import eventsService from "@/services/events/events.service";
import { statuses } from "@/stores/events/events.constants";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export default (props: Router.RouteComponentProps<TParamsWithId>) => {
  const {
    state: { user },
  } = useAuthStore();
  const eventState = useSubscribeToSingleEvent(props.match.params.id);
  const protectedEventState = useSubscribeToSingleEventProtected(
    props.match.params.id
  );
  const eventRecap = useSubscribeToSingleEventRecap(props.match.params.id);

  const isPastEvent = React.useMemo(() => {
    return (
      eventState &&
      new Date(eventState.eventStartTime.seconds * 1000) < new Date()
    );
  }, [eventState]);
  // If user is admin, subscribe protected fields.

  const onCancelEventClick = React.useCallback(() => {
    if (eventState && eventState.status !== statuses.cancelled)
      confirmAlert({
        title: "WARNING: Are you sure you want to CANCEL this event?",
        message: "This action is irreversible.",
        buttons: [
          {
            label: "Yes, CANCEL this event.",
            onClick: async () => {
              try {
                await eventsService.update(eventState.id, {
                  status: statuses.cancelled,
                });
                toast("Event cancelled");
              } catch (err) {
                console.error(
                  "AdminDashboard.hook: onDenyClick: Error Updating Event."
                );
              }
            },
          },
          {
            label: "No, KEEP this event.",
            onClick: () => {},
          },
        ],
      });
  }, [eventState]);

  if (
    [USER_ROLES.admin, USER_ROLES.superAdmin].includes(
      user?.claims.role as AuthUserRoles
    )
  ) {
    return {
      event: eventState,
      eventProtected: protectedEventState,
      isPastEvent,
      user,
      onCancelEventClick,
    };
  }

  return { event: eventState, isPastEvent, user, eventRecap };
};
