export const sideNavStyles = (open: boolean, mobileMenuExpanded: boolean) => ({
  position: "fixed",
  top: 0,
  bottom: 0,
  right: "auto",
  backgroundColor: "#2B303B",
  width: "280px",
  zIndex: 20,
  left: ["-280px", "-280px", "-280px", "-210px"],
  transition: "transform 150ms ease-in-out",
  transform:
    open || mobileMenuExpanded
      ? [
          "translate3d(280px, 0, 0)",
          "translate3d(280px, 0, 0)",
          "translate3d(280px, 0, 0)",
          "translate3d(210px, 0, 0)"
        ]
      : "translate3d(0, 0, 0)"
});

export const sideMenuStyles = {
  alignItems: "flex-end"
};
