/** Lib */
import React from "react";

/** Stores */
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

/** UI */
import { Box, Flex, Image } from "rebass";
import MenuItem from "./menu-item/MenuItem";

/** Hooks */
import hooks from "./SideNavigation.hooks";
import { useLocation } from "react-router-dom"

/** Styles */
import { sideNavStyles, sideMenuStyles } from "./SideNavigation.styles";
import {
  IconHome,
  IconCalendar,
  IconCalendarAdd,
  IconUsers,
  IconBusiness,
  IconAccounts
} from "@common/icon/Icons.common";
import { USER_ROLES } from "@stores/auth/auth.constants";

/** Types */
export type TSideNavigationProps = {
  setMobileMenuExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  mobileMenuExpanded: boolean;
};

const SideNavigation: React.FC<TSideNavigationProps &
  Router.RouteComponentProps> = props => {
  // const { state: authState } = useAuthStore();
  const user = useSubscribeToOwnUser();
  const { open, onMouseMove, onMouseLeave, onMenuItemLinkClick } = hooks(props);

  const role = user?.role;
  const active = user?.active;
  const { pathname } = useLocation();

  return (
    <Box
      className="side-navigation"
      sx={sideNavStyles(open, props.mobileMenuExpanded)}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <Flex
        className="side-navigation-header"
        sx={{
          backgroundColor: "#272b35",
          borderBottom: "1px solid #232730",
          height: "60px",
          marginBottom: "12px",
          justifyContent:
            open || props.mobileMenuExpanded ? "center" : "flex-end",
          alignItems: "center",
          position: "relative"
        }}
      >
        <Image
          src="/mtwo-side-logo.png"
          height="33px"
          width="auto"
          sx={{
            position: "absolute",
            opacity: open || props.mobileMenuExpanded ? 1 : 0
          }}
        />
        <Image
          src="/mtwo-header-logo.png"
          height="30px"
          width="auto"
          sx={{
            position: "absolute",
            right: "18px",
            opacity: open ? 0 : 1,
            display: ["none", "none", "none", "inline"]
          }}
        />
      </Flex>
      <Flex className="side-menu" flexDirection="column" sx={sideMenuStyles}>
        {role && active && (
          <>
            <MenuItem
              to="/dashboard"
              onClick={onMenuItemLinkClick}
              text="Dashboard"
              Icon={IconHome}
              menuOpen={open}
              iconContainerProps={pathname === '/dashboard' ? {
                bg: "primary",
                sx: {
                  svg: {
                    width: "16px",
                    fill: "white"
                  }
                }
              }: {}}
            />
            <MenuItem
              to="/calendar"
              onClick={onMenuItemLinkClick}
              text="Calendar"
              Icon={IconCalendar}
              menuOpen={open}
              iconContainerProps={pathname === '/calendar' ? {
                bg: "primary",
                sx: {
                  svg: {
                    width: "16px",
                    fill: "white"
                  }
                }
              }: {}}
            />

            {role &&
              [
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.eventCreator
              ].includes(role as AuthUserRoles) && (
                <MenuItem
                  to="/"
                  onClick={onMenuItemLinkClick}
                  text="Schedule/Add"
                  Icon={IconCalendarAdd}
                  menuOpen={open}
                  iconContainerProps={pathname.includes('schedule-event') || pathname.includes('add-account') ? {
                    bg: "primary",
                    sx: {
                      svg: {
                        width: "16px",
                        fill: "white"
                      }
                    }
                  }: {}}                  
                  subMenuItems={[
                    {
                      text: "Schedule Event",
                      to: "/schedule-event"
                    },
                    {
                      text: "Schedule Events from Excel",
                      to: "/schedule-events-excel"
                    },
                    {
                      text: "Add Account",
                      to: "/add-account"
                    },
                    {
                      text: "Add Accounts from Excel",
                      to: "/add-accounts-excel"
                    }
                  ]}
                />
              )}
            {role &&
              [USER_ROLES.admin, USER_ROLES.superAdmin].includes(
                role as AuthUserRoles
              ) && (
                <MenuItem
                  onClick={onMenuItemLinkClick}
                  to="/manage-users"
                  text="Manage Users"
                  Icon={IconUsers}
                  menuOpen={open}
                  iconContainerProps={pathname === '/manage-users' ? {
                    bg: "primary",
                    sx: {
                      svg: {
                        width: "16px",
                        fill: "white"
                      }
                    }
                  }: {}}                  
                />
              )}
            {role &&
              [USER_ROLES.admin, USER_ROLES.superAdmin].includes(
                role as AuthUserRoles
              ) && (
                <MenuItem
                  onClick={onMenuItemLinkClick}
                  to="/manage-business"
                  text="Manage Business Entites"
                  Icon={IconBusiness}
                  menuOpen={open}
                  iconContainerProps={pathname === '/manage-business' ? {
                    bg: "primary",
                    sx: {
                      svg: {
                        width: "16px",
                        fill: "white"
                      }
                    }
                  }: {}}                  
                />
              )}              
            {role &&
              [
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.eventCreator
              ].includes(role as AuthUserRoles) && (
                <MenuItem
                  onClick={onMenuItemLinkClick}
                  to="/accounts"
                  text="Accounts"
                  Icon={IconAccounts}
                  menuOpen={open}
                  iconContainerProps={pathname === '/accounts' ? {
                    bg: "primary",
                    sx: {
                      svg: {
                        width: "16px",
                        fill: "white"
                      }
                    }
                  }: {}}                  
                />
              )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default SideNavigation;
