/**
 *
 * * I M P O R T S
 *
 */

/**
 * * IMPORTS: Lib
 */
import React from "react";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

/**
 * * IMPORTS: Hooks
 */
import useScrollToTop from "@/hooks/useScrollToTop";
import useSubscribeToAccounts from "@/hooks/useSubscribeToAccounts";
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";
import useSubscribeToAllEvents from "@/hooks/useSubscribeToAllEvents";
import useParseEventsForDashboard from "@/hooks/useParseEventsForDashboard";
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";
import useGenerateEventColumns from "@/hooks/useGenerateEventColumns";
import useLocalStorage from "@/hooks/useLocalStorage";

/**
 * * IMPORTS: UI
 */
import { Box, Flex } from "rebass";
import {
  IconCheck,
  IconClear,
  IconPeopleOutline
} from "@/ui/common/icon/Icons.common";

/**
 * * IMPORTS: Services
 */
import EventsService from "@/services/events/events.service";

/**
 * * IMPORTS: Constants
 */
import { statuses } from "@/stores/events/events.constants";

/**
 * * IMPORTS: Styles
 */
import { tableActionContainerStyles } from "@/styles/shared/tables.styles";

/**
 * * IMPORTS: Types
 */
import { TFirestoreEvent } from "@typings/events/events.types";
import { Row } from "react-table";
import { TSelectProps } from "@/ui/common/form-elements/select/Select.common.new";

/**
 * * VARIABLES: Types
 */
export interface IShowFilterState {
  requested: boolean;
  approved: boolean;
  booked: boolean;
  executed: boolean;
  denied: boolean;
}

/**
 * * VARIABLES: Constants
 */
export const DASHBOARD_SHOW_INITIAL: IShowFilterState = {
  requested: true,
  approved: false,
  booked: false,
  executed: false,
  denied: false
};

export const DASHBOARD_SHOW_RESET: IShowFilterState = {
  requested: false,
  approved: false,
  booked: false,
  executed: false,
  denied: false
};

const TABLE_TYPES: {
  [key: string]: keyof IShowFilterState;
} = {
  requested: "requested",
  approved: "approved",
  booked: "booked",
  executed: "executed",
  denied: "denied"
};

/**
 *
 * * E X P O R T S
 *
 */

export default (props: Router.RouteComponentProps) => {
  /**
   * * Props
   */
  const { history } = props;
  /**
   * * Hooks
   */
  useScrollToTop();
  const { accounts } = useSubscribeToAccounts();
  const { allEvents } = useSubscribeToAllEvents();
  const ownUser = useSubscribeToOwnUser();
  const columns = useGenerateEventColumns();

  /**
   * * State
   */
  const [selectedRequested, setSelectedRequested] = React.useState<
    Row<TFirestoreEvent>[]
  >();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchKeyBusiness, setSearchKeyBusiness] = React.useState("");
  const [showFilterState, setShowFilterState] = useLocalStorage<
    IShowFilterState
  >("ADMIN_DASHBOARD_FILTERS", DASHBOARD_SHOW_INITIAL);
  const [loading, setLoading] = React.useState(false);

  /**
   * * Handlers
   */

  const onSearchInputChange = React.useCallback(
    (ev: React.ChangeEvent<any>) => {
      setSearchTerm((ev.target as HTMLInputElement).value);
    },
    []
  );

  
  const onSearchBusinessSelectChange = React.useCallback(
    (ev: TSelectProps) => {
      setSearchKeyBusiness(String(ev.value));
    },
    []
  );

  const onSelectionChange = React.useCallback(
    (type: keyof IShowFilterState) => (selection: Row<TFirestoreEvent>[]) => {
      switch (type) {
        case TABLE_TYPES.requested:
          return setSelectedRequested(selection);
      }
    },
    []
  );

  const onRowClick = React.useCallback(
    (val: Row<TFirestoreEvent>) => {
      const rowEvent = val.original;
      history.push(`/events/${rowEvent.id}`);
    },
    [history]
  );

  const onApproveClick = React.useCallback(
    (event: TFirestoreEvent) => async (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      history.push(`/approve-event/${event.id}`, event);
    },
    [history]
  );

  const onDenyClick = React.useCallback(
    (event: TFirestoreEvent) => (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      confirmAlert({
        title: "Are you sure you want to Deny this event?",
        message:
          "This will mark this event as 'denied' and will send an email to the Event Creator to notify them.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                setLoading(true);
                await EventsService.update(event.id, {
                  status: statuses.denied
                });
                toast("Event marked as 'denied', notifying Event Creator...");
              } catch (err) {
                console.error(
                  "AdminDashboard.hook: onDenyClick: Error Updating Event."
                );
              }
              try {
                await EventsService.eventsDenied({
                  eventCreator: event.createdBy,
                  events: [event]
                });
                toast("Event Creator notified.");
                setLoading(false);
              } catch (err) {
                setLoading(false);
                console.error(
                  "AdminDashboard.hook: onDenyClick: Error Notifying Event Creator"
                );
              }
            }
          },
          {
            label: "No",
            onClick: () => {}
          }
        ]
      });
    },
    []
  );

  const onStaffClick = React.useCallback(
    (eventId: string) => async (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      props.history.push(`/staff-event/${eventId}`);
    },
    [props.history]
  );

  const onFilterCheckboxChange = React.useCallback(
    (filterType: keyof IShowFilterState) => (
      ev: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (ev.target.checked && !showFilterState[filterType]) {
        setShowFilterState({
          ...DASHBOARD_SHOW_RESET,
          [filterType]: ev.target.checked
        });
        setSearchTerm("");
      }
    },
    [setShowFilterState, showFilterState]
  );

  const onDenySelectedClick = React.useCallback(async () => {
    if (selectedRequested?.length) {
      confirmAlert({
        title: `Are you sure you want to deny the ${selectedRequested.length} selected event(s)?`,
        message:
          "This will mark selected events as 'denied' and will send an email to the Event Creator to notify them.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              setLoading(true);
              await Promise.all(
                selectedRequested.map(async row => {
                  const event: TFirestoreEvent = row.original;
                  try {
                    await EventsService.update(event.id, {
                      status: statuses.denied
                    });
                  } catch (err) {
                    console.error("Error Denying Event.", err);
                    return Promise.reject(err);
                  }
                })
              )
                .catch(err => {
                  setLoading(false);
                  console.error(
                    "AdminDashboard.hook: onDenySelectedClick",
                    err
                  );
                  toast("Error Denying Event: " + err.toString());
                })
                .then(() => {
                  toast(
                    "Events marked as 'denied', notifying Event Creator(s)"
                  );
                });

              let deniedNotifications: {
                [key: string]: TFirestoreEvent[];
              } = {};

              selectedRequested
                .filter(sel => !!sel.original.createdBy)
                .forEach(ev => {
                  const deniedEventsArr =
                    deniedNotifications[ev.original.createdBy];

                  if (deniedEventsArr) {
                    deniedEventsArr.push(ev.original);
                  } else {
                    deniedNotifications[ev.original.createdBy] = [ev.original];
                  }
                });

              try {
                await Promise.all(
                  Object.keys(deniedNotifications).map(async key => {
                    try {
                      return await EventsService.eventsDenied({
                        eventCreator: key,
                        events: deniedNotifications[key]
                      });
                    } catch (err) {
                      return Promise.reject(err);
                    }
                  })
                );
                setLoading(false);
                toast("Event Creator(s) notified.");
              } catch (err) {
                setLoading(false);
                console.error(
                  "AdminDashboard.hook: onDenySelectedClick: Error sending email notifications",
                  err
                );
              }
            }
          },
          {
            label: "No",
            onClick: () => {}
          }
        ]
      });
    }
  }, [selectedRequested]);

  /**
   * * react-table Columns
   */

  const requestedTableColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        Header: "",
        accessor: "actions",
        Cell: (context: { row: { original: TFirestoreEvent } }) => (
          <>
            <Flex justifyContent="flex-end">
              <Box
                as="button"
                onClick={onApproveClick(context.row.original)}
                data-tip="approve event (this will send sms notication to the event creator)"
                data-place="left"
                sx={{
                  ...tableActionContainerStyles,
                  svg: {
                    fill: "success",
                    width: "16px"
                  }
                }}
              >
                <IconCheck title="Approve" />
              </Box>
              <Box
                onClick={onDenyClick(context.row.original)}
                data-tip="deny/decline event (this will send email notication to the event creator)"
                data-place="left"
                as="button"
                sx={{
                  ...tableActionContainerStyles,
                  svg: {
                    fill: "danger",
                    width: "16px"
                  }
                }}
              >
                <IconClear title="Deny" />
                <ReactTooltip />
              </Box>
            </Flex>
          </>
        )
      }
    ];
  }, [columns, onApproveClick, onDenyClick]);

  const approvedTableColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        Header: "",
        accessor: "staff",
        Cell: (context: { row: { original: TFirestoreEvent } }) => (
          <>
            <Flex justifyContent="flex-end">
              <Box
                as="button"
                onClick={onStaffClick(context.row.original.id)}
                data-tip="Staff Event"
                sx={{
                  ...tableActionContainerStyles,
                  svg: {
                    fill: "success",
                    width: "16px"
                  }
                }}
              >
                <IconPeopleOutline title="Staff" />
              </Box>
              <ReactTooltip />
            </Flex>
          </>
        )
      }
    ];
  }, [columns, onStaffClick]);

  const { business } = useSubscribeToBusiness();
  const events = useParseEventsForDashboard(allEvents, searchKeyBusiness);

  return {
    events,
    business,
    accounts,
    columns,
    requestedTableColumns,
    approvedTableColumns,
    selectedRequested,
    searchTerm,
    searchKeyBusiness,
    showFilterState,
    onSelectionChange,
    onSearchInputChange,
    onFilterCheckboxChange,
    onSearchBusinessSelectChange,
    onRowClick,
    onDenySelectedClick,
    loading,
    user: ownUser
  };
};
