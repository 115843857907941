/** Lib */
import React from "react";
import * as firebase from "firebase/app";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";

/** Hooks */
import usersService from "@/services/users/users.service";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";

export default (props: { user: TUserFirestore }) => {
  const { user } = props;
  /**
   * Refs
   */
  const avatarRef = React.useRef<AvatarEditor>();
  /**
   * State
   */
  const [newAvatar, setNewAvatar] = React.useState<File>();
  const [avatarEdit, setAvatarEdit] = React.useState(false);
  const [avatarScale, setAvatarScale] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  /**
   * Handlers
   */
  const onAvatarCancel = React.useCallback(() => {
    setAvatarEdit(false);
    setNewAvatar(undefined);
  }, []);

  const onDrop = React.useCallback(acceptedFiles => {
    setNewAvatar(acceptedFiles[0]);
    setAvatarEdit(true);
  }, []);

  const onAvatarSave = React.useCallback(() => {
    if (avatarRef.current && user) {
      setLoading(true);
      avatarRef.current?.getImageScaledToCanvas().toBlob(async blob => {
        if (blob) {
          // Create a root reference
          var storageRef = firebase.storage().ref();

          // Create a reference to 'images/mountains.jpg'
          var imagesRef = storageRef.child(
            `images/profiles/${user.id}/avatar.jpg`
          );

          try {
            const snapshot = await imagesRef.put(blob);
            const newURL = await snapshot.ref.getDownloadURL();
            await usersService.updateById(user.id, {
              avatar: newURL as string
            });
            setAvatarEdit(false);
            setLoading(false);
          } catch (err) {
            setAvatarEdit(false);
            setLoading(false);
            console.error("Error uploading new avatar.", err);
          }
        }
      });
    }
  }, [avatarRef, user]);

  const onScaleChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setAvatarScale(Number(ev.target.value));
    },
    []
  );

  /**
   * Dropzone
   */
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return {
    newAvatar,
    avatarEdit,
    avatarRef,
    avatarScale,
    loading,
    getRootProps,
    getInputProps,
    onScaleChange,
    onAvatarCancel,
    onAvatarSave
  };
};
