export const tableStyles = {
  textAlign: "left",
  borderSpacing: "0",
  borderCollapse: "separate",
  margin: "0 auto 60px",
  width: "100%",
  th: {
    fontFamily: "heading",
    padding: ["10px", "15px"]
  },
  "thead > tr": {
    backgroundColor: "transparent"
  },
  "tbody > tr": {
    ":nth-of-type(odd)": {
      backgroundColor: "lightGray"
    },
    // cursor: "pointer",
    transition: "opacity 125ms",
    "& > td:last-child": {
      textAlign: "right"
    }
  },
  td: {
    padding: ["5px", "10px"]
  }
};
