/** Lib */
import React from "react";

/** Stores */
import { useStore as useEventsStore } from "@stores/events/events.store";
import { useStore as useUsersStore } from "@stores/users/users.store";

/** Services */
import EventsService from "@services/events/events.service";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";

export default () => {
  const { state, actions } = useEventsStore();
  const {
    state: { ownUser }
  } = useUsersStore();

  const { subscribedToOwn } = state;

  React.useEffect(() => {
    if (!subscribedToOwn && ownUser) {
      EventsService.subscribeToOwn(events => {
        const data = events.docs.map(doc => doc.data()) as TFirestoreEvent[];
        actions.setOwnEvents(data);
        actions.setSubscribedToOwn(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToOwn, ownUser]);

  return state;
};
