/** Lib */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/storage";

/** Context */
import { AuthContextProvider } from "@stores/auth/auth.store";
import { EventsContextProvider } from "@stores/events/events.store";
import { AccountsContextProvider } from "./stores/accounts/accounts.store";
import { UsersContextProvider } from "./stores/users/users.store";
import { BusinessContextProvider } from "./stores/business/business.store";

/** UI */
import App from "./ui/screens/App";

/** Config */
import * as serviceWorker from "./serviceWorker";
import { firebaseConfig } from "./config";

firebase.initializeApp(firebaseConfig);

firebase.analytics().logEvent("Loaded app");

// Use for local functions testing.
if (process.env.REACT_APP_USE_FUNCTIONS_EMULATOR === "true") {
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false
  });
}

ReactDOM.render(
  <Router>
    <AuthContextProvider>
      <EventsContextProvider>
        <AccountsContextProvider>
          <UsersContextProvider>
            <BusinessContextProvider>
              <App />
            </BusinessContextProvider>
          </UsersContextProvider>
        </AccountsContextProvider>
      </EventsContextProvider>
    </AuthContextProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
