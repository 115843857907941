import React from "react";
import { Container } from "@/ui/common/responsive";
import Card from "@/ui/common/card/Card.common";
import EventRecapForm from "@/ui/components/event-recap-form/EventRecapForm.component";

import hooks from "./EventRecap.hooks";
import Loader from "@/ui/common/loader/Loader.common";

const EventRecapFormScreen: React.FC<Router.RouteComponentProps<{
  id: string;
}>> = (props) => {
  const { user } = hooks(props);

  if (!user) {
    return (
      <Container pt={4}>
        <Card p={4} maxWidth="800px" m="0 auto">
          <Loader className="la-3x" m="0 auto" />
        </Card>
      </Container>
    );
  }

  return (
    <Container pt={4}>
      <Card p={4} maxWidth="800px" m="0 auto">
        <EventRecapForm {...props} user={user} />
      </Card>
    </Container>
  );
};

export default EventRecapFormScreen;
