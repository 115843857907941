/** Lib */
import React from "react";

/** Services */
import AccountsService from "@services/accounts/accounts.service";

export default (props: Router.RouteComponentProps<TParamsWithId>) => {
  const [accountsState, setAccountsState] = React.useState();
  const account = props.location.state || accountsState;

  React.useEffect(() => {
    const { id } = props.match.params;
    if (!account && id) {
      AccountsService.getById(id).then(doc => {
        setAccountsState({
          id: doc.id,
          ...doc.data()
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { account };
};
