/** Lib */
import React from "react";
import xlsx from "xlsx";
import * as firebase from "firebase/app";
import { useDropzone } from "react-dropzone";
import { camelCase } from "camel-case";
import { convertToLocalTime } from "date-fns-timezone";
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/** Services */
import EventsService from "@services/events/events.service";

/** Serializers */
import serializeEvents from "@services/events/events.serializer";

/** Validators */
import { validateExcelParsedEvent } from "@schemas/events/events.schemas";

/** Util */
import getHeadersFromExcelSheet from "@util/getHeadersFromExcelSheet";
import { REQUIRED_EXCEL_HEADERS } from "@constants/events.constants";

/** Types */
import { IEventUploadResponse, TEventData, IValidationResult } from "./ScheduleEventsExcel.types";
import { TFirestoreEvent, TExcelEventParsed } from "@typings/events/events.types";
import AccountsService from "@/services/accounts/accounts.service";

import useSubscribeToAllBusiness from "@/hooks/useSubscribeToAllBusiness";
import { USER_ROLES } from "@/stores/auth/auth.constants";

/** Constants */
const MAX_FILE_SIZE = 2000000;
const EXCEL_OFFSET = 2; // offset for excel sheet headers.

export default () => {
  /**
   * State
   */
  const [loading, setLoading] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState<IEventUploadResponse>();
  const [eventData, setEventData] = React.useState<TEventData>([]);
  const [workbook, setWorkbook] = React.useState<xlsx.WorkBook>();
  const [rejectedFilesLocal, setRejectedFilesLocal] = React.useState<File[]>();
  const [workbookValid, setWorkbookValid] = React.useState<boolean | undefined>();

  const { business } = useSubscribeToAllBusiness();
  const {
    state: { user },
  } = useAuthStore();

  /**
   * set local state for rejected files
   * when file drop fails.
   */
  const onDropRejected = React.useCallback((rejectedFiles) => {
    setRejectedFilesLocal(rejectedFiles);
  }, []);

  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsBinaryString(acceptedFiles[0]);

      reader.onload = () => {
        // parse file data, convert to json, and return it to client.
        try {
          const parsed = xlsx.read(reader.result, { type: "binary" });

          const isWorkbookValid = parsed.SheetNames.every((name) => {
            for (let count = 0; count <= 100; count++) {
              const cell = parsed.Sheets[name][xlsx.utils.encode_cell({ r: 2, c: count })];
              if (!cell) break;
              Object.keys(cell).forEach((key) => {
                cell[key] = `${cell[key]}`.replace(/(\r\n|\n|\r)/gm, "");
              });
            }
            const headers = getHeadersFromExcelSheet(parsed.Sheets[name], EXCEL_OFFSET);
            // If legend sheet, skip validation
            if (name === "Legend") {
              return true;
            }

            return REQUIRED_EXCEL_HEADERS.every((header) => {
              return headers.includes(header);
            });
          });

          if (isWorkbookValid) {
            setWorkbook(parsed);
            setWorkbookValid(true);
          } else {
            setWorkbook(undefined);
            setWorkbookValid(false);
          }
        } catch (err) {
          setWorkbook(undefined);
          setWorkbookValid(false);
        }
      };
    }
  }, []);

  const dropzoneState = useDropzone({
    onDrop,
    onDropRejected,
    accept: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"],
    minSize: 0,
    maxSize: MAX_FILE_SIZE,
  });

  const onUploadSubmit = React.useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();
      if (eventData.length) {
        try {
          setLoading(true);
          const data = eventData
            .filter((sheet) => {
              return !!sheet.data.length;
            })
            .reduce((evArr, sheet) => {
              return [...evArr, ...sheet.data];
            }, [] as TFirestoreEvent[]);
          const response = await EventsService.addEvents(data);
          await AccountsService.addAccounts(data.map((acc) => ({ accountName: acc.accountName })));
          if (response?.added.length) {
            await EventsService.eventsCreated(response?.added.length);
          }
          setLoading(false);
          setUploadResponse(response);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      }
    },
    [eventData]
  );

  React.useEffect(() => {
    (async () => {
      if (workbook && user && business) {
        const sheets: TEventData = workbook.SheetNames.filter((sName) => sName !== "Legend").map((name) => {
          const sheet = workbook.Sheets[name];
          // strip headers (row 3) of special characters, and camelCase.
          for (let count = 0; count <= 100; count++) {
            const cell = sheet[xlsx.utils.encode_cell({ r: 2, c: count })];
            if (!cell) break;
            Object.keys(cell).forEach((key) => {
              cell[key] = camelCase(`${cell[key]}`.replace(/[^A-Za-z0-9]/g, ""));
            });
          }

          const json: Array<any> = xlsx.utils.sheet_to_json(sheet, {
            range: EXCEL_OFFSET,
          });

          const validation: IValidationResult<TExcelEventParsed> = json.reduce(
            (valAcc, row, index) => {
              try {
                const trimmedRow = Object.keys(row).reduce((evAcc, key) => {
                  const ev = row as any;

                  return {
                    ...evAcc,
                    [key]: typeof ev[key] === "string" ? ev[key].trim() : ev[key],
                  };
                }, {}) as TExcelEventParsed;

                const result = validateExcelParsedEvent(trimmedRow);
                const rowBusinessEntity = business.find((bus) => bus.accountName === trimmedRow.businessEntity);
                const isAdmin = [USER_ROLES.superAdmin, USER_ROLES.admin].includes(user.claims.role);
                const isEventCreator = user.claims.role === USER_ROLES.eventCreator;
                const userBusinessEntity = business.find((bus) => bus.id === user.claims.business);
                const canCreate =
                  rowBusinessEntity &&
                  userBusinessEntity &&
                  (isAdmin || (isEventCreator && userBusinessEntity.accountName === rowBusinessEntity.accountName));

                if (row.businessEntity && rowBusinessEntity) row.businessEntity = rowBusinessEntity.id;
                if (result && canCreate) {
                  return {
                    failed: valAcc.failed,
                    passed: [...valAcc.passed, row],
                  };
                } else if (!canCreate) {
                  return {
                    passed: valAcc.passed,
                    failed: [
                      ...valAcc.failed,
                      {
                        path: ``,
                        message: `Business Entity does not exist, is blank, or your user account does not belong to it.`,
                        row: index + EXCEL_OFFSET * 2,
                      },
                    ],
                  };
                }
              } catch (err) {
                console.error(err);
                return {
                  passed: valAcc.passed,
                  failed: [...valAcc.failed, { ...err, row: index + EXCEL_OFFSET * 2 }],
                };
              }
              return valAcc;
            },
            { passed: [], failed: [] } as IValidationResult<TExcelEventParsed>
          );

          return {
            name,
            data: serializeEvents(validation.passed) as TFirestoreEvent[],
            failed: validation.failed,
          };
        });

        const locations = sheets.reduce((locationsAcc, sheet) => {
          const sheetLocations = sheet.data.map((ev) => {
            return { zip: ev.zipCode };
          });

          const uniqueSheetLocations = sheetLocations.filter((ev, index) => {
            return sheetLocations.findIndex((val) => val.zip === ev.zip) === index;
          });

          return [
            ...locationsAcc,
            ...uniqueSheetLocations.filter((ev) => {
              return !locationsAcc.find((loc) => {
                return `${loc.zip}` === `${ev.zip}`;
              });
            }),
          ];
        }, [] as { zip: string }[]);

        const response = await firebase.functions().httpsCallable("getTimezones")(locations);

        const sheetsWithTimezone = sheets.map((sheet) => {
          return {
            name: sheet.name,
            failed: sheet.failed,
            data: sheet.data.map((ev) => {
              const tz = response.data.find(
                (tzs: any) => tzs.zip.trim() === ev.zipCode.trim() && tzs.zip.trim() === ev.zipCode.trim()
              );
              if (tz && tz.timezone) {
                const convertedEventStartTime = convertToLocalTime(ev.eventStartTime, {
                  timeZone: tz.timezone,
                });
                const convertedEventEndTime = convertToLocalTime(ev.eventEndTime, {
                  timeZone: tz.timezone,
                });
                return {
                  ...ev,
                  timezone: tz.timezone,
                  eventStartTime: convertedEventStartTime,
                  eventEndTime: convertedEventEndTime,
                };
              }
              return {
                ...ev,
                timezone: "",
              };
            }),
          };
        });

        setEventData(sheetsWithTimezone);

        setLoading(false);
      }
    })();
  }, [workbook, user, business]);

  const resetForm = React.useCallback(() => {
    setWorkbookValid(undefined);
    setWorkbook(undefined);
    setEventData([]);
    setRejectedFilesLocal(undefined);
    setLoading(false);
    setUploadResponse(undefined);
  }, []);

  const isFileTooLarge = React.useMemo(
    () => rejectedFilesLocal && rejectedFilesLocal.length > 0 && rejectedFilesLocal[0].size > MAX_FILE_SIZE,
    [rejectedFilesLocal]
  );

  return {
    dropzoneState,
    isFileTooLarge,
    onUploadSubmit,
    eventData,
    workbookValid,
    workbook,
    rejectedFilesLocal,
    resetForm,
    loading,
    uploadResponse,
  };
};
