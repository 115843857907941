/** Lib */
import React from "react";
import * as yup from "yup";
import { useTable, useSortBy } from "react-table";
import { useFormik } from "formik";
import { Button } from "rebass";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { IconClear } from "@/ui/common/icon/Icons.common";

/** Services */
import formatPhoneNumber from "@util/formatPhone";

/** Hooks */
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";
import { TBusinessFirestore } from "@/typings/business/business.types";
import BusinessService from "@/services/business/business.service";

export default (props: Router.RouteComponentProps) => {
  const allBusiness = useSubscribeToBusiness();
  const [businessArr, setBusinessArr] = React.useState<Array<TBusinessFirestore>>(
    []
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      primaryEmail: "",
      accountName: "",
      phone: "",
      address: ""
    },
    validationSchema: yup.object().shape({
      primaryEmail: yup
        .string()
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
          "Email invalid."
        )
        .required("Email is required."),
      accountName: yup
        .string()
        .required("Account Name is required."),
      phone: yup
        .string()
        .required("Phone is required."),
      address: yup
        .string()
        .required("Address is required."),
    }),
    onSubmit: async (values, formikHelpers) => {
      setIsLoading(true);
      const isExist = await BusinessService.isAccountExist(values.accountName);

      if (isExist) {
        toast('User already exists.');
        setIsLoading(false);
        formikHelpers.resetForm();
      } else {
        formikHelpers.resetForm();
        await BusinessService.createNewBusiness({id: '', ...values});
        setIsLoading(false);
      }
    },
  });

  React.useEffect(() => {
    setBusinessArr(allBusiness.business);
  }, [allBusiness]);

  const onDeleteClick = React.useCallback((id: string) => async (ev: React.MouseEvent) => {
    BusinessService.deleteBusinessById(id);
  }, []);

  const activeColumns = React.useMemo(() => {
    return [
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Primary Email",
        accessor: "primaryEmail",
      },
      {
        Header: "Phone",
        accessor: (row: TBusinessFirestore) =>
          formatPhoneNumber(row.phone) as string,
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }: { row: { original: TBusinessFirestore } }) => {
          return (
            <>
              <Button
                type="button"
                variant="blank"
                onClick={onDeleteClick(row.original.id)}
                data-tip="Delete Business"
                sx={{
                  svg: {
                    fill: "danger",
                    width: "16px",
                  },
                }}
              >
                <ReactTooltip />
                <IconClear />
              </Button>
            </>
          );
        },
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessTable = useTable<TBusinessFirestore>(
    {
      columns: activeColumns,
      data: businessArr,
      initialState: {
        sortBy: [{ id: "accountName", desc: false }],
      } as any,
    },
    useSortBy
  );

  return {
    allBusiness,
    businessTable,
    formik,
    isLoading
  };
};
