export const tableActionContainerStyles = {
  background: "transparent",
  border: "none",
  px: 3,
  cursor: "pointer",
  transition: "opacity 150ms",
  "&:hover": {
    opacity: 0.65
  }
};
