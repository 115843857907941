/** Lib */
import * as firebase from "firebase/app";

/** Types */
import { TFirestoreEvent, TFirestoreEventProtected, IFirestoreEventRecap } from "@typings/events/events.types";
import { firestoreEventSchema } from "@schemas/events/events.schemas";
import {
  FIREBASE_EVENTS_RESOURCE_NAME,
  FIREBASE_EVENTS_RESOURCE_NAME_SINGLE,
  FIREBASE_EVENTS_PROTECTED_NAME,
  TInvitationResponses,
  FIREBASE_EVENT_RECAP_RESOURCE_NAME,
  FIREBASE_EVENT_RECAP_RESOURCE_NAME_SINGLE,
} from "@constants/events.constants";

export default {
  getById(id: string) {
    return firebase.firestore().collection(FIREBASE_EVENTS_RESOURCE_NAME).doc(id).get();
  },
  async create(payload: TFirestoreEvent) {
    if (!firestoreEventSchema.validateSync(payload)) {
      return Promise.reject(
        new Error(
          `${FIREBASE_EVENTS_RESOURCE_NAME_SINGLE} Service: Create: Invalid ${FIREBASE_EVENTS_RESOURCE_NAME_SINGLE} Schema`
        )
      );
    }
    try {
      const ref = firebase.firestore().collection(FIREBASE_EVENTS_RESOURCE_NAME).doc(payload.id);

      return ref.set(payload);
    } catch (err) {
      console.error(
        `${FIREBASE_EVENTS_RESOURCE_NAME} Service: Create: Error Adding ${FIREBASE_EVENTS_RESOURCE_NAME_SINGLE}`
      );
    }
  },
  async updateEventProtected(id: string, values: TFirestoreEventProtected) {
    const ref = firebase.firestore().collection(FIREBASE_EVENTS_PROTECTED_NAME(id)).doc(id);

    const data = await ref.get();

    if (data.exists) {
      return ref.update(values);
    }
    return ref.set(values);
  },
  async addEvents(events: TFirestoreEvent[]) {
    try {
      const duplicates: TFirestoreEvent[] = [];
      const eventsToAdd: TFirestoreEvent[] = [];

      await Promise.all(
        events.map((event) => {
          const eventDocRef = firebase.firestore().collection(FIREBASE_EVENTS_RESOURCE_NAME).doc(event.id);
          return eventDocRef.get().then((doc) => {
            if (doc.exists) {
              return duplicates.push(event);
            }
            return eventsToAdd.push(event);
          });
        })
      );

      const batch = firebase.firestore().batch();

      eventsToAdd.forEach((event) => {
        const eventDocRef = firebase.firestore().collection(FIREBASE_EVENTS_RESOURCE_NAME).doc(event.id);

        batch.set(eventDocRef, event);
      });

      await batch.commit();

      return { added: eventsToAdd, duplicates };
    } catch (err) {
      console.error("Error adding events.", err);
    }
  },
  subscribeToAll(
    snapshotCallback: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_EVENTS_RESOURCE_NAME)
      .onSnapshot(snapshotCallback, (err) => {
        console.error("Error occurred subscribing to events", err);
      });
  },
  subscribeToSingle(
    eventId: string,
    snapshotCallback: (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_EVENTS_RESOURCE_NAME)
      .doc(eventId)
      .onSnapshot(snapshotCallback, (err) => {
        console.error("Error occurred subscribing to event", err);
      });
  },
  subscribeToSingleEventProtected(
    eventId: string,
    snapshotCallback: (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_EVENTS_PROTECTED_NAME(eventId))
      .doc(eventId)
      .onSnapshot(snapshotCallback, (err) => {
        console.error("Error occurred subscribing to event protected", err);
      });
  },
  subscribeToMultiple(
    eventIds: string[],
    snapshotCallback: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_EVENTS_RESOURCE_NAME)
      .where("id", "in", eventIds)
      .onSnapshot(snapshotCallback, (err) => {
        console.error("Error subscribing to multiple events", err);
      });
  },
  subscribeToSingleEventRecap(eventId: string, cb: (recap: IFirestoreEventRecap) => void) {
    firebase
      .firestore()
      .collection(FIREBASE_EVENT_RECAP_RESOURCE_NAME)
      .doc(eventId)
      .onSnapshot(
        (snap) => {
          if (snap.exists) {
            return cb(snap.data() as IFirestoreEventRecap);
          }
          console.error(`${FIREBASE_EVENT_RECAP_RESOURCE_NAME_SINGLE} ${eventId} does not exist.`);
        },
        (err) => {
          console.error(err);
        }
      );
  },
  async updateEventRecap(eventId: string, data: Partial<IFirestoreEventRecap>) {
    const filtered = {};
    Object.keys(data).forEach((key) => {
      if ((data as any)[key] !== undefined) {
        (filtered as any)[key] = (data as any)[key];
      }
    });
    try {
      await firebase.firestore().collection(FIREBASE_EVENT_RECAP_RESOURCE_NAME).doc(eventId).update(filtered);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  },
  eventsCreated(count: number) {
    return firebase.functions().httpsCallable("eventsCreated")({
      eventCount: count,
    });
  },
  eventsDenied(data: { eventCreator: string; events: TFirestoreEvent[] }) {
    return firebase.functions().httpsCallable("eventsDenied")(data);
  },
  update(id: string, payload: Partial<TFirestoreEvent>) {
    return firebase
      .firestore()
      .collection(FIREBASE_EVENTS_RESOURCE_NAME)
      .doc(id)
      .update(payload)
      .catch((err) => {
        console.error(`${FIREBASE_EVENTS_RESOURCE_NAME} SERVICE: ERROR UPDATING: ${err}`);
        return Promise.reject(err);
      });
  },
  respondToEventInvite(data: { userId: string; eventId: string; response: TInvitationResponses }) {
    return firebase.functions().httpsCallable("respondToEventInvitation")(data);
  },
  async subscribeToOwn(
    snapshotCallback: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void
  ) {
    const authId = firebase.auth().currentUser?.uid;

    try {
      const result = await firebase.auth().currentUser?.getIdTokenResult();
      if (result?.claims?.business) {
        return firebase
          .firestore()
          .collection(FIREBASE_EVENTS_RESOURCE_NAME)
          .where("business", "==", result.claims.business)
          .onSnapshot(snapshotCallback, (err) => {
            console.error("Error subscribing to own events", err);
          });
      }
    } catch (err) {
      console.error(err);
      return;
    }

    if (!authId) return console.error("not authenticated");
    return (
      firebase
        .firestore()
        .collection(FIREBASE_EVENTS_RESOURCE_NAME)
        // .where("createdBy", "==", firebase.auth().currentUser?.uid as string)
        .onSnapshot(snapshotCallback, (err) => {
          console.error("Error subscribing to own events", err);
        })
    );
  },
  async subscribeToOwnAmbassadorEvents(
    snapshotInvitedCallback: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void,
    snapshotAcceptedCallback: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void,
    // snapshotDeclinedCallback: (
    //   snapshot: firebase.firestore.QuerySnapshot<
    //     firebase.firestore.DocumentData
    //   >
    // ) => void,
    snapshotConfirmedCallback: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void
  ) {
    const authId = firebase.auth().currentUser?.uid;
    if (!authId) return console.error("not authenticated");

    firebase
      .firestore()
      .collection(`Events`)
      .where("invitedUsers", "array-contains", authId)
      .onSnapshot(snapshotInvitedCallback, (err) => {
        console.error("Error subscribing to own ambassador INVITED events", err);
      });
    firebase
      .firestore()
      .collection(`Events`)
      .where("acceptedInviteUsers", "array-contains", authId)
      .onSnapshot(snapshotAcceptedCallback, (err) => {
        console.error("Error subscribing to own ambassador ACCEPTED events", err);
      });
    firebase
      .firestore()
      .collection(`Events`)
      .where("confirmedByAdminUsers", "array-contains", authId)
      .onSnapshot(snapshotConfirmedCallback, (err) => {
        console.error("Error subscribing to own ambassador CONFIRMED events", err);
      });
  },
};
