/** Lib */
import React from "react";

/** UI */
import { Button, Heading, Flex } from "rebass";
import { Container } from "@common/responsive";
import Table from "@common/table/Table.common";
import Card from "@/ui/common/card/Card.common";
import DashboardFilters from "@components/dashboard/DashboardFilters.component";

/** Hooks */
import hooks from "./AdminDashboard.hooks";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import CheckboxTable from "@/ui/common/checkbox-table/CheckboxTable.common";
import Loader from "@/ui/common/loader/Loader.common";

const AdminDashboard: React.FC<Router.RouteComponentProps> = props => {
  const {
    events,
    business,
    columns,
    requestedTableColumns,
    approvedTableColumns,
    searchTerm,
    searchKeyBusiness,
    loading,
    showFilterState,
    selectedRequested,
    onSelectionChange,
    onRowClick,
    onSearchInputChange,
    onSearchBusinessSelectChange,
    onFilterCheckboxChange,
    onDenySelectedClick,
    user
  } = hooks(props);

  return (
    <Container pt={4}>
      <Heading mb={4}>Dashboard</Heading>
      {user && !loading ? (
        <>
          <DashboardFilters
            onSearchInputChange={onSearchInputChange}
            onSearchBusinessSelectChange={onSearchBusinessSelectChange}
            onFilterCheckboxChange={onFilterCheckboxChange}
            showFilterState={showFilterState}
            searchKeyBusiness={searchKeyBusiness}
            searchTerm={searchTerm}
            user={user}
            business={business}
          />

          <Card p={[3, 3, 4]}>
            {events && showFilterState.requested && (
              <>
                <Flex mb={3} flexWrap="wrap" justifyContent="space-between">
                  <Heading variant="small">Requested Events</Heading>
                  <Button
                    variant={
                      !selectedRequested?.length || loading
                        ? "danger.disabled"
                        : "danger"
                    }
                    type="button"
                    disabled={!selectedRequested?.length || loading}
                    onClick={onDenySelectedClick}
                  >
                    {loading ? (
                      <Loader height="15px" width="15px" m="0 auto" />
                    ) : (
                      "Deny Selected"
                    )}
                  </Button>
                </Flex>
                {!loading ? (
                  <CheckboxTable<TFirestoreEvent>
                    id="adminRequested"
                    columns={requestedTableColumns}
                    tableData={events.requested}
                    onRowClick={onRowClick}
                    onSelectionChange={onSelectionChange("requested")}
                    filterValue={searchTerm}
                    css={{
                      minHeight: "375px"
                    }}
                  />
                ) : (
                  <Loader className="la-3x" />
                )}
              </>
            )}
            {events && showFilterState.approved && (
              <>
                <Heading variant="small" mb={3}>
                  Approved Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={approvedTableColumns}
                  tableData={events.approved}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {events && showFilterState.booked && (
              <>
                <Heading variant="small" mb={3}>
                  Booked Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={events.booked}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {events && showFilterState.executed && (
              <>
                <Heading variant="small" mb={3}>
                  Executed Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={events.executed}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {events && showFilterState.denied && (
              <>
                <Heading variant="small" mb={3}>
                  Denied Events
                </Heading>

                <Table<TFirestoreEvent>
                  columns={columns}
                  tableData={events.denied}
                  onRowClick={onRowClick}
                  filterValue={searchTerm}
                  css={{
                    minHeight: "375px"
                  }}
                />
              </>
            )}
            {/* <Box>
          <Link to="schedule-events-excel">
            <Button type="button" mr={2}>
              Schedule Events
            </Button>
          </Link>
          <Link to="calendar">
            <Button variant="secondary">View Calendar</Button>
          </Link>
        </Box> */}
          </Card>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      )}
    </Container>
  );
};

export default AdminDashboard;
