/** Lib */
import * as firebase from "firebase/app"

import {
  FIREBASE_BUSINESS_RESOURCE_NAME
} from "@constants/business.constants";
import { TBusinessFirestore } from "@/typings/business/business.types";

const BusinessService = {
  async isAccountExist(accountName: string) {
    const exist = await firebase
      .firestore()
      .collection(FIREBASE_BUSINESS_RESOURCE_NAME)
      .where('accountName', '==', accountName)
      .limit(1)
      .get();

    return !exist.empty 
  },
  createNewBusiness(payload: TBusinessFirestore) {
    const createRef = firebase
      .firestore()
      .collection(FIREBASE_BUSINESS_RESOURCE_NAME)
      .doc();
    
    createRef.set({ ...payload, id: createRef.id });
  },
  deleteBusinessById(id: string) {
    firebase
      .firestore()
      .collection(FIREBASE_BUSINESS_RESOURCE_NAME)
      .doc(id)
      .delete()
  },
  getById(id: string) {
    return firebase
      .firestore()
      .collection(FIREBASE_BUSINESS_RESOURCE_NAME)
      .doc(id)
      .get();
  },
  getByAccountName(accountName: string) {
    return firebase
      .firestore()
      .collection(FIREBASE_BUSINESS_RESOURCE_NAME)
      .limit(1)
      .where("accountName", "==", accountName.toLowerCase())
      .get();
  },
  subscribeToAll(
    snapshotCallback: (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_BUSINESS_RESOURCE_NAME)
      .orderBy(`accountName`)
      .onSnapshot(snapshotCallback, err => {
        console.error(
          `Error occurred subscribing to ${FIREBASE_BUSINESS_RESOURCE_NAME}`,
          err
        );
      });
  },  
};

export default BusinessService;
