/** Lib */
import React from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from "react-table";
/** Types */
import { ITableProps } from "./Table.common";

function useTableHooks<RowDataType>(props: ITableProps<RowDataType>) {
  const {
    columns,
    tableData,
    defaultSortBy,
    onRowClick: onRowClickProp,
    filterValue
  } = props;
  /**
   * table hooks
   */
  const table = useTable<RowDataType>(
    {
      columns: columns || [],
      data: tableData || [],
      initialState: defaultSortBy
        ? {
            pageSize: 10,
            sortBy: defaultSortBy
          }
        : {}
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    if (typeof filterValue === "string") {
      table.setGlobalFilter(filterValue);
    }

    // eslint-disable-next-line
  }, [filterValue]);

  const onRowClick = React.useCallback(
    (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (onRowClickProp) {
        onRowClickProp(row);
      }
    },
    [onRowClickProp]
  );

  return { table, onRowClick };
}

export default useTableHooks;
