/** Lib */
import React from "react";

/** Stores */
import { useStore } from "@stores/users/users.store";

/** Services */
import UsersService from "@services/users/users.service";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";

/** Constants */
import { USER_ROLES } from "@/stores/auth/auth.constants";

export default () => {
  const { state, actions } = useStore();

  const { subscribedToAmbassadors,  ambassadors } = state;

  React.useEffect(() => {
    if (!subscribedToAmbassadors) {
      UsersService.subscribeToUsersByRole(
        USER_ROLES.brandAmbassador,
        users => {
          const data = users.docs.map(doc => doc.data()) as TUserFirestore[];
          actions.setAmbassadors(data);
          actions.setSubscribedToAmbassadors(true);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToAmbassadors]);

  return ambassadors;
};
