/** Lib */
import * as yup from "yup";
import { TAccountExcelParsed } from "@typings/accounts/accounts.types";

export const accountSchema = yup.object().shape({
  accountName: yup.string().required(),
  id: yup.string().required()
});

export const accountFormSchema = yup.object().shape({
  accountName: yup.string().required("Account Name is required.")
});

export const accountExcelSchema = yup.object().shape({
  "Account Name": yup.string().required()
});

// schema after special characters are removed, etc.
export const accountExcelParsedSchema = yup.object().shape({
  accountName: yup.string().required()
});

export const validateExcelParsedAccount = (account: TAccountExcelParsed) => {
  return accountExcelParsedSchema.validateSync(account);
};
