/** Lib */
import React from "react";

/** UI */
import Table from "@common/table/Table.common";

/** Types */
import { TAccountFirestore } from "@typings/accounts/accounts.types";
// import { Row } from "react-table";

export interface IAccountsListingProps {
  accounts: TAccountFirestore[];
}

const AccountsListing: React.FC<
  IAccountsListingProps & Router.RouteComponentProps
> = (props) => {
  return (
    <Table
      tableData={props.accounts}
      // onRowClick={(row: Row<TAccountFirestore>) => {
      //   props.history.push(`/accounts/${row.original.accountName}`, row.original);
      // }}
      sx={{
        "tbody > tr": {
          ":nth-of-type(odd)": {
            backgroundColor: "lightGray",
            "&:hover": {
              backgroundColor: "#dadada",
            },
          },
          transition: "opacity 125ms",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
          cursor: "default",
        },
      }}
      columns={[
        {
          Header: "Account Name",
          accessor: "accountName",
        },
      ]}
    />
  );
};

export default AccountsListing;
