/** Lib */
import React from "react";
import * as firebase from "firebase/app";

/** Hooks */
import useSubscribeToAllEvents from "@/hooks/useSubscribeToAllEvents";
import { convertToTimeZone } from "date-fns-timezone";

/** Constants */
import { statuses } from "@stores/events/events.constants";

/** Types */
import { TCalendarProps } from "./Calendar.component";
import useSubscribeToAmbassadorEvents from "@/hooks/useSubscribeToAmbassadorEvents";
import { USER_ROLES } from "@/stores/auth/auth.constants";
import { TFirestoreEvent } from "@/typings/events/events.types";

const eventColors = ["#512DA8", "#673AB7", "#03A9F4", "#303F9F", "#00BCD4", "#C2185B", "#D32F2F"];

export default (props: TCalendarProps) => {
  const { auth } = props;
  const [events, setEvents] = React.useState<Array<any>>([]);
  const [searchKeyBusiness, setSearchKeyBusness] = React.useState<string>("");
  const { allEvents } = useSubscribeToAllEvents();
  const ownAmbassadorEvents = useSubscribeToAmbassadorEvents();

  const onSearchKeyChange = React.useCallback((ev: any) => {
    setSearchKeyBusness(ev.value);
  }, []);

  const calendarEvents = React.useMemo(() => {
    let eventsArr: Array<TFirestoreEvent> = [];
    if (auth) {
      if (auth.claims.role === USER_ROLES.brandAmbassador && ownAmbassadorEvents) {
        const { acceptedEvents, invitedEvents, confirmedEvents } = ownAmbassadorEvents;
        if (Array.isArray(acceptedEvents)) eventsArr = eventsArr.concat(acceptedEvents);
        if (Array.isArray(invitedEvents)) eventsArr = eventsArr.concat(invitedEvents);
        if (Array.isArray(confirmedEvents)) eventsArr = eventsArr.concat(confirmedEvents);
      } else if ([USER_ROLES.admin, USER_ROLES.superAdmin, USER_ROLES.eventCreator].includes(auth.claims.role)) {
        eventsArr = allEvents;
      }
    }
    return eventsArr.filter((ev) => {
      return [statuses.approved, statuses.booked, statuses.executed].includes(ev.status);
    });
  }, [allEvents, ownAmbassadorEvents, auth]);

  React.useEffect(() => {
    if (calendarEvents && calendarEvents.length) {
      setEvents(
        // eslint-disable-next-line array-callback-return
        calendarEvents.map((doc) => {
          if (searchKeyBusiness === "" || (doc.business && doc.business === searchKeyBusiness)) {
            return {
              ...doc,
              bgColor: eventColors[Math.floor(Math.random() * eventColors.length)],
              title: `${doc.accountName}, ${doc.streetAddress}, ${doc.city}, ${doc.state}`,
              allDay: false,
              start: convertToTimeZone((doc.eventStartTime as firebase.firestore.Timestamp).toDate(), {
                timeZone: doc.timezone,
              }),
              end: convertToTimeZone((doc.eventEndTime as firebase.firestore.Timestamp).toDate(), {
                timeZone: doc.timezone,
              }),
            };
          }
        })
      );
    }
  }, [calendarEvents, searchKeyBusiness]);

  return { events, searchKeyBusiness, onSearchKeyChange };
};
