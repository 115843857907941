/** Lib */
import React from "react";

/** Hooks */
import useGenerateEventColumns from "@/hooks/useGenerateEventColumns";
import useScrollToTop from "@/hooks/useScrollToTop";
import useSubscribeToAccounts from "@/hooks/useSubscribeToAccounts";
import useSubscribeToOwnEvents from "@/hooks/useSubscribeToOwnEvents";
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

/** Types */
import { Row } from "react-table";
import { TFirestoreEvent } from "@typings/events/events.types";
import {
  IShowFilterState,
  DASHBOARD_SHOW_INITIAL,
  DASHBOARD_SHOW_RESET
} from "../admin-dashboard/AdminDashboard.hooks";
import useLocalStorage from "@/hooks/useLocalStorage";

export default (props: Router.RouteComponentProps) => {
  /**
   * Props
   */
  const { history } = props;
  /**
   * Hooks
   */
  useScrollToTop();
  const { accounts } = useSubscribeToAccounts();
  const { ownEvents } = useSubscribeToOwnEvents();
  const ownUser = useSubscribeToOwnUser();
  const columns = useGenerateEventColumns();

  /**
   * State
   */
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showFilterState, setShowFilterState] = useLocalStorage<
    IShowFilterState
  >("EVENT_CREATOR_DASHBOARD_FILTERS", DASHBOARD_SHOW_INITIAL);

  /**
   * Handlers
   */
  const onSearchInputChange = React.useCallback(
    (ev: React.ChangeEvent<any>) => {
      setSearchTerm((ev.target as HTMLInputElement).value);
    },
    []
  );

  const onRowClick = React.useCallback(
    (val: Row<TFirestoreEvent>) => {
      const rowEvent = val.original;
      history.push(`/events/${rowEvent.id}`);
    },
    [history]
  );

  const onFilterCheckboxChange = React.useCallback(
    (filterType: keyof IShowFilterState) => (
      ev: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (ev.target.checked && !showFilterState[filterType]) {
        setShowFilterState({
          ...DASHBOARD_SHOW_RESET,
          [filterType]: ev.target.checked
        });
        setSearchTerm("");
      }
    },
    [setShowFilterState, showFilterState]
  );

  return {
    ownEvents,
    accounts,
    columns,
    onRowClick,
    onSearchInputChange,
    onFilterCheckboxChange,
    showFilterState,
    searchTerm,
    user: ownUser
  };
};
