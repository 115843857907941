import styled from "@emotion/styled";

export const StyledLoader = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  & > div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  display: block;
  font-size: 0;
  color: #fff;

  &.la-dark {
    color: #333;
  }

  & > div {
    display: inline-block;
    float: none;
    background-color: #ccc;
    border: 0 solid #ccc;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    max-height: 100%;
    max-width: 100%;
    border-radius: 100%;
    opacity: 0.5;
    -webkit-animation: ball-scale-pulse 2s infinite ease-in-out;
    -moz-animation: ball-scale-pulse 2s infinite ease-in-out;
    -o-animation: ball-scale-pulse 2s infinite ease-in-out;
    animation: ball-scale-pulse 2s infinite ease-in-out;
  }

  & > div:last-child {
    -webkit-animation-delay: -1s;
    -moz-animation-delay: -1s;
    -o-animation-delay: -1s;
    animation-delay: -1s;
  }

  &.la-sm {
    width: 16px;
    height: 16px;
  }

  &.la-sm > div {
    width: 16px;
    height: 16px;
  }

  &.la-2x {
    width: 64px;
    height: 64px;
  }

  &.la-2x > div {
    width: 64px;
    height: 64px;
  }

  &.la-3x {
    width: 96px;
    height: 96px;
  }

  &.la-3x > div {
    width: 96px;
    height: 96px;
  }

  /*
 * Animation
 */
  @-webkit-keyframes ball-scale-pulse {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes ball-scale-pulse {
    0%,
    100% {
      -moz-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -moz-transform: scale(1);
      transform: scale(1);
    }
  }
  @-o-keyframes ball-scale-pulse {
    0%,
    100% {
      -o-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes ball-scale-pulse {
    0%,
    100% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
`;
