/** Lib */
import React from 'react';

/** Types */
import { TInputProps } from './Checkbox.common';

export default (props: TInputProps) => {
    /**
     * Props
     */
    const { onFocus, onBlur } = props;
    /**
     * State
     */
    const [focused, setFocused] = React.useState(false);

    /**
     * Handlers
     */

    const onInputFocus = React.useCallback(
      (ev: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(ev);
        }
        setFocused(true);
      },
      [onFocus]
    );

    const onInputBlur = React.useCallback(
      (ev: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(ev);
        }
        setFocused(false);
      },
      [onBlur]
    );

    return {
        focused,
        onInputFocus,
        onInputBlur
    }
}