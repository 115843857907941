/** Lib */
import React from "react";

/** UI */
import { Container } from "@common/responsive";
import Card from '@/ui/common/card/Card.common';
import { Heading } from "rebass";
import ScheduleSingleEventForm from "@components/schedule-single-event/form/ScheduleSingleEventForm.component";

const ScheduleEvent: React.FC = props => {
  return (
    <Container pt={4}>
      <Heading variant="medium.center" mb={4}>
        Schedule New Event
      </Heading>
      <Card maxWidth="800px" p={4} margin="0 auto">
        <ScheduleSingleEventForm />
      </Card>
    </Container>
  );
};

export default ScheduleEvent;
