import { SET_AUTHENTICATED, SET_USER_DATA } from "./auth.constants";

/**
 * Action Creators (For use by store, _do not import these directly into components_)
 */

export const setAuthenticated: ActionCreator<AuthState, AuthAction<boolean>> = (
  state,
  dispatch
) => async (value: boolean) => {
  dispatch({
    type: SET_AUTHENTICATED,
    payload: value
  });
};

export const setUserData: ActionCreator<AuthState, AuthAction<any>> = (
  state,
  dispatch
) => async (value: any) => {
  return dispatch({
    type: SET_USER_DATA,
    payload: value
  });
};
