/**
 * * ACTION CONSTANTS
 */
export const SET_EVENTS = "SET_EVENTS";
export const SET_OWN_AMBASSADOR_EVENTS = "SET_OWN_AMBASSADOR_EVENTS";
export const SET_OWN_EVENTS = "SET_OWN_EVENTS";
export const SET_SUBSCRIBED_TO_ALL = "SET_SUBSCRIBED_TO_ALL";
export const SET_SUBSCRIBED_TO_OWN = "SET_SUBSCRIBED_TO_OWN";
export const SET_SUBSCRIBED_TO_OWN_AMBASSADOR =
  "SET_SUBSCRIBED_TO_OWN_AMBASSADOR";

export const EVENT_STATUSES = [
  "requested",
  "approved",
  "denied",
  "booked",
  "executed",
  "cancelled"
];

export const REQUIRED_EXCEL_HEADERS = [
  "Status",
  "Funding Source",
  "Requesters Name",
  "Email Address",
  "Phone Number",
  "WOD Location",
  "Event Date",
  "Event Start Time",
  "Event End Time",
  "Premise",
  "Account Name",
  "Street Address",
  "City",
  "State",
  "Zip Code",
  "Day of Event Account Contact",
  "Brand",
  "Activation Plus Up",
  "Type of Event / Promotion",
  "Event Notes"
];

export const OPTIONAL_EXCEL_HEADERS = ["Promo Team", "Bar Spend"];

export const FIREBASE_EVENTS_RESOURCE_NAME = "Events";
export const FIREBASE_EVENTS_RESOURCE_NAME_SINGLE = "Event";

export const FIREBASE_EVENT_RECAP_RESOURCE_NAME = "EventRecaps";
export const FIREBASE_EVENT_RECAP_RESOURCE_NAME_SINGLE = "EventRecap";

export const FIREBASE_EVENTS_PROTECTED_NAME = (id: string) =>
  `Events/${id}/Protected`;

export const EVENT_INVITATION_RESPONSES = {
  accept: "accept",
  decline: "decline"
};

type TInvitationKeys = keyof typeof EVENT_INVITATION_RESPONSES;
export type TInvitationResponses = typeof EVENT_INVITATION_RESPONSES[TInvitationKeys]; //  "accept" | "decline"

export const TIMEZONE_NAMES = {
  "America/New_York": "Eastern",
  "America/Detroit": "Eastern",
  "America/Kentucky/Louisville": "Eastern",
  "America/Kentucky/Monticello": "Eastern",
  "America/Indiana/Indianapolis": "Eastern",
  "America/Indiana/Vincennes": "Eastern",
  "America/Indiana/Winamac": "Eastern",
  "America/Indiana/Marengo": "Eastern",
  "America/Indiana/Petersburg": "Eastern",
  "America/Indiana/Vevay": "Eastern",
  "America/Chicago": "Central",
  "America/Indiana/Tell_City": "Central",
  "America/Indiana/Knox": "Central",
  "America/Menominee": "Central",
  "America/North_Dakota/Center": "Central",
  "America/North_Dakota/New_Salem": "Central",
  "America/North_Dakota/Beulah": "Central",
  "America/Denver": "Mountain",
  "America/Boise": "Mountain",
  "America/Phoenix": "Mountain",
  "America/Los_Angeles": "Pacific",
  "America/Anchorage": "Alaska",
  "America/Juneau": "Alaska",
  "America/Sitka": "Alaska",
  "America/Metlakatla": "Alaska",
  "America/Yakutat": "Alaska",
  "America/Nome": "Alaska",
  "America/Adak": "UTC-10",
  "Pacific/Honolulu": "Hawaii"
};

export type TTimezones = keyof typeof TIMEZONE_NAMES;
