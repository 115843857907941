/** Lib */
import React from "react";

/** Hooks */
import useScrollToTop from "@/hooks/useScrollToTop";

/** Stores */
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/** UI */
import Calendar from "@components/calendar/Calendar.component";
import { Container } from "@/ui/common/responsive";
import { Flex } from "rebass";
import Loader from "@/ui/common/loader/Loader.common";

const CalendarScreen: React.FC<Router.RouteComponentProps> = props => {
  useScrollToTop();
  const {
    state: { user: auth }
  } = useAuthStore();
  if (!auth) {
    return (
      <Container>
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      </Container>
    );
  }
  return (
    <>
      <Calendar {...props} auth={auth} />
    </>
  );
};

export default CalendarScreen;
