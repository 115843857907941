import React from "react";
import { Card as CardComponent, CardProps } from "rebass";

/** Types */
import { InterpolationWithTheme } from '@emotion/core';


const Card: React.FC<CardProps & {
  css?: InterpolationWithTheme<any>;
}> = props => {
  const { sx, ...rest } = props;
  return (
    <CardComponent
      {...rest}
      sx={{
        // boxShadow: "large",\
        mb: 3,
        backgroundColor: "white",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "1px",
        borderColor: "lightGray",
        ...sx
      }}
    >
      {props.children}
    </CardComponent>
  );
};

export default Card;
