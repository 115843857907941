/**
 * * Imports
 */

// * Imports: Hooks
import { useStore } from "@stores/auth/auth.store";

export default (props: Router.RouteComponentProps<{ id: string }>) => {
  const {
    state: { user },
  } = useStore();

  return {
    user,
  };
};
