/** Lib */
import React from "react";

/** Services */
import UserService from "@services/users/users.service";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";

/** Store */
import { useStore as useUsersStore } from "@stores/users/users.store";
import { useStore as useAuthStore } from "@stores/auth/auth.store";

export default () => {
  const {
    state: { ownUser, subscribedToOwn },
    actions: { setOwn, setSubscribedToOwn }
  } = useUsersStore();

  const {
    state: { user }
  } = useAuthStore();

  const userId = user?.uid;

  React.useEffect(() => {
    if (!userId) {
      setSubscribedToOwn(false);
      setOwn(null);
    } else if (!subscribedToOwn && userId) {
      UserService.subscribeToUserById(userId, doc => {
        const data = doc.data() as TUserFirestore;
        setOwn(data);
        setSubscribedToOwn(true);
      });
    }
    // eslint-disable-next-line
  }, [subscribedToOwn, userId]);

  return ownUser;
};
