/** Lib */
import React from "react";

/** Stores */
import { useStore } from "@stores/users/users.store";

/** Services */
import UsersService from "@services/users/users.service";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";

export default () => {
  const { state, actions } = useStore();

  const { subscribedToAll } = state;

  React.useEffect(() => {
    if (!subscribedToAll) {
      UsersService.subscribeToAllUsers((users) => {
        const data = users.docs.map(doc => doc.data()) as TUserFirestore[];
        actions.setAll(data);
        actions.setSubscribedToAll(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToAll]);

  return state;
};
