/**
 *
 * * IMPORTS
 *
 */

/**
 * * Lib
 */
import * as yup from "yup";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { capitalCase } from "change-case";

/**
 * * Constants
 */
import { EVENT_STATUSES } from "@constants/events.constants";

/**
 * * Types
 */
import {
  TFirestoreEvent,
  TFirestoreSheet,
  TExcelEventParsed,
} from "@typings/events/events.types";

/**
 * * Utils
 */
import convertExcelDate from "@/util/convertExcelDate";
import { startOfDay } from "date-fns";

/**
 * * Custom `Yup` validators
 */

/**
 * @function excelDateWithinGivenDays
 * `yup` method for checking if value is in array
 */
yup.addMethod(yup.number, "excelDateOutsideGivenDays", function (
  givenDays: number
) {
  return this.test(
    "excelDateOutsideGivenDays",
    `Date must be at least ${givenDays} days in the future.`,
    function (value: number) {
      const evDate = convertExcelDate(value, 0);
      const dateDiff = differenceInCalendarDays(
        startOfDay(evDate),
        startOfDay(new Date())
      );
      return dateDiff >= givenDays;
    }
  );
});
/**
 * @function dateWithinGivenDays
 * `yup` method for checking if value is in array
 */
yup.addMethod(yup.number, "dateWithinGivenDays", function (givenDays: number) {
  return this.test(
    "dateWithinGivenDays",
    `Date must be at least ${givenDays} days in the future.`,
    function (value: Date) {
      const dateDiff = differenceInCalendarDays(
        startOfDay(value),
        startOfDay(new Date())
      );
      return dateDiff >= givenDays;
    }
  );
});

/**
 * * Custom formatting for `Yup` required error.
 */
yup.setLocale({
  mixed: {
    required: (val) => `${capitalCase(val.path as string)} is required.`,
  },
});

export const excelEventParsedSchema = yup
  .object()
  .shape({
    accountName: yup.string().required(),
    activationPlusUp: yup.string().required(),
    brand: yup.string().required(),
    businessEntity: yup.string().required(),
    city: yup.string(),
    accountSpend: yup.string(),
    dayofEventAccountContact: yup.string(),
    emailAddress: yup.string(),
    eventDate: yup.number().excelDateOutsideGivenDays(10).required(),
    eventEndTime: yup.number().required(),
    eventNotes: yup.string(),
    eventStartTime: yup.number().required(),
    fundingSource: yup.string().required(),
    phoneNumber: yup.string(),
    premise: yup.string().required(),
    requestersName: yup.string(),
    state: yup.string().required(),
    status: yup
      .string()
      .matches(/^requested$/i, 'status must be "requested"')
      .required(),
    streetAddress: yup.string().required(),
    typeofEventPromotion: yup.string(),
    wodLocation: yup.string(),
    zipCode: yup
      .number()
      .required()
      .test(
        "len",
        "Zip Code must be exactly 5 digits",
        (val) => val && val.toString().length === 5
      ),
    timezone: yup.string(),
  })
  .noUnknown(true, (params) => {
    return `Only known columns can be used.`;
  });

export const excelEventRawSchema = yup
  .object()
  .shape({
    "Account Name": yup.string().required(),
    "Account Spend": yup.string(),
    "Activation Plus Up": yup.string().required(),
    Brand: yup.string().required(),
    City: yup.string(),
    "Day of Event Account \\nContact": yup.string().required(),
    "Email Address": yup.string().required(),
    "Event Date": yup.number().required(),
    "Event End Time": yup.number().required(),
    "Event Notes": yup.string(),
    "Event Start Time": yup.number().required(),
    "Funding Source": yup.string().required(),
    "Phone Number": yup.string().required(),
    Premise: yup.string().required(),
    "Requesters Name": yup.string(),
    State: yup.string().required(),
    status: yup
      .string()
      .matches(/^requested$/i, 'status must be "requested"')
      .required(),
    "Street Address": yup.string().required(),
    "Type of Event / Promotion": yup.string(),
    "WOD Location": yup.string(),
    "Zip Code": yup
      .number()
      .required()
      .test(
        "len",
        "Zip Code must be exactly 5 digits",
        (val) => val && val.toString().length === 5
      ),
    "Business Entity": yup.string().required()
  })
  .noUnknown(true, (params) => {
    return `Only known columns can be used.`;
  });

export const firestoreEventSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    accountName: yup.string().required(),
    accountSpend: yup.string(),
    activationPlusUp: yup.string().required(),
    createdBy: yup.string(),
    brand: yup.string().required(),
    city: yup.string().required(),
    dayofEventAccountContact: yup.string(),
    emailAddress: yup.string(),
    eventEndTime: yup.mixed().required(),
    eventNotes: yup.string(),
    eventStartTime: yup.mixed().required(),
    fundingSource: yup.string().required(),
    phoneNumber: yup.string(),
    premise: yup.string().required(),
    requestersName: yup.string(),
    state: yup.string().required(),
    status: yup.string().oneOf(EVENT_STATUSES),
    streetAddress: yup.string().required(),
    typeofEventPromotion: yup.string(),
    wodLocation: yup.string(),
    zipCode: yup
      .string()
      .matches(/^\d{5}$/, "Zip Code must be a 5 digit number."),
    // .required("Zip Code is required."),
    timezone: yup.string(),
    invitedUsers: yup.array(yup.string()),
    acceptedInviteUsers: yup.array(yup.string()),
    declinedInviteUsers: yup.array(yup.string()),
    declinedByAdminUsers: yup.array(yup.string()),
    confirmedByAdminUsers: yup.array(yup.string()),
    designatedAmbassador: yup.string(),
    business: yup.string()
  })
  .noUnknown(true, (params) => {
    return `Only known columns can be used.`;
  });

export const firestoreEventUpdateFormSchema = yup.object().shape({
  id: yup.string().required(),
  createdBy: yup.string().required(),
  accountName: yup.string().required(),
  accountSpend: yup.string(),
  activationPlusUp: yup.string().required(),
  brand: yup.string().required(),
  business: yup.string().required(),
  city: yup.string().required(),
  dayofEventAccountContact: yup.string(),
  eventEndTime: yup.date().required(),
  eventNotes: yup.string(),
  eventStartTime: yup.date().required(),
  fundingSource: yup.string().required(),
  emailAddress: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
      "Email invalid."
    )
    .required(),
  phoneNumber: yup
    .string()
    .matches(
      /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
      "Please use (xxx) xxx-xxxx format"
    )
    .required("Phone Number is required"),
  premise: yup.string().required(),
  requestersName: yup.string(),
  state: yup.string().required(),
  status: yup.string().required(),
  streetAddress: yup.string().required(),
  typeofEventPromotion: yup.string(),
  wodLocation: yup.string(),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, "Zip Code must be a 5 digit number.")
    .required("Zip Code is required."),
  timezone: yup.string(),
});
export const firestoreEventFormSchema = yup.object().shape({
  accountName: yup.string().required(),
  accountSpend: yup.string(),
  activationPlusUp: yup.string().required(),
  brand: yup.string().required(),
  city: yup.string().required(),
  dayofEventAccountContact: yup.string(),
  eventEndTime: yup.date().required(),
  eventNotes: yup.string(),
  eventStartTime: yup.date().required(),
  fundingSource: yup.string().required(),
  business: yup.string(),
  emailAddress: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
      "Email invalid."
    )
    .required(),
  phoneNumber: yup
    .string()
    .matches(
      /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
      "Please use (xxx) xxx-xxxx format"
    )
    .required("Phone Number is required"),
  premise: yup.string().required(),
  requestersName: yup.string(),
  state: yup.string().required(),
  status: yup.string().required(),
  streetAddress: yup.string().required(),
  typeofEventPromotion: yup.string(),
  wodLocation: yup.string(),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, "Zip Code must be a 5 digit number.")
    .required("Zip Code is required."),
  timezone: yup.string(),
});

export const firestoreSheetSchema = yup.object({
  name: yup.string(),
  data: yup.array(firestoreEventSchema),
});

export const firestoreEventProtectedSchema = yup.object().shape({
  budget: yup
    .string()
    .matches(
      /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
      "Format: 100 or 100.00"
    )
    .required("Budget Required."),
  actualBarSpend: yup
    .string()
    .matches(
      /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
      "Format: 100 or 100.00"
    )
    .required("Actual Bar Spend Required."),
  brandAmbassadorCount: yup
    .string()
    .matches(/^[1-9][0-9]?$|^20$/, "1 through 20")
    .required("How many Brand Ambassadors required to book event?"),
});

export const firestoreUserAcceptedEvent = yup.object().shape({
  id: yup.string().required(),
  createdAt: yup.mixed().required(),
});

export const excelEventsRawArraySchema = yup.array(excelEventRawSchema);
export const excelEventsParsedArraySchema = yup.array(excelEventParsedSchema);
export const firestoreEventsArraySchema = yup.array(firestoreEventSchema);
export const firestoreEventsSheetArraySchema = yup.array(firestoreSheetSchema);

export const validateFirestoreEventsArray = (eventArr: TFirestoreEvent[]) => {
  return firestoreEventsArraySchema.validateSync(eventArr);
};

export const validateFirestoreSheetArray = (sheets: TFirestoreSheet[]) => {
  return firestoreEventsSheetArraySchema.validateSync(sheets);
};

export const validateExcelEventsRawArray = (eventArr: TExcelEventParsed[]) => {
  return excelEventsRawArraySchema.validateSync(eventArr);
};

export const validateExcelEventsParsedArray = (
  eventArr: TExcelEventParsed[]
) => {
  return excelEventsParsedArraySchema.validateSync(eventArr);
};

export const validateExcelRawEvent = (event: TExcelEventParsed) => {
  return excelEventRawSchema.validateSync(event);
};

export const validateExcelParsedEvent = (event: TExcelEventParsed) => {
  return excelEventParsedSchema.validateSync(event);
};

export const validateFirestoreEvent = (event: TExcelEventParsed) => {
  return firestoreEventSchema.validateSync(event);
};
