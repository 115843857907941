/** Lib */
import React from "react";

/** Services */
import Users from "@services/users/users.service";

/** Types */
import { IUserUpdate } from "@/typings/users/users.types";

export default (props: Router.RouteComponentProps<TLocationParamsWithId>) => {
  const [userState, setUserState] = React.useState<IUser | undefined>();
  const [formValues, setFormValues] = React.useState<
    { [K in keyof IUserUpdate]: any }
  >();

  const user: IUser | undefined =
    userState || (props.location.state as IUser | undefined);
  const { id: userId } = props.match.params;

  const getUser = React.useCallback(() => {
    Users.getById(userId)
      .then(res => {
        setUserState({ ...(res.data() as IUser), id: res.id });
      })
      .catch(err => {
        console.error(err);
      });
  }, [userId]);
  /**
   * Get User data by id if user not passed in location state.
   */
  React.useEffect(() => {
    if (!props.location.state && userId) {
      getUser();
    }
  }, [getUser, userId, props.location.state]);
  /**
   * Disable user button click handler.
   */
  const onDisableClick = React.useCallback(
    (id: string) => async (ev: React.MouseEvent) => {
      try {
        await Users.updateById(id, { active: false });
        getUser();
      } catch (err) {
        console.error("Failed to Disable user: ", err);
      }
    },
    [getUser]
  );

  /**
   * Approve user button click handler.
   */
  const onApproveClick = React.useCallback(
    (id: string) => async (ev: React.MouseEvent) => {
      try {
        await Users.updateById(id, { active: true });
        getUser();
      } catch (err) {
        console.error("Failed to Approve user: ", err);
      }
    },
    [getUser]
  );

  /**
   * On ambassador star level change
   */
  const onStarChange = React.useCallback(
    (ev: React.ChangeEvent<any>) => {
      setFormValues({
        ...formValues,
        stars: Number((ev.target as HTMLSelectElement).value)
      });
    },
    [formValues]
  );

  /**
   * On update user
   */
  const onUpdateUser = React.useCallback(
    async (ev: React.MouseEvent) => {
      try {
        if (formValues) {
          await Users.updateById(userId, formValues);
          setFormValues(undefined);
          getUser();
        }
      } catch (err) {
        console.error("Failed to update user", err);
      }
    },
    [formValues, userId, getUser]
  );

  const onFirstNameChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
        ...formValues,
        firstName: (ev.target as HTMLInputElement).value
      });
    },
    [formValues]
  );

  return {
    user,
    onDisableClick,
    onApproveClick,
    onStarChange,
    onFirstNameChange,
    onUpdateUser,
    formValues
  };
};
