/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import { statuses } from "@/stores/events/events.constants";
import { IOwnEvents } from "@/stores/events/events.types";

export default (events: TFirestoreEvent[], searchKeyBusiness: string): IOwnEvents => {
  return events.reduce(
    (eventsAcc, event) => {
      if (!searchKeyBusiness || event.business === searchKeyBusiness) {
        if (event.status.toLowerCase() === statuses.requested) {
          return {
            ...eventsAcc,
            requested: [...eventsAcc.requested, event],
          };
        }
        if (event.status.toLowerCase() === statuses.approved) {
          return {
            ...eventsAcc,
            approved: [...eventsAcc.approved, event],
          };
        }
        if (event.status.toLowerCase() === statuses.booked) {
          return {
            ...eventsAcc,
            booked: [...eventsAcc.booked, event],
          };
        }
        if (event.status.toLowerCase() === statuses.denied) {
          return {
            ...eventsAcc,
            denied: [...eventsAcc.denied, event],
          };
        }
        if (event.status.toLowerCase() === statuses.executed) {
          return {
            ...eventsAcc,
            executed: [...eventsAcc.executed, event],
          };
        }
        if (event.status.toLowerCase() === statuses.cancelled) {
          return {
            ...eventsAcc,
            cancelled: [...eventsAcc.cancelled, event],
          };
        }
      }
      return eventsAcc;
    },
    {
      requested: [],
      approved: [],
      booked: [],
      denied: [],
      executed: [],
      cancelled: [],
    } as IOwnEvents
  );
};
