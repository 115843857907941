/** Lib */
import React from "react";

/** UI */
import { Box, Heading, Text, Button } from "rebass";

/** Types */
import { IAccountUploadResponse } from "@screens/add-accounts-excel/AddAccountsExcel.types";

export interface IAddAccountResultsProps {
  uploadResponse: IAccountUploadResponse;
  resetForm: () => void;
}

const AddAccountsUploadResults: React.FC<IAddAccountResultsProps> = props => {
  const { uploadResponse, resetForm } = props;
  return (
    <Box>
      <Heading as="h3" variant="medium" mb={3}>
        {!!uploadResponse.added.length ? (
          <Text as="span" color="success">
            Success!
          </Text>
        ) : (
          <Text as="span">No Accounts Added</Text>
        )}
      </Heading>
      <Text mb={3}>
        {uploadResponse.added.length} Accounts Added.{" "}
        {uploadResponse.duplicates.length} were duplicates.
      </Text>
      <Button type="button" onClick={resetForm}>
        Go Back
      </Button>
    </Box>
  );
};

export default AddAccountsUploadResults;
