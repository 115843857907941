import React from "react";

import { Box, Flex, Button, Image, Text } from "rebass";
import hooks from "./LoginForm.hooks";
import Card from "@/ui/common/card/Card.common";
import Input from "@common/form-elements/input/Input.common";
import Loader from "@/ui/common/loader/Loader.common";

const LoginForm: React.FC<Router.RouteComponentProps> = props => {
  const {
    emailRef,
    passRef,
    onLoginFormSubmit,
    onEmailSignInClick,
    signInWithEmail,
    authCode,
    authenticated,
    loading
  } = hooks(props);

  if (authenticated !== false) {
    return null;
  }

  return (
    <Card maxWidth={510} mx="auto" pt={3}>
      <Box
        as="form"
        maxWidth="450px"
        mx="auto"
        onSubmit={onLoginFormSubmit}
        p={3}
      >
        <Flex justifyContent="center" mb={5}>
          <Image
            src="/mtwo-event-logo.jpg"
            maxWidth="300px"
            width="100%"
            height="auto"
            alignSelf="center"
          />
        </Flex>
        {!loading ? (
          <>
            {!signInWithEmail ? (
              <>
                <Box mb={3}>
                  <Box mb={2}>
                    <Input
                      ref={emailRef}
                      id="email"
                      name="email"
                      placeholder="email"
                      label="Email"
                    />
                  </Box>
                  <Box mb={2}>
                    <Input
                      ref={passRef}
                      label="password"
                      id="password"
                      name="password"
                      placeholder="password"
                      type="password"
                    />
                  </Box>
                </Box>
                <Flex
                  mb={5}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  flexDirection="column"
                >
                  <Button
                    type="submit"
                    mb={2}
                    sx={{
                      cursor: "pointer"
                    }}
                  >
                    Login
                  </Button>
                  <Text>
                    <Button
                      type="button"
                      variant="anchor"
                      onClick={onEmailSignInClick}
                    >
                      Or login with just your email.
                    </Button>
                  </Text>
                  {authCode && <Text color="danger">{authCode}</Text>}
                </Flex>
              </>
            ) : (
              <>
                <Box mb={2}>
                  <Input
                    ref={emailRef}
                    id="email"
                    name="email"
                    placeholder="email"
                    label="Email"
                  />
                </Box>
                <Flex
                  mb={5}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  flexDirection="column"
                >
                  <Button
                    type="submit"
                    mb={2}
                    sx={{
                      cursor: "pointer"
                    }}
                  >
                    Send Login Link to Email
                  </Button>
                  <Text>
                    <Button
                      type="button"
                      variant="anchor"
                      onClick={onEmailSignInClick}
                    >
                      Or login with your email/password.
                    </Button>
                  </Text>
                  {authCode && <Text color="danger">{authCode}</Text>}
                </Flex>
              </>
            )}
          </>
        ) : (
          <Loader className="la-3x" m="0 auto" />
        )}
      </Box>
    </Card>
  );
};

export default LoginForm;
