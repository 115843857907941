/** Lib */
import React from "react";

/** Types */
import { TSideNavigationProps } from "./SideNavigation.component";

export default (props: TSideNavigationProps & Router.RouteComponentProps) => {
  const [open, setOpen] = React.useState(false);
  const [openDisabled, setOpenDisabled] = React.useState(false);

  const {
    location: { pathname },
    setMobileMenuExpanded
  } = props;

  React.useEffect(() => {
    setOpen(false);
    setOpenDisabled(true);
    setTimeout(() => {
      setOpenDisabled(false);
    }, 1000);
  }, [pathname]);

  const onMouseMove = React.useCallback(() => {
    if (!open && !openDisabled) {
      setOpen(true);
    }
  }, [open, openDisabled]);

  const onMouseLeave = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onMenuItemLinkClick = React.useCallback(
    (ev: React.MouseEvent<any>) => {
      if (window.innerWidth < 992) {
        setMobileMenuExpanded(false);
      }
    },
    [setMobileMenuExpanded]
  );

  return { open, onMouseMove, onMouseLeave, onMenuItemLinkClick };
};
