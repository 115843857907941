export const menuItemStyles = {
  marginBottom: "12px"
};

export const linkStyles = {
  display: "block",
  width: "100%",
  cursor: "pointer",
  textDecoration: "none",
  transition: "color 150ms ease-in-out",
  "&:hover": {
    color: "white",
    svg: {
      fill: "white"
    }
  }
};

export const subLinkStyles = {
  transition: "color 150ms ease-in-out",
  textDecoration: "none",
  color: "slateGray",

  "&:hover": {
    color: "white"
  }
};
