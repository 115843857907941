const roles = {
  admin: "admin",
  superAdmin: "superAdmin",
  eventCreator: "eventCreator",
  brandAmbassador: "brandAmbassador"
};

type TRoles = typeof roles;
type TUserRoles = { [K in keyof TRoles]: AuthUserRoles };

export const USER_ROLES: TUserRoles = {
  admin: "admin",
  superAdmin: "superAdmin",
  eventCreator: "eventCreator",
  brandAmbassador: "brandAmbassador"
};

export const FIREBASE_USERS_RESOURCE_NAME = "UserInfo";
export const FIREBASE_USERS_RESOURCE_NAME_SINGLE = "User";
