/** Lib */
import React from "react";

/** Hooks */
import hooks from "./Profile.hooks";

/** UI */
import { Box, Heading } from "rebass";
import { Container } from "@/ui/common/responsive";
import Card from "@/ui/common/card/Card.common";
import AmbassadorProfileForm from "@/ui/components/ambassador-profile-form/AmbassadorProfileForm.component";
import AdminAndEventCreatorProfileForm from "@/ui/components/admin-and-ec-profile-form/AdminAndEventCreatorProfileForm.component";

/** Constants */
import { USER_ROLES } from "@/stores/auth/auth.constants";
import EditAvatar from "@/ui/components/edit-avatar/EditAvatar.component";

const ProfileScreen = () => {
  const { onSubmitError, onSubmitSuccess, ownUser } = hooks();
  return (
    <Container pt={4}>
      <Card p={3}>
        <Box
          className="profile-title"
          py={2}
          sx={{
            borderStyle: "solid",
            borderWidth: "0 0 1px 0",
            borderColor: "lightGray"
          }}
        >
          <Box p={2}>
            <Heading variant="small">Your Profile</Heading>
          </Box>
        </Box>
        <Box p={3}>
          {ownUser && (
            <Box mb={4}>
              <EditAvatar user={ownUser} />
            </Box>
          )}
          {ownUser?.role === USER_ROLES.brandAmbassador && (
            <AmbassadorProfileForm
              onSubmitSuccess={onSubmitSuccess}
              onSubmitError={onSubmitError}
              user={ownUser}
            />
          )}
          {ownUser && ownUser.role !== USER_ROLES.brandAmbassador && (
            <AdminAndEventCreatorProfileForm
              onSubmitSuccess={onSubmitSuccess}
              onSubmitError={onSubmitError}
              user={ownUser}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
};

export default ProfileScreen;
