/** Lib */
import React from "react";

/** Hooks */
import hooks from "./EventDetails.hooks";

/** UI */
import { Heading, Flex, Button, Box, Text } from "rebass";
import { Container } from "@common/responsive";
import EventDetailsForm from "@components/event-details/form/EventDetailsForm.component";
import Card from "@/ui/common/card/Card.common";
import Loader from "@/ui/common/loader/Loader.common";
import { Link } from "react-router-dom";
import { statuses } from "@/stores/events/events.constants";
import { format } from "date-fns";
import { TIMEZONE_NAMES, TTimezones } from "@/constants/events.constants";
import { USER_ROLES } from "@/constants/users.constants";

const EventDetailsScreen: React.FC<Router.RouteComponentProps<
  TLocationParamsWithId
>> = (props) => {
  const {
    event,
    eventProtected,
    isPastEvent,
    user,
    onCancelEventClick,
    eventRecap,
  } = hooks(props);

  if (event && event.status === statuses.cancelled) {
    return (
      <Container pt={4}>
        <Heading color="danger">Event has been cancelled.</Heading>
      </Container>
    );
  }
  return (
    <Container pt={4}>
      <Flex
        justifyContent="space-between"
        maxWidth="800px"
        margin="0 auto"
        flexWrap="wrap"
        mb={4}
      >
        {event && (
          <Box mb="25px">
            <Heading variant="small" mb={1}>
              {event.accountName}, {event.brand} (status: {event.status})
            </Heading>
            <Text>
              {format(event.eventStartTime.toDate(), "E, M/dd h:mm a")} -{" "}
              {format(event.eventEndTime.toDate(), "h:mm a")}
              <br />
              {TIMEZONE_NAMES[event.timezone as TTimezones]}
              {" Time"}
            </Text>
          </Box>
        )}
        <Flex
          sx={{
            button: {
              mx: 2,
            },
          }}
          alignItems="flex-start"
        >
          {event && user && (
            <>
              {event.status === statuses.executed &&
                ([USER_ROLES.admin, USER_ROLES.superAdmin].includes(
                  user.claims.role
                ) ||
                  (user.claims.role === USER_ROLES.eventCreator &&
                    eventRecap) ||
                  event.confirmedByAdminUsers.includes(user.uid)) && (
                  <Link to={`/event-recap-form/${props.match.params.id}`}>
                    <Button type="button">Event Recap</Button>
                  </Link>
                )}
              {event.status === statuses.requested &&
                [USER_ROLES.admin, USER_ROLES.superAdmin].includes(
                  user.claims.role
                ) &&
                !isPastEvent && (
                  <Link
                    to={{
                      pathname: `/approve-event/${props.match.params.id}`,
                      state: event,
                    }}
                  >
                    <Button type="button">Approve Event</Button>
                  </Link>
                )}
              {[statuses.approved, statuses.booked].includes(event.status) &&
                [USER_ROLES.admin, USER_ROLES.superAdmin].includes(
                  user.claims.role
                ) && (
                  <Link to={`/staff-event/${props.match.params.id}`}>
                    <Button type="button">Staff Event</Button>
                  </Link>
                )}
              {[statuses.approved, statuses.booked].includes(event.status) &&
                [USER_ROLES.admin, USER_ROLES.superAdmin].includes(
                  user.claims.role
                ) && (
                  // <Link to={`/cancel-event/${props.match.params.id}`}>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={onCancelEventClick}
                    display="inline"
                  >
                    Cancel Event
                  </Button>
                  // </Link>
                )}
            </>
          )}
        </Flex>
      </Flex>

      {event && eventProtected ? (
        <Card maxWidth="800px" p={4} margin="0 auto">
          <EventDetailsForm
            {...props}
            theEvent={event}
            eventProtected={eventProtected}
          />
        </Card>
      ) : event ? (
        <Card maxWidth="800px" p={4} margin="0 auto">
          <EventDetailsForm {...props} theEvent={event} />
        </Card>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      )}
    </Container>
  );
};

export default EventDetailsScreen;
