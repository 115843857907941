/** Lib */
import React from "react";

/** Stores */
import { useStore } from "@stores/accounts/accounts.store";

/** Services */
import AccountsService from "@services/accounts/accounts.service";

/** Types */
import { TAccountFirestore } from "@typings/accounts/accounts.types";


export default () => {
  const {
    state,
    actions
  } = useStore();

  const { subscribedToAll } = state;

  React.useEffect(() => {
    if (!subscribedToAll) {
      AccountsService.subscribeToAll(snapshot => {
        const data = snapshot.docs.map(doc => doc.data()) as TAccountFirestore[];
        actions.setAccounts(data);
        actions.setSubscribedToAll(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToAll]);

  return state;
};
