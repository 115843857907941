/** Constants */
import { SET_BUSINESS, SET_SUBSCRIBED_TO_ALL } from "./business.constants";

/** Types */
import { TBusinessFirestore } from "@typings/business/business.types";
import { TBusinessAction, IBusinessState } from "./business.types";

/**
 * Action Creators (For use by store, _do not import these directly into components_)
 */

export const setBusiness: ActionCreator<
IBusinessState,
TBusinessAction<TBusinessFirestore[]>
> = (state, dispatch) => (value: TBusinessFirestore[]) => {
  dispatch({
    type: SET_BUSINESS,
    payload: value
  });
};

export const setSubscribedToAll: ActionCreator<
IBusinessState,
TBusinessAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_ALL,
    payload: value
  });
};