/** Store dependencies */
import * as actionCreators from "./accounts.actions";
import accountsReducer from "./accounts.reducer";
import createStore from "@util/createStore";

/** Types */
import {
  TAccountsAction,
  IAccountsState,
  IAccountsActions
} from "./accounts.types";

export const ACCOUNTS_INITIAL_STATE = {
  accounts: [],
  subscribedToAll: false
};

const { StoreContext, StoreContextProvider, useStore } = createStore<
  IAccountsState,
  TAccountsAction<any>,
  IAccountsActions
>(ACCOUNTS_INITIAL_STATE, accountsReducer, actionCreators);

export {
  StoreContext as AccountsContext,
  StoreContextProvider as AccountsContextProvider,
  useStore
};
