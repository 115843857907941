/** Lib */
import React from "react";
import AvatarEditor from "react-avatar-editor";

/** UI */
import { Box, Image, Button } from "rebass";
import { IconEdit } from "@/ui/common/icon/Icons.common";

/** Hooks */
import hooks from "./EditAvatar.hooks";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";
import Loader from "@/ui/common/loader/Loader.common";

const EditAvatar: React.FC<{ user: TUserFirestore }> = props => {
  const { user } = props;
  const {
    newAvatar,
    avatarEdit,
    avatarRef,
    avatarScale,
    loading,
    getRootProps,
    getInputProps,
    onScaleChange,
    onAvatarSave,
    onAvatarCancel
  } = hooks(props);
  return (
    <>
      <Box mb={3}>
        {newAvatar && avatarEdit ? (
          <Box mb={3}>
            <AvatarEditor
              ref={avatarRef as any}
              image={newAvatar}
              width={200}
              height={200}
              border={10}
              borderRadius={100}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={avatarScale}
              rotate={0}
            />
            <Box>
              <input
                type="range"
                min="1"
                max="5"
                step="0.1"
                value={avatarScale}
                onChange={onScaleChange}
              />
            </Box>
            <Button
              onClick={onAvatarSave}
              variant={loading ? "primary.disabled" : "primary"}
              disabled={loading}
            >
              {loading ? (
                <Loader height="15px" width="15px" m="0 auto" />
              ) : (
                "Save"
              )}
            </Button>
            {!loading && (
              <Button variant="secondary" onClick={onAvatarCancel} ml={2}>
                Cancel
              </Button>
            )}
          </Box>
        ) : (
          <Box
            className="profile-avatar"
            sx={{
              display: "inline-block",
              position: "relative",
              lineHeight: 0,
              width: "125px",
              height: "125px"
            }}
          >
            <Image
              src={user.avatar}
              sx={{ width: "125px", borderRadius: "50%" }}
            />
            <Button
              {...(getRootProps() as any)}
              variant="blank"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                p: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                svg: { fill: "white", width: "20px" }
              }}
            >
              <IconEdit />
              <input {...getInputProps()} />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EditAvatar;
