import * as actionCreators from './auth.actions';
import authReducer from './auth.reducer';
import createStore from '@util/createStore';

export const AUTH_INITIAL_STATE = {
  authenticated: undefined,
  user: null,
};

const { StoreContext, StoreContextProvider, useStore } = createStore<AuthState, AuthAction<any>, AuthActions>(
  AUTH_INITIAL_STATE,
  authReducer,
  actionCreators,
);

export { StoreContext as AuthContext, StoreContextProvider as AuthContextProvider, useStore };
