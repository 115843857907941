/** Constants */
import {
  SET_BUSINESS,
  SET_SUBSCRIBED_TO_ALL
} from "./business.constants";

/** Types */
import { IBusinessState, TBusinessAction } from './business.types';

export default (state: IBusinessState, action: TBusinessAction<any>) => {
  switch (action.type) {
    case SET_BUSINESS: {
      return {
        ...state,
        business: action.payload
      };
    }
    case SET_SUBSCRIBED_TO_ALL: {
      return {
        ...state,
        subscribedToAll: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
