/** Lib */
import React from "react";

/** UI */
import { Box, Heading, Text } from "rebass";

/** Styles */
import { borderStyles } from "./ScheduleEventsValidationResults.styles";

/** Types */
import { IScheduleEventsValidationResultsProps } from "./ScheduleEventsValidationResults.types";

const ScheduleEventsValidationResults: React.FC<IScheduleEventsValidationResultsProps> = props => {
  const { eventData } = props;
  return (
    <Box
      sx={{
        ...borderStyles,
        borderTopWidth: "2px"
      }}
    >
      {eventData.map(sheet => {
        if (sheet.failed.length) {
          return (
            <Box
              key={sheet.name}
              py={3}
              sx={{
                ...borderStyles,
                borderBottomWidth: "1px"
              }}
            >
              <Heading as="h3" variant="small" mb={3}>
                Sheet:{" "}
                <Text as="span" fontWeight="normal">
                  {sheet.name}
                </Text>
              </Heading>
              <>
                {!!sheet.failed.length && (
                  <Text color="danger">
                    {sheet.failed.length} Rows Failed Validation:
                  </Text>
                )}
                <ul>
                  {sheet.failed.map(err => {
                    return (
                      <li key={sheet.name + err.row + err.path.toString()}>
                        Row: {err.row}, {err.message}
                      </li>
                    );
                  })}
                </ul>
              </>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default ScheduleEventsValidationResults;
