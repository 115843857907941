/** Lib */
import React, { HTMLProps } from "react";

/** UI */
import { Box, Text, BoxProps, SxStyleProp } from "rebass";

/** Styles */
import {
  radioLabelStyles,
  radioInputStyles,
  radioContainerStyles
} from "./Radio.styles";

/** Types */
export type TInputProps = {
  name: string;
  id?: string;
  value?: string;
  label: string;
  required?: boolean;
  error?: string;
  containerProps?: BoxProps;
  labelSx?: SxStyleProp;
} & BoxProps &
  HTMLProps<HTMLInputElement>;

const Radio: React.ForwardRefExoticComponent<TInputProps> = React.forwardRef(
  (props, ref) => {
    const { labelSx, containerProps, ...rest } = props;

    return (
      <Box
        className="form-control checkbox"
        {...(containerProps ? containerProps : {})}
        css={{ cursor: "pointer" }}
      >
        <Box
          className="radio-container"
          sx={{ ...radioContainerStyles, whiteSpace: "nowrap" }}
        >
          <Box
            as="input"
            type="radio"
            ref={ref}
            sx={{ ...radioInputStyles }}
            {...rest}
          />
          <Text
            as="label"
            variant="sectionHeading"
            htmlFor={props.id}
            sx={{ ...radioLabelStyles(props.disabled), ...(labelSx || {}) }}
          >
            {props.label}
          </Text>
        </Box>
      </Box>
    );
  }
);

export default Radio;
