/**
 * Constants
 */
export const SET_EVENTS = "SET_EVENTS";
export const SET_OWN_AMBASSADOR_EVENTS = "SET_OWN_AMBASSADOR_EVENTS";
export const SET_OWN_EVENTS = "SET_OWN_EVENTS";
export const SET_SUBSCRIBED_TO_ALL = "SET_SUBSCRIBED_TO_ALL";
export const SET_SUBSCRIBED_TO_OWN = "SET_SUBSCRIBED_TO_OWN";
export const SET_SUBSCRIBED_TO_OWN_AMBASSADOR = "SET_SUBSCRIBED_TO_OWN_AMBASSADOR";

export const statuses = {
  requested: "requested",
  approved: "approved",
  denied: "denied",
  booked: "booked",
  executed: "executed",
  cancelled: "cancelled",
};
