/** Lib */
import React from "react";

/** Services */
import EventsService from "@services/events/events.service";

/** Types */
import { IFirestoreEventRecap } from "@typings/events/events.types";

export default (eventId: string) => {
  const [subscribed, setSubscribed] = React.useState(false);
  const [eventRecap, setEventRecap] = React.useState<IFirestoreEventRecap>();

  React.useEffect(() => {
    if (!subscribed) {
      EventsService.subscribeToSingleEventRecap(eventId, (recapData) => {
        setEventRecap(recapData);
        setSubscribed(true);
      });
    }
  }, [subscribed, eventId]);

  return eventRecap;
};
