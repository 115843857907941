/** Lib */
import React from "react";

/** Types */
import { Column, Row } from "react-table";
import { SxStyleProp, Flex, Box, BoxProps } from "rebass";

/** Hooks */
import hooks from "./CheckboxTable.hooks";
import Pagination from "../table/Pagination";
import { tableStyles, StyledTh } from "../table/Table.styles";

export interface ICheckboxTableProps<RowDataType extends {}> {
  id: string;
  tableData: Array<RowDataType>;
  columns: Array<Column<RowDataType>>;
  defaultSortBy?: { id: string; desc: boolean }[];
  onRowClick?: (row: Row<any>) => void;
  onSelectionChange?: (selection: Row<RowDataType>[]) => void;
  sx?: SxStyleProp;
  filterColumns?: (keyof RowDataType)[];
  filterValue?: string;
  selectionMax?: number;
}

function CheckboxTable<RowDataType>(
  props: React.PropsWithChildren<ICheckboxTableProps<RowDataType> & BoxProps>
) {
  const {
    onRowClick,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    // rows,
    prepareRow,
    // selectedRowIds,
    // selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageSize,
    pageIndex
  } = hooks<RowDataType>(props);

  const { onRowClick: ignore, onSelectionChange, ...restProps } = props;

  return (
    <Box overflowX="scroll" maxWidth="100%" sx={tableStyles} {...restProps}>
      <Flex flexDirection="column" justifyContent="space-between">
        <Box as="table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTh
                    {...column.getHeaderProps(
                      (column as any).getSortByToggleProps()
                    )}
                    id={column.id}
                  >
                    {column.render("Header")}
                  </StyledTh>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const click = cell.column.id !== "selection";
                    return (
                      <td
                        {...cell.getCellProps()}
                        id={cell.column.id}
                        onClick={click ? onRowClick(row) : undefined}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Box>
        <Flex justifyContent="center">
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default CheckboxTable;
