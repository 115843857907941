import React from "react";
import { format } from "date-fns";
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";


export default function useGenerateEventColumns(headers?: string[]) {
  const { business } = useSubscribeToBusiness();

  return React.useMemo(() => {
    const columns = [
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Account Name",
        accessor: "accountName"
      },
      {
        Header: "Business Entity",
        accessor: "business",
        Cell: (context: any) => {
          const businessId = context.row.original.business;
          return business?.find(item=>item?.id === businessId)?.accountName || ''
        }
      },
      {
        Header: "Brand",
        accessor: "brand"
      },
      {
        Header: "City",
        accessor: "city"
      },
      {
        Header: "State",
        accessor: "state"
      },
      {
        Header: "Zip Code",
        accessor: "zipCode"
      },
      {
        Header: "Date",
        accessor: "eventStartTime",
        Cell: (context: any) => {
          return format(
            context.row.original.eventStartTime.toDate(),
            "yyyy-MM-dd"
          );
        }
      }
    ];
    if (headers) {
      return columns.filter(col => headers.includes(col.accessor));
    }
    return columns;
  }, [headers, business]);
}
