export function dateRangeOverlaps(
  startA: Date | number,
  endA: Date | number,
  startB: Date | number,
  endB: Date | number
) {
  if (startA <= startB && startB <= endA) return true; // b starts in a
  if (startA <= endB && endB <= endA) return true; // b ends in a
  if (startB < startA && endA < endB) return true; // a in b
  return false;
}
