/** Lib */
import React from "react";

/** Hooks */
import hooks from "./StaffEvent.hooks";

/** UI */
import CheckboxTable from "@/ui/common/checkbox-table/CheckboxTable.common";
import { Container } from "@/ui/common/responsive";
import Filters from "./Filters";
import { Flex, Box, Button, Heading, Text } from "rebass";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";
import Input from "@/ui/common/form-elements/input/Input.common";
import Loader from "@/ui/common/loader/Loader.common";
import { format } from "date-fns";
import Table from "@/ui/common/table/Table.common";
import { TIMEZONE_NAMES, TTimezones } from "@/constants/events.constants";
import { statuses } from "@/stores/events/events.constants";
import { Link } from "react-router-dom";

const StaffEventScreen: React.FC<Router.RouteComponentProps<{
  id: string;
}>> = (props) => {
  const {
    event,
    isPastEvent,
    availableAmbassadors,
    acceptedAmbassadors,
    invitedAmbassadors,
    confirmedAmbassadors,
    // declinedAmbassadors,
    columns,
    confirmSelectionMax,
    onSelectionChange,
    onAcceptedSelectionChange,
    onConfirmedSelectionChange,
    selectedAmbassadors,
    selectedAcceptedAmbassadors,
    selectedConfirmedAmbassadors,
    onSearchChange,
    onSearchAcceptedChange,
    onSearchConfirmedChange,
    searchTerm,
    acceptedSearchTerm,
    confirmedSearchTerm,
    onInviteClick,
    onConfirmAcceptedInvitesClick,
    onRevokeConfirmedClick,
    eventTooFarInFuture,
    loading,
    showFilterState,
    onFilterCheckboxChange,
  } = hooks(props);

  return (
    <Container pt={4}>
      {event ? (
        <>
          <Box mb="65px">
            <Heading mb={1}>
              Staff Event:{" "}
              <Link to={`/events/${event.id}`}>
                {event.accountName}, {event.brand}{" "}
              </Link>
              (status: {event.status})
            </Heading>
            <Text>
              {format(event.eventStartTime.toDate(), "E, M/dd h:mm a")} -{" "}
              {format(event.eventEndTime.toDate(), "h:mm a")}{" "}
              {isPastEvent && (
                <Text as="span" color="danger">
                  {" "}
                  (PAST EVENT)
                </Text>
              )}
              <br />
              {TIMEZONE_NAMES[event.timezone as TTimezones]}
              {" Time"}
            </Text>
          </Box>
          {event &&
          !isPastEvent &&
          !eventTooFarInFuture &&
          [statuses.approved, statuses.booked].includes(event.status) ? (
            <>
              <Box mb={5}>
                <Filters
                  showFilterState={showFilterState}
                  onFilterCheckboxChange={onFilterCheckboxChange}
                />
              </Box>
              {showFilterState.available && (
                <Box>
                  <Heading variant="small" mb={4}>
                    Available Ambassadors
                  </Heading>
                  <Flex justifyContent="space-between" mb={4}>
                    <Box minWidth="300px">
                      <Input
                        name="amb-search"
                        label="search"
                        onChange={onSearchChange}
                      />
                    </Box>
                    <Box>
                      <Button
                        type="button"
                        onClick={onInviteClick}
                        disabled={!!!(selectedAmbassadors.length && !loading)}
                        variant={
                          selectedAmbassadors.length && !loading
                            ? "primary"
                            : "primary.disabled"
                        }
                      >
                        {loading ? (
                          <Loader height="15px" width="15px" m="0 auto" />
                        ) : (
                          "Invite Selected Ambassadors"
                        )}
                      </Button>
                    </Box>
                  </Flex>
                  <CheckboxTable<TUserFirestore>
                    id="staffedAvailable"
                    tableData={availableAmbassadors}
                    columns={columns}
                    onSelectionChange={onSelectionChange}
                    filterValue={searchTerm}
                  />
                </Box>
              )}
              {showFilterState.accepted && (
                <Box>
                  <Heading variant="small" mb={4}>
                    Ambassadors Pending Confirmation (Accepted Invite)
                  </Heading>
                  <Flex justifyContent="space-between" mb={4}>
                    <Box minWidth="300px">
                      <Input
                        name="amb-search"
                        label="search"
                        onChange={onSearchAcceptedChange}
                      />
                    </Box>
                    <Box>
                      <Button
                        type="button"
                        onClick={onConfirmAcceptedInvitesClick}
                        disabled={
                          !!!(selectedAcceptedAmbassadors.length && !loading)
                        }
                        variant={
                          selectedAcceptedAmbassadors.length && !loading
                            ? "primary"
                            : "primary.disabled"
                        }
                      >
                        {loading ? (
                          <Loader height="15px" width="15px" m="0 auto" />
                        ) : (
                          "Confirm Selected Ambassadors"
                        )}
                      </Button>
                    </Box>
                  </Flex>
                  <CheckboxTable<TUserFirestore>
                    id="staffedAccepted"
                    tableData={acceptedAmbassadors}
                    columns={columns}
                    onSelectionChange={onAcceptedSelectionChange}
                    filterValue={acceptedSearchTerm}
                    selectionMax={confirmSelectionMax}
                  />
                </Box>
              )}
              {showFilterState.confirmed && (
                <Box>
                  <Heading variant="small" mb={4}>
                    Confirmed Ambassadors
                  </Heading>
                  <Flex justifyContent="space-between" mb={4}>
                    <Box minWidth="300px">
                      <Input
                        name="amb-search"
                        label="search"
                        onChange={onSearchConfirmedChange}
                      />
                    </Box>
                    <Box>
                      <Button
                        type="button"
                        onClick={onRevokeConfirmedClick}
                        disabled={
                          !!!(selectedConfirmedAmbassadors.length && !loading)
                        }
                        variant={
                          selectedConfirmedAmbassadors.length && !loading
                            ? "danger"
                            : "danger.disabled"
                        }
                      >
                        {loading ? (
                          <Loader height="15px" width="15px" m="0 auto" />
                        ) : (
                          "Remove Selected Ambassadors"
                        )}
                      </Button>
                    </Box>
                  </Flex>
                  <CheckboxTable<TUserFirestore>
                    id="staffConfirmed"
                    tableData={confirmedAmbassadors}
                    columns={columns}
                    onSelectionChange={onConfirmedSelectionChange}
                    filterValue={confirmedSearchTerm}
                  />
                </Box>
              )}
              {showFilterState.invited && (
                <Box>
                  <Heading variant="small" mb={4}>
                    Invited Ambassadors (have not accepted)
                  </Heading>
                  <Table<TUserFirestore>
                    tableData={invitedAmbassadors}
                    columns={columns}
                    filterValue={searchTerm}
                  />
                </Box>
              )}{" "}
            </>
          ) : ![statuses.approved, statuses.booked].includes(event.status) ? (
            <Text color="danger" fontSize={1} sx={{ fontStyle: "italic" }}>
              Status must be 'Approved' or 'Booked' to access staffing.
            </Text>
          ) : eventTooFarInFuture ? (
            <Text color="danger" fontSize={1} sx={{ fontStyle: "italic" }}>
              Event must start in 30 days or less in order to access staffing.
            </Text>
          ) : isPastEvent ? (
            <Text color="danger" fontSize={1} sx={{ fontStyle: "italic" }}>
              Event is in the past.
            </Text>
          ) : null}
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x"></Loader>
        </Flex>
      )}
    </Container>
  );
};

export default StaffEventScreen;
