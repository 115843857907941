/**
 *
 *
 * *IMPORTS
 *
 *
 */

/**
 * * IMPORTS: Lib
 */
import React from "react";
import ReactTooltip from "react-tooltip";
import { Flex, Box } from "rebass";

/**
 * * IMPORTS: Hooks
 */
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";
import useSubscribeToAmbassadorEvents from "@/hooks/useSubscribeToAmbassadorEvents";
import useGenerateEventColumns from "@/hooks/useGenerateEventColumns";
import useScrollToTop from "@/hooks/useScrollToTop";
import useLocalStorage from "@/hooks/useLocalStorage";

/**
 * * IMPORTS: Stores
 */
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/**
 * * IMPORTS: Styles
 */
import { tableActionContainerStyles } from "@/styles/shared/tables.styles";

/**
 * * IMPORTS: Types
 */
import { Row } from "react-table";
import { TFirestoreEvent } from "@typings/events/events.types";
import { IconCheck, IconClear } from "@/ui/common/icon/Icons.common";
import { confirmAlert } from "react-confirm-alert";
import eventsService from "@/services/events/events.service";
import { toast } from "react-toastify";
import { EVENT_INVITATION_RESPONSES } from "@/constants/events.constants";
import { statuses } from "@/stores/events/events.constants";

/**
 *
 *
 * * VARIABLES
 *
 *
 */

/**
 * * VARIABLES: Types
 */
export interface IShowAmbassadorFilterState {
  confirmed: boolean;
  accepted: boolean;
  invited: boolean;
  // declined: boolean;
}

/**
 * * VARIABLES: Constants
 */
export const DASHBOARD_SHOW_INITIAL: IShowAmbassadorFilterState = {
  confirmed: false,
  accepted: false,
  invited: true
  // declined: false
};

export const DASHBOARD_SHOW_RESET: IShowAmbassadorFilterState = {
  confirmed: false,
  accepted: false,
  invited: false
  // declined: false
};

/**
 *
 *
 * * EXPORT
 *
 *
 */

export default (props: Router.RouteComponentProps) => {
  const { history } = props;
  /**
   * * State
   */
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showFilterState, setShowFilterState] = useLocalStorage<
    IShowAmbassadorFilterState
  >("AMBASSADOR_DASHBOARD_FILTERS", DASHBOARD_SHOW_INITIAL);
  const [loading, setLoading] = React.useState(false);
  /**
   * * Hooks
   */
  useScrollToTop();
  const {
    state: { user: auth }
  } = useAuthStore();
  const ownUser = useSubscribeToOwnUser();
  const events = useSubscribeToAmbassadorEvents();
  const columns = useGenerateEventColumns();

  /**
   * @function onAcceptInviteClick
   * Event handler for user accepting Event invite
   */
  const onAcceptInviteClick = React.useCallback(
    (data: TFirestoreEvent) => (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      if (auth) {
        confirmAlert({
          title: "Accept Event Invite?",
          message: "This accept invitation and notify MTWO for confirmation.",
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                try {
                  setLoading(true);
                  await eventsService.respondToEventInvite({
                    userId: auth.uid,
                    eventId: data.id,
                    response: EVENT_INVITATION_RESPONSES.accept
                  });
                  setLoading(false);
                  toast("Event invitation accepted.");
                } catch (err) {
                  setLoading(false);
                  toast("Error accepting invitation: " + err.toString());
                  console.error(
                    "AmbassadorDashboard.hooks: onAcceptInviteClick: Error Accepting Event."
                  );
                }
              }
            },
            {
              label: "No",
              onClick: () => {}
            }
          ]
        });
      }
    },
    [auth]
  );

  /**
   * @function onDeclineInviteClick
   * Event handler for user declining Event invite
   */
  const onDeclineInviteClick = React.useCallback(
    (data: TFirestoreEvent) => (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      if (auth) {
        confirmAlert({
          title: "Decline Event Invite?",
          message: "This will decline your invitation to this Event.",
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                try {
                  setLoading(true);
                  await eventsService.respondToEventInvite({
                    userId: auth.uid,
                    eventId: data.id,
                    response: EVENT_INVITATION_RESPONSES.decline
                  });
                  setLoading(false);
                  toast("Event invitation declined.");
                } catch (err) {
                  setLoading(false);
                  toast("Error declining invitation: " + err.toString());
                  console.error(
                    "AmbassadorDashboard.hooks: onDeclineInviteClick: Error Declining Event."
                  );
                }
              }
            },
            {
              label: "No",
              onClick: () => {}
            }
          ]
        });
      }
    },
    [auth]
  );

  /**
   * * Memo'd variables
   */
  const invitedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        Header: "",
        accessor: "accept",
        Cell: (context: { row: { original: TFirestoreEvent } }) => (
          <Flex justifyContent="flex-end">
            <Box
              as="button"
              onClick={onAcceptInviteClick(context.row.original)}
              data-tip="Accept Invite (Still needs MTWO confirmation)"
              data-place="left"
              sx={{
                ...tableActionContainerStyles,
                svg: {
                  fill: "success",
                  width: "16px"
                }
              }}
            >
              <IconCheck title="Accept Invite" />
            </Box>
            <Box
              onClick={onDeclineInviteClick(context.row.original)}
              data-tip="Decline Invite"
              data-place="left"
              as="button"
              sx={{
                ...tableActionContainerStyles,
                svg: {
                  fill: "danger",
                  width: "16px"
                }
              }}
            >
              <IconClear title="Decline Invite" />
              <ReactTooltip />
            </Box>
            <ReactTooltip />
          </Flex>
        )
      }
    ];
    //
  }, [columns, onAcceptInviteClick, onDeclineInviteClick]);

  /**
   ** Handlers
   */
  const onSearchInputChange = React.useCallback(
    (ev: React.ChangeEvent<any>) => {
      setSearchTerm((ev.target as HTMLInputElement).value);
    },
    []
  );

  const onRowClick = React.useCallback(
    (val: Row<TFirestoreEvent>) => {
      const rowEvent = val.original;
      history.push(`/events/${rowEvent.id}`);
    },
    [history]
  );

  const onFilterCheckboxChange = React.useCallback(
    (filterType: keyof IShowAmbassadorFilterState) => (
      ev: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (ev.target.checked && !showFilterState[filterType]) {
        setShowFilterState({
          ...DASHBOARD_SHOW_RESET,
          [filterType]: ev.target.checked
        });
        setSearchTerm("");
      }
    },
    [setShowFilterState, showFilterState]
  );

  const invitedEvents = React.useMemo(() => {
    if (events?.invitedEvents) {
      return events.invitedEvents.filter(ev => ev.status === statuses.approved || ev.status === statuses.booked)
    }
  }, [events])

  const acceptedEvents = React.useMemo(() => {
    if (events?.acceptedEvents) {
      return events.acceptedEvents.filter(ev => ev.status === statuses.approved || ev.status === statuses.booked)
    }
  }, [events])

  return {
    ownUser,
    columns,
    invitedColumns,
    events,
    invitedEvents,
    acceptedEvents,
    showFilterState,
    onRowClick,
    onFilterCheckboxChange,
    onSearchInputChange,
    loading,
    searchTerm
  };
};
