/** Lib */
import * as firebase from "firebase/app"

/** Types */
import { TAccountForm } from "@typings/accounts/accounts.types";
import { accountFormSchema } from "@schemas/accounts/accounts.schemas";

/** Constants */
import {
  FIREBASE_ACCOUNTS_RESOURCE_NAME,
  FIREBASE_ACCOUNTS_RESOURCE_NAME_SINGLE
} from "@constants/accounts.constants";

export default {
  getById(id: string) {
    return firebase
      .firestore()
      .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
      .doc(id)
      .get();
  },
  getByAccountName(accountName: string) {
    return firebase
      .firestore()
      .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
      .limit(1)
      .where("accountName", "==", accountName.toLowerCase())
      .get();
  },
  async addAccounts(accounts: TAccountForm[]) {
    try {
      const duplicates: TAccountForm[] = [];
      const accountsToAdd: TAccountForm[] = [];

      await Promise.all(
        accounts.map(account => {
          const accountDocRef = firebase
            .firestore()
            .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
            .where("accountName", "==", account.accountName);
          return accountDocRef.get().then(doc => {
            if (doc.size) {
              return duplicates.push(account);
            }
            return accountsToAdd.push(account);
          });
        })
      );

      const batch = firebase.firestore().batch();

      accountsToAdd.forEach(account => {
        const accountDocRef = firebase
          .firestore()
          .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
          .doc();

        batch.set(accountDocRef, { ...account, id: accountDocRef.id });
      });

      await batch.commit();

      return { added: accountsToAdd, duplicates };
    } catch (err) {
      console.error("Error adding accounts.", err);
    }
  },
  subscribeToAll(
    snapshotCallback: (
      snapshot: firebase.firestore.QuerySnapshot<
        firebase.firestore.DocumentData
      >
    ) => void
  ) {
    return firebase
      .firestore()
      .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
      .orderBy(`accountName`)
      .onSnapshot(snapshotCallback, err => {
        console.error(
          `Error occurred subscribing to ${FIREBASE_ACCOUNTS_RESOURCE_NAME}`,
          err
        );
      });
  },
  async create(payload: TAccountForm) {
    if (!accountFormSchema.validateSync(payload)) {
      return Promise.reject(
        new Error(
          `${FIREBASE_ACCOUNTS_RESOURCE_NAME} Service: Create: Invalid ${FIREBASE_ACCOUNTS_RESOURCE_NAME_SINGLE} Schema`
        )
      );
    }
    try {
      const ref = firebase
        .firestore()
        .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
        .where("accountName", "==", payload.accountName);

      const doc = await ref.get();

      if (doc.size) {
        return Promise.reject(
          new Error(
            `${FIREBASE_ACCOUNTS_RESOURCE_NAME_SINGLE} "${payload.accountName}" already exists.`
          )
        );
      }

      const createRef = firebase
        .firestore()
        .collection(FIREBASE_ACCOUNTS_RESOURCE_NAME)
        .doc();
      createRef.set({ ...payload, id: createRef.id });
    } catch (err) {
      console.error(
        `${FIREBASE_ACCOUNTS_RESOURCE_NAME} Service: Create: Error Adding ${FIREBASE_ACCOUNTS_RESOURCE_NAME_SINGLE}`,
        err
      );
    }
  }
};
