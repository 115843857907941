/** Lib */
import React from "react";

/** Hooks */
import hooks from "./Accounts.hooks";

/** UI */
import AccountsListing from "@components/accounts/AccountsListing.component";
import { Container } from "@common/responsive";
import Card from "@/ui/common/card/Card.common";
import { Heading } from "rebass";

const AccountsScreen: React.FC<Router.RouteComponentProps> = props => {
  const { state } = hooks();
  return (
    <Container pt={4}>
      <Card p={[3, 3, 4]}>
        <Heading variant="small">Accounts</Heading>
        <AccountsListing {...props} accounts={state.accounts} />
      </Card>
    </Container>
  );
};

export default AccountsScreen;
