/** Lib */
import React, { HTMLProps } from "react";

/** UI */
import { Box, Text, BoxProps } from "rebass";

/** Styles */
import {
  textAreaContainerStyles,
  textAreaLabelStyles,
  textAreaStyles
} from "../FormElement.styles";

/** Types */
export type TInputProps = {
  name: string;
  id?: string;
  label: string;
  required?: boolean;
  error?: string;
  containerProps?: BoxProps;
  externalLabel?: boolean;
} & BoxProps &
  HTMLProps<HTMLTextAreaElement>;

const TextArea: React.ForwardRefExoticComponent<TInputProps> = React.forwardRef(
  (props, ref) => {
    /**
     * Props
     */
    const {
      onFocus,
      onBlur,
      required,
      error,
      disabled,
      externalLabel = true,
      containerProps
    } = props;
    /**
     * State
     */
    const [focused, setFocused] = React.useState(false);

    const onInputFocus = React.useCallback(
      (ev: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(ev);
        }
        setFocused(true);
      },
      [onFocus]
    );

    const onInputBlur = React.useCallback(
      (ev: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(ev);
        }
        setFocused(false);
      },
      [onBlur]
    );

    return (
      <Box className="form-control" {...(containerProps ? containerProps : {})}>
        {externalLabel && (
          <Text
            as="label"
            variant="sectionHeading"
            sx={textAreaLabelStyles}
            mb={1}
          >
            {props.label}
          </Text>
        )}
        <Box
          className="textarea-container"
          sx={textAreaContainerStyles(focused, !!required, error, disabled)}
        >
          {!externalLabel && (
            <Text
              as="label"
              variant="sectionHeading"
              sx={textAreaLabelStyles}
              mb={1}
            >
              {props.label}
            </Text>
          )}
          <Box
            {...props}
            ref={ref}
            as="textarea"
            sx={textAreaStyles}
            color={disabled ? "rgb(98,98,98)" : undefined}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
        </Box>
        {error && (
          <Text color="danger" fontSize={1} pt={1} pl={1}>
            {error}
          </Text>
        )}
      </Box>
    );
  }
);

export default TextArea;
