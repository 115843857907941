/** Lib */
import React from "react";
import { Link } from "react-router-dom";

/** UI */
import EventCreatorDashboardScreen from "./event-creator-dashboard/EventCreatorDashboard.screen";
import AdminDashboardScreen from "./admin-dashboard/AdminDashboard.screen";
import AmbassadorDashboardScreen from "./ambassador-dashboard/AmbassadorDashboard.screen";
import { Container } from "@/ui/common/responsive";
import { Text, Button, Flex } from "rebass";

/** Hooks */
import useSubscribeToOwnUser from "@/hooks/useSubscribeToOwnUser";

/** Constants */
import { USER_ROLES } from "@/stores/auth/auth.constants";
import Loader from "@/ui/common/loader/Loader.common";

const DashboardScreen: React.FC<Router.RouteComponentProps> = props => {

  const ownUser = useSubscribeToOwnUser();
  if (!ownUser) {
    return (
      <Container pt={4}>
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      </Container>
    );
  }

  if (!ownUser.active) {
    return (
      <Container pt={4}>
        <Text mb={2}>
          Please complete your profile to activate your account.
        </Text>
        <Link to="/finish-sign-up">
          <Button>Complete Profile</Button>
        </Link>
      </Container>
    );
  }
  switch (ownUser.role) {
    case USER_ROLES.eventCreator:
      return <EventCreatorDashboardScreen {...props} />;
    case USER_ROLES.admin:
      return <AdminDashboardScreen {...props} />;
    case USER_ROLES.superAdmin:
      return <AdminDashboardScreen {...props} />;
    case USER_ROLES.brandAmbassador:
      return <AmbassadorDashboardScreen {...props} />;
    default:
      return (
        <Container pt={4}>
          Something went wrong, please contact an MTWO administrator.
        </Container>
      );
  }
};

export default DashboardScreen;
