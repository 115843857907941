/** Lib */
import React from "react";

/** Stores */
import { useStore } from "@stores/business/business.store";

/** Services */
import BusinessService from "@services/business/business.service";

/** Types */
import { TBusinessFirestore } from "@typings/business/business.types";

export default () => {
  const { state, actions } = useStore();

  const { subscribedToAll } = state;

  React.useEffect(() => {
    if (!subscribedToAll) {
      BusinessService.subscribeToAll((business) => {
        const data = business.docs.map(doc => doc.data()) as TBusinessFirestore[];

        actions.setBusiness(data);
        actions.setSubscribedToAll(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToAll]);

  return state;
};
