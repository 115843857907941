import styled from "@emotion/styled";
import theme from "@/ui/theme";

export const StyledTh = styled.th({
  ...(theme.text.sectionHeading as any),
});

export const tableStyles = {
  table: {
    textAlign: "left",
    borderSpacing: "0",
    borderCollapse: "separate",
    margin: "0 auto 60px",
    width: "100%",
    th: {
      fontFamily: "heading",
      padding: ["10px 5px", "10px 5px"],
    },
    "thead > tr": {
      backgroundColor: "transparent",
    },
    "tbody > tr": {
      ":nth-of-type(odd)": {
        backgroundColor: "lightGray",
        "&:hover": {
          backgroundColor: "#dadada",
        },
      },
      transition: "opacity 125ms",
      "&:hover": {
        backgroundColor: "#f9f9f9",
      },
      cursor: "pointer",
    },
    td: {
      padding: ["5px", "5px"],
      minWidth: "100px",
      maxWidth: "150px",
      maxHeight: "75px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&#avatar": {
        minWidth: "50px",
      },
      "&#selection": {
        minWidth: "25px",
      },
    },
  },
};
