import React from "react";
import { Flex, FlexProps } from "rebass";

/** Types */
import { InterpolationWithTheme } from "@emotion/core";

const Row: React.NamedExoticComponent<FlexProps & {
  css?: InterpolationWithTheme<any>;
}> = React.memo(props => {
  return (
    <Flex
      {...props}
      mx="-15px"
      flexWrap="wrap"
      className={
        typeof props.className === "string"
          ? `c-row ${props.className}`
          : "c-row"
      }
    >
      {props.children}
    </Flex>
  );
});

export default Row;
