/** Lib */
import React from "react";

/** Services */
import EventsService from "@services/events/events.service";

/** Types */
import { TFirestoreEventProtected } from "@typings/events/events.types";

export default (eventId: string) => {
  const [subscribed, setSubscribed] = React.useState(false);
  const [eventProtected, setEventProtected] = React.useState<
    TFirestoreEventProtected
  >();

  React.useEffect(() => {
    if (!subscribed) {
      EventsService.subscribeToSingleEventProtected(eventId, doc => {
        const data = doc.data() as TFirestoreEventProtected;
        setEventProtected(data);
        setSubscribed(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribed]);

  return eventProtected;
};
