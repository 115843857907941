/** Lib */
import React from "react";
import { Link } from "react-router-dom";

/** Hooks */
import hooks from "./AddAccountsExcel.hooks";

/** UI */
import { Heading, Box, Button, Link as Anchor } from "rebass";
import { Container } from "@common/responsive";

/** Components */
import AddAccountsUploadResults from "@components/add-accounts-excel/upload-results/AddAccountsUploadResults.component";
import AddAccountsForm from "@components/add-accounts-excel/form/AddAccountsForm.component";
import AddAccountsValidationResults from "@components/add-accounts-excel/validation-results/AddAccountsValidationResults.component";

const AddAccountsExcelScreen: React.FC<Router.RouteComponentProps> = props => {
  const {
    dropzoneState,
    isFileTooLarge,
    onUploadSubmit,
    accountData,
    workbook,
    workbookValid,
    resetForm,
    rejectedFilesLocal,
    loading,
    uploadResponse
  } = hooks();

  return (
    <>
      <Container pt={4}>
        <Heading variant="medium.center" mb={4}>
          Add Accounts from Excel{" "}
          <Anchor fontSize={2} href="/account-upload-template.xlsx">
            (download template)
          </Anchor>
        </Heading>
        {!uploadResponse ? (
          <>
            <AddAccountsForm
              dropzoneState={dropzoneState}
              resetForm={resetForm}
              onUploadSubmit={onUploadSubmit}
              workbook={workbook}
              workbookValid={workbookValid}
              rejectedFilesLocal={rejectedFilesLocal}
              isFileTooLarge={isFileTooLarge}
              loading={loading}
              accountData={accountData}
            />
            {!!accountData.length && (
              <AddAccountsValidationResults accountData={accountData} />
            )}
          </>
        ) : (
          <AddAccountsUploadResults
            resetForm={resetForm}
            uploadResponse={uploadResponse}
          />
        )}
        {!accountData.length && (
          <Box>
            <Link to="dashboard">
              <Button type="button" mr={2}>
                Dashboard
              </Button>
            </Link>
            <Link to="calendar">
              <Button variant="secondary">View Calendar</Button>
            </Link>
          </Box>
        )}
      </Container>
    </>
  );
};

export default AddAccountsExcelScreen;
