/** Store dependencies */
import * as actionCreators from "./users.actions";
import usersReducer from "./users.reducer";
import createStore from "@util/createStore";

/** Types */
import { TUsersAction, IUsersState, IUsersActions } from "./users.types";

export const USERS_INITIAL_STATE = {
  allUsers: [],
  ownUser: null,
  subscribedToAll: false,
  subscribedToOwn: false,
  eventCreators: [],
  subscribedToEventCreators: false,
  ambassadors: [],
  subscribedToAmbassadors: false
};

const { StoreContext, StoreContextProvider, useStore } = createStore<
  IUsersState,
  TUsersAction<any>,
  IUsersActions
>(USERS_INITIAL_STATE, usersReducer, actionCreators);

export {
  StoreContext as UsersContext,
  StoreContextProvider as UsersContextProvider,
  useStore
};
