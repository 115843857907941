/** Constants */
import { SET_ACCOUNTS, SET_SUBSCRIBED_TO_ALL } from "./accounts.constants";

/** Types */
import { TAccountFirestore } from "@typings/accounts/accounts.types";
import { TAccountsAction, IAccountsState } from "./accounts.types";

/**
 * Action Creators (For use by store, _do not import these directly into components_)
 */

export const setAccounts: ActionCreator<
  IAccountsState,
  TAccountsAction<TAccountFirestore[]>
> = (state, dispatch) => (value: TAccountFirestore[]) => {
  dispatch({
    type: SET_ACCOUNTS,
    payload: value
  });
};

export const setSubscribedToAll: ActionCreator<
  IAccountsState,
  TAccountsAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_ALL,
    payload: value
  });
};
