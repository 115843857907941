import React from "react";

/** Types */
import { TFirestoreEvent } from "@typings/events/events.types";
import { IOwnEvents } from "@/stores/events/events.types";

/** Util */
import parseEventsForDashboard from "@util/parseEventsForDashboard";

export default (events: TFirestoreEvent[], searchKeyBusiness: string): IOwnEvents | null => {
  const [parsedEvents, setParsedEvents] = React.useState<IOwnEvents | null>(
    null
  );

  React.useEffect(() => {
    setParsedEvents(parseEventsForDashboard(events, searchKeyBusiness));
  }, [events, searchKeyBusiness]);

  return parsedEvents;
};
