/** Lib */
import React, { NamedExoticComponent } from "react";

/** UI */
import { Flex, FlexProps } from "rebass";

const MenuSection: NamedExoticComponent<React.PropsWithChildren<{
  border?: boolean;
} & FlexProps>> = React.memo(({ children, border = true, sx }) => {
  return (
    <Flex
      p={3}
      flexDirection="column"
      sx={{
        borderColor: "lightGray",
        borderWidth: border ? "0 0 1px 0" : 0,
        borderStyle: "solid",
        ...sx
      }}
    >
      {children}
    </Flex>
  );
});

export default MenuSection;
