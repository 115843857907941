/** Lib */
import React from "react";
import { Link } from "react-router-dom";

/** UI */
import { Container } from "@/ui/common/responsive";
import { Heading, Text, Box, Button } from "rebass";
import Card from "@/ui/common/card/Card.common";
import AdminProfileForm from "@/ui/components/admin-and-ec-profile-form/AdminAndEventCreatorProfileForm.component";
import EditAvatar from "@/ui/components/edit-avatar/EditAvatar.component";

/** Hooks */
import hooks from "./FinishSignUp.hooks";
import { USER_ROLES } from "@/stores/auth/auth.constants";
import AmbassadorProfileForm from "@/ui/components/ambassador-profile-form/AmbassadorProfileForm.component";

// Constants
const FRIENDLY_ROLES: { [key in AuthUserRoles]: string } = {
  superAdmin: "Super Admin",
  admin: "Admin",
  eventCreator: "Event Creator",
  brandAmbassador: "Brand Ambassador"
};

const FinishSignUpScreen: React.FC<Router.RouteComponentProps> = props => {
  const { user, submitSuccess, onSubmitSuccess, onSubmitError } = hooks(
    props
  );
  return (
    <>
      {user && (
        <>
          <Container pt={4}>
            <Heading>
              You've been invited to be a MTWO{" "}
              {FRIENDLY_ROLES[user.role as AuthUserRoles]}!
            </Heading>
            <Text mb={4}>
              Please fill in your details below to complete your sign up.
            </Text>
            <Card p={3}>
              {submitSuccess === true && (
                <Box>
                  <Heading color="success" variant="small" mb={2}>
                    Sign Up Complete!
                  </Heading>
                  <Text mb={4}>Your account is now active.</Text>
                  <Link to="/dashboard">
                    <Button>Go To Dashboard</Button>
                  </Link>
                </Box>
              )}
              {submitSuccess === false && (
                <Box>
                  <Heading color="danger" variant="small" mb={2}>
                    Sign Up Failed!
                  </Heading>
                  <Text mb={4}>Please contact an administrator.</Text>
                </Box>
              )}
              {typeof submitSuccess !== "boolean" && (
                <>
                  {[USER_ROLES.admin, USER_ROLES.eventCreator].includes(
                    user.role as AuthUserRoles
                  ) && (
                    <>
                      <Box mb={4}>
                        <EditAvatar user={user} />
                      </Box>

                      <AdminProfileForm
                        user={user}
                        onSubmitSuccess={onSubmitSuccess}
                        onSubmitError={onSubmitError}
                      />
                    </>
                  )}
                  {user.role === USER_ROLES.brandAmbassador && (
                    <>
                      <Box mb={4}>
                        <EditAvatar user={user} />
                      </Box>

                      <AmbassadorProfileForm
                        user={user}
                        onSubmitSuccess={onSubmitSuccess}
                        onSubmitError={onSubmitError}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Container>
        </>
      )}
    </>
  );
};

export default FinishSignUpScreen;
