/** Lib */
import React from "react";

/** UI */
import { Container } from "@common/responsive";
import Card from "@/ui/common/card/Card.common";
import { Heading } from "rebass";
import AddSingleAccountForm from "@components/add-single-account/form/AddSingleAccountForm.component";

const AddSingleAccountScreen: React.FC = props => {
  return (
    <Container pt={4}>
      <Heading variant="medium.center" mb={4}>
        Add New Account
      </Heading>
      <Card maxWidth="800px" p={4} margin="0 auto">
        <AddSingleAccountForm />
      </Card>
    </Container>
  );
};

export default AddSingleAccountScreen;
