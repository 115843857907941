//========================================================================================
/*                                                                                      *
 * APPROVE EVENT SCREEN                                                                 *
 *                                                                                      */
//========================================================================================

/**
 *
 * * IMPORTS
 *
 */

/**
 * * LIB
 */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

/**
 * * HOOKS
 */
import hooks from "./ApproveEvent.hooks";

/**
 * * UI
 */
import { Container } from "@/ui/common/responsive";
import { Heading, Button, Flex, Box, Text } from "rebass";
import Card from "@/ui/common/card/Card.common";
import Input from "@/ui/common/form-elements/input/Input.common";
import FormRow from "@/ui/common/form-elements/form-row/FormRow.common";
import Loader from "@/ui/common/loader/Loader.common";
import { TFirestoreEvent } from "@/typings/events/events.types";
import { format } from "date-fns";
import { TTimezones, TIMEZONE_NAMES } from "@/constants/events.constants";

const ApproveEventScreen: React.FC<RouteComponentProps<{
  id: string;
}>> = (props) => {
  const { formik, loading, onCancelClick, isPastEvent } = hooks(props);
  const event = props.location.state as TFirestoreEvent;

  if (!event) {
    props.history.push("/");
    return null;
  }

  return (
    <Container py={4}>
      <Box maxWidth="800px" margin="0 auto">
        <Heading variant="small" mb={3}>
          Approve Event
        </Heading>
        <Box mb="25px">
          <Heading variant="xsmall" mb={1}>
            {event.accountName}, {event.brand} (status: {event.status})
          </Heading>
          <Text>
            {format(
              new Date(event.eventStartTime.seconds * 1000),
              "E, M/dd h:mm a"
            )}{" "}
            - {format(new Date(event.eventEndTime.seconds * 1000), "h:mm a")}
            {isPastEvent && (
              <Text as="span" color="danger">
                {" "}
                (PAST EVENT)
              </Text>
            )}
            <br />
            {TIMEZONE_NAMES[event.timezone as TTimezones]}
            {" Time"}
          </Text>
        </Box>
      </Box>
      {event && !isPastEvent && (
        <Card p={4} maxWidth="800px" margin="0 auto">
          <form onSubmit={formik.handleSubmit}>
            <FormRow>
              <Input
                // containerProps={inputContainerProps}
                name="budget"
                label="Budget ($)"
                placeholder="0.00"
                required
                value={formik.values.budget}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.budget && formik.errors.budget
                    ? formik.errors.budget
                    : undefined
                }
              />
            </FormRow>
            <FormRow>
              <Input
                // containerProps={inputContainerProps}
                name="brandAmbassadorCount"
                label="Brand Ambassador Count (How Many?)"
                placeholder="0"
                required
                value={formik.values.brandAmbassadorCount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandAmbassadorCount &&
                  formik.errors.brandAmbassadorCount
                    ? formik.errors.brandAmbassadorCount
                    : undefined
                }
              />
            </FormRow>
            <FormRow>
              <Input
                // containerProps={inputContainerProps}
                name="actualBarSpend"
                label="Actual Bar Spend ($)"
                placeholder="0"
                required
                value={formik.values.actualBarSpend}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.actualBarSpend && formik.errors.actualBarSpend
                    ? formik.errors.actualBarSpend
                    : undefined
                }
              />
            </FormRow>
            <Flex alignItems="center">
              <Button
                mr={2}
                minHeight="32px"
                minWidth="100px"
                disabled={!formik.dirty}
                type="submit"
                variant={!formik.dirty ? "primary.disabled" : "primary"}
              >
                {loading ? (
                  <Loader height="15px" width="15px" m="0 auto" />
                ) : (
                  "Approve"
                )}
              </Button>
              <Button variant="secondary" type="button" onClick={onCancelClick}>
                Cancel
              </Button>
            </Flex>
          </form>
        </Card>
      )}
    </Container>
  );
};

export default withRouter(ApproveEventScreen);
