/** Constants */
import {
  SET_ALL,
  SET_OWN,
  SET_SUBSCRIBED_TO_ALL,
  SET_SUBSCRIBED_TO_OWN,
  SET_AMBASSADORS,
  SET_SUBSCRIBED_TO_AMBASSADORS,
  SET_EVENT_CREATORS,
  SET_SUBSCRIBED_TO_EVENT_CREATORS
} from "./users.constants";

/** Types */
import { TUserFirestore } from "@typings/users/users.types";
import { TUsersAction, IUsersState } from "./users.types";

/**
 * Action Creators (For use by store, _do not import these directly into components_)
 */

export const setAll: ActionCreator<
  IUsersState,
  TUsersAction<TUserFirestore[]>
> = (state, dispatch) => (value: TUserFirestore[]) => {
  dispatch({
    type: SET_ALL,
    payload: value
  });
};

export const setOwn: ActionCreator<
  IUsersState,
  TUsersAction<TUserFirestore>
> = (state, dispatch) => (value: TUserFirestore) => {
  dispatch({
    type: SET_OWN,
    payload: value
  });
};

export const setSubscribedToAll: ActionCreator<
  IUsersState,
  TUsersAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_ALL,
    payload: value
  });
};

export const setSubscribedToOwn: ActionCreator<
  IUsersState,
  TUsersAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_OWN,
    payload: value
  });
};

export const setEventCreators: ActionCreator<
  IUsersState,
  TUsersAction<TUserFirestore[]>
> = (state, dispatch) => (value: TUserFirestore[]) => {
  dispatch({
    type: SET_EVENT_CREATORS,
    payload: value
  });
};

export const setSubscribedToEventCreators: ActionCreator<
  IUsersState,
  TUsersAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_EVENT_CREATORS,
    payload: value
  });
};

export const setAmbassadors: ActionCreator<
  IUsersState,
  TUsersAction<TUserFirestore[]>
> = (state, dispatch) => (value: TUserFirestore[]) => {
  dispatch({
    type: SET_AMBASSADORS,
    payload: value
  });
};

export const setSubscribedToAmbassadors: ActionCreator<
  IUsersState,
  TUsersAction<boolean>
> = (state, dispatch) => (value: boolean) => {
  dispatch({
    type: SET_SUBSCRIBED_TO_AMBASSADORS,
    payload: value
  });
};
