/** Lib */
import React, { HTMLProps } from "react";

/** UI */
import { Box, Text, BoxProps } from "rebass";

/** Hooks */
import hooks from "./Checkbox.hooks";

/** Styles */
import {
  checkboxContainerStyles,
  checkboxLabelStyles,
  checkboxInputStyles
} from "./Checkbox.styles";

export type TInputProps = {
  name: string;
  id?: string;
  value?: string;
  label: string;
  required?: boolean;
  error?: string;
  containerProps?: BoxProps;
} & BoxProps &
  HTMLProps<HTMLInputElement>;

const Checkbox: React.ForwardRefExoticComponent<TInputProps> = React.forwardRef(
  (props, ref) => {
    /**
     * Props
     */
    const { error, disabled, containerProps } = props;

    /** Hooks */
    const { onInputBlur, onInputFocus } = hooks(props);

    return (
      <Box
        className="form-control checkbox"
        {...(containerProps ? containerProps : {})}
        css={{ cursor: "pointer" }}
      >
        <Box
          className="checkbox-container"
          sx={{ ...checkboxContainerStyles, whiteSpace: "nowrap" }}
        >
          <Box
            {...props}
            ref={ref ? ref : undefined}
            as="input"
            sx={{ ...checkboxInputStyles }}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            type="checkbox"
          />
          <Text
            as="label"
            variant="sectionHeading"
            htmlFor={props.id}
            sx={{ ...checkboxLabelStyles(disabled) }}
          >
            {props.label}
          </Text>
        </Box>
        {error && (
          <Text color="danger" fontSize={1} pt={1} pl={1}>
            {error}
          </Text>
        )}
      </Box>
    );
  }
);

export default Checkbox;
