import React from "react";
import * as firebase from "firebase/app";

import { useStore } from "@stores/auth/auth.store";

export default () => {
  const {
    state: { user },
  } = useStore();
  const [loginError, setLoginError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const params = new URL(window.location.href).searchParams;
        const signInFromEmail = params.get("signInFromEmail");
        const signInEmail = params.get("signInEmail");
        if (signInEmail && signInFromEmail) {
          await firebase
            .auth()
            .signInWithEmailLink(signInEmail, window.location.href);
        }
      } catch (err) {
        console.error("useAuthFromUrl: " + err);
        setLoginError(true);
      }
    })();
  }, []);

  return { user, loginError };
};
