/** Lib */
import React from "react";

/** Hooks */
import hooks from "./AccountDetails.hooks";

/** UI */
import { Container } from "@common/responsive";
import AccountDetails from "@components/accounts/AccountDetails.component";
import Loader from "@/ui/common/loader/Loader.common";
import { Flex } from "rebass";

const AccountDetailsScreen: React.FC<Router.RouteComponentProps<{
  id: string;
}>> = props => {
  const { account } = hooks(props);

  if (!account) {
    return (
      <Container>
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      </Container>
    );
  }

  return (
    <Container>
      <AccountDetails account={account} />
    </Container>
  );
};

export default AccountDetailsScreen;
