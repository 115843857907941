/**
 * Constants
 */
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_USER_DATA = "SET_USER_DATA";

const roles = {
  admin: "admin",
  superAdmin: "superAdmin",
  eventCreator: "eventCreator",
  brandAmbassador: "brandAmbassador"
};

type TRoles = typeof roles;
type TUserRoles = { [K in keyof TRoles]: AuthUserRoles };

export const USER_ROLES: TUserRoles = {
  admin: "admin",
  superAdmin: "superAdmin",
  eventCreator: "eventCreator",
  brandAmbassador: "brandAmbassador"
};
