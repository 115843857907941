/** Lib */
import React from "react";
import { Link } from "react-router-dom";

/** UI */
import { Flex, Text, Box, FlexProps } from "rebass";

/** Styles */
import { menuItemStyles, linkStyles, subLinkStyles } from "./MenuItem.styles";
import { IconLeftArrow } from "@common/icon/Icons.common";

const LinkBox = Box as any;
const SubLinkBox = Box as any;

const MenuItem: React.FC<{
  Icon: React.NamedExoticComponent;
  text: string;
  to: string;
  iconContainerProps?: FlexProps;
  subMenuItems?: { to: string; text: string }[];
  menuOpen: boolean;
  onClick?: (ev: React.MouseEvent<any>) => void;
}> = ({
  Icon,
  text,
  to,
  iconContainerProps = {},
  subMenuItems = [],
  menuOpen,
  onClick
}) => {
  /**
   * State
   */
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const subMenuRef = React.useRef<HTMLDivElement>();

  /** Hooks */
  React.useEffect(() => {
    /** Close submenu if menu is not open */
    if (subMenuOpen && !menuOpen) {
      setSubMenuOpen(false);
    }
  }, [menuOpen, subMenuOpen]);

  if (subMenuItems.length) {
    return (
      <Box
        color="slateGray"
        onClick={ev => {
          setSubMenuOpen(!subMenuOpen);
        }}
        sx={linkStyles}
      >
        <Flex
          className="menu-item"
          id="menu-home"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          padding="0 14px 0 32px"
          sx={menuItemStyles}
        >
          <Text>{text}</Text>
          <Flex
            className="menu-item-arrow"
            justifyContent="center"
            alignItems="center"
            sx={{
              svg: {
                fill: "slateGray",
                transition: "all 150ms ease-in-out",
                transform: subMenuOpen ? "rotate(-90deg)" : "none"
              }
            }}
          >
            <IconLeftArrow />
          </Flex>
          <Flex
            className="menu-item-icon"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
            bg="sharkGray"
            sx={{
              svg: {
                transition: "fill 150ms ease-in-out",
                width: "16px",
                fill: "slateGray"
              }
            }}
            {...iconContainerProps}
          >
            <Icon />
          </Flex>
        </Flex>
        <Flex
          ref={subMenuRef}
          className="sub-menu"
          bg="sharkGray"
          flexDirection="column"
          sx={{
            maxHeight:
              subMenuOpen && subMenuRef.current
                ? subMenuRef.current.scrollHeight + "px"
                : "0px",
            overflow: "hidden",
            transition: "max-height 150ms ease-in-out"
          }}
        >
          {subMenuItems.map(item => {
            return (
              <SubLinkBox
                as={Link}
                key={item.to}
                to={item.to}
                className="sub-menu-item"
                py={2}
                pl={4}
                pr="19px"
                sx={subLinkStyles}
                onClick={onClick}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Text>{item.text}</Text>
                  <Flex
                    bg="charadeGray"
                    width="32px"
                    height="32px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight={700}>{item.text.slice(0, 1)}</Text>
                  </Flex>
                </Flex>
              </SubLinkBox>
            );
          })}
        </Flex>
      </Box>
    );
  }
  return (
    <LinkBox
      as={Link}
      to={to}
      onClick={onClick}
      color="slateGray"
      sx={linkStyles}
    >
      <Flex
        className="menu-item"
        id="menu-home"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding="0 14px 0 32px"
        sx={menuItemStyles}
      >
        <Text>{text}</Text>
        <Flex
          className="menu-item-icon"
          justifyContent="center"
          alignItems="center"
          width="40px"
          height="40px"
          bg="sharkGray"
          sx={{
            svg: {
              transition: "fill 150ms ease-in-out",
              width: "16px",
              fill: "slateGray"
            }
          }}
          {...iconContainerProps}
        >
          <Icon />
        </Flex>
      </Flex>
    </LinkBox>
  );
};

export default MenuItem;
