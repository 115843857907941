/** Lib */
import React from "react";

/** UI */
import { Box, Button, Flex, Heading, Text } from "rebass";
import Input from "@common/form-elements/input/Input.common";
import Select from "@common/form-elements/select/Select.common.new";
import TextArea from "@common/form-elements/text-area/TextArea.common";
import FormRow from "@common/form-elements/form-row/FormRow.common";
import DatePicker from "@common/form-elements/date-picker/DatePicker.common";
/** Hooks */
import hooks from "./EventDetailsForm.hooks";

import { STATE_OPTIONS } from "@components/ambassador-profile-form/AmbassadorProfileForm.constants";

/** Types */
import { TFirestoreEvent, TFirestoreEventProtected } from "@typings/events/events.types";
import { USER_ROLES } from "@constants/users.constants";
import Loader from "@/ui/common/loader/Loader.common";
import { endOfDay, isSameDay } from "date-fns";

const inputContainerProps = {
  mb: [4, 0],
  flex: 1,
  px: 2,
};

const EventDetailsForm: React.FC<
  {
    theEvent: TFirestoreEvent;
    eventProtected?: TFirestoreEventProtected;
  } & Router.RouteComponentProps<{ id: string }>
> = (props) => {
  const {
    formik,
    accounts,
    submitSuccess,
    submitError,
    resetForm,
    loading,
    allFieldsDisabled,
    isWithinTenDayWindow,
    isPastEvent,
    user,
  } = hooks(props);

  if (submitSuccess) {
    return (
      <Box>
        <Heading color="success" variant="small" mb={2}>
          Event Updated
        </Heading>
        <Text mb={4}>Event updated successfully.</Text>
        <Button type="button" variant="secondary" onClick={resetForm}>
          Go Back
        </Button>
      </Box>
    );
  }

  if (submitError) {
    return (
      <Box>
        <Heading mb={3} variant="small" color="danger">
          Failed to Update Event: {submitError}
        </Heading>
        <Button type="button" onClick={resetForm}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box as="form" onSubmit={formik.handleSubmit as any}>
      {!isWithinTenDayWindow && user && user.claims.role === USER_ROLES.eventCreator ? (
        <Text color="danger" textAlign="center" mb={3}>
          Event not editable because it starts in less than 10 days or is in the past.
        </Text>
      ) : isPastEvent && user && [USER_ROLES.admin, USER_ROLES.superAdmin].includes(user.claims.role) ? (
        <Text color="danger" textAlign="center" mb={3}>
          Event not editable because it is in the past.
        </Text>
      ) : null}
      <Box mb={4}>
        <FormRow>
          <Select
            containerProps={inputContainerProps}
            name="accountName"
            label="Account Name"
            options={accounts.map((acc) => ({
              label: acc.accountName,
              value: acc.accountName,
            }))}
            required
            value={formik.values.accountName}
            onChange={(val) => formik.setFieldValue("accountName", val)}
            onBlur={formik.handleBlur}
            disabled={allFieldsDisabled || loading}
            error={formik.touched.accountName && formik.errors.accountName ? formik.errors.accountName : undefined}
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="requestersName"
            label="Requester's Name"
            value={formik.values.requestersName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.requestersName && formik.errors.requestersName ? formik.errors.requestersName : undefined
            }
          />
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="fundingSource"
            label="Funding Source"
            required
            value={formik.values.fundingSource}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.fundingSource && formik.errors.fundingSource ? formik.errors.fundingSource : undefined
            }
          />
        </FormRow>
        {props.eventProtected && (
          <>
            <FormRow>
              <Input
                containerProps={inputContainerProps}
                disabled={allFieldsDisabled || loading}
                name="budget"
                label="Budget ($)"
                required
                value={formik.values.budget}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.budget && formik.errors.budget ? formik.errors.budget : undefined}
              />
              <Input
                containerProps={inputContainerProps}
                disabled={allFieldsDisabled || loading}
                name="brandAmbassadorCount"
                label="Brand Ambassador Count"
                required
                value={formik.values.brandAmbassadorCount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandAmbassadorCount && formik.errors.brandAmbassadorCount
                    ? formik.errors.brandAmbassadorCount
                    : undefined
                }
              />
              <Input
                containerProps={inputContainerProps}
                disabled={allFieldsDisabled || loading}
                name="actualBarSpend"
                label="Actual Bar Spend ($)"
                required
                value={formik.values.actualBarSpend}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.actualBarSpend && formik.errors.actualBarSpend
                    ? formik.errors.actualBarSpend
                    : undefined
                }
              />
            </FormRow>
          </>
        )}
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="emailAddress"
            label="Email Address"
            required
            value={formik.values.emailAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.emailAddress && formik.errors.emailAddress ? formik.errors.emailAddress : undefined}
          />
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="phoneNumber"
            label="Phone Number"
            placeholder="(xxx) xxx-xxxx"
            required
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : undefined}
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="accountSpend"
            label="Account Spend"
            value={formik.values.accountSpend}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.accountSpend && formik.errors.accountSpend ? formik.errors.accountSpend : undefined}
          />
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="wodLocation"
            label="WOD Location"
            value={formik.values.wodLocation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.wodLocation && formik.errors.wodLocation ? formik.errors.wodLocation : undefined}
          />
        </FormRow>
        <FormRow>
          <DatePicker
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="eventStartTime"
            placeholderText="Click to Select Date"
            label="Event Start Date/Time"
            required
            selected={formik.values.eventStartTime as any}
            onChange={(value: any) => {
              formik.setFieldValue("eventStartTime", value);
              formik.setFieldValue("eventEndTime", value);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.eventStartTime && formik.errors.eventStartTime
                ? (formik.errors.eventStartTime as string)
                : undefined
            }
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            timeIntervals={15}
          />
          <DatePicker
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading || !formik.values.eventStartTime}
            name="eventEndTime"
            label="Event End Date/Time"
            required
            placeholderText="Click to Select Date"
            selected={formik.values.eventEndTime as any}
            onChange={(value: any) => formik.setFieldValue("eventEndTime", value)}
            onBlur={formik.handleBlur}
            error={
              formik.touched.eventEndTime && formik.errors.eventEndTime
                ? (formik.errors.eventEndTime as string)
                : undefined
            }
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            timeIntervals={15}
            minDate={formik.values.eventStartTime as any}
            minTime={
              formik.values.eventStartTime &&
              formik.values.eventEndTime &&
              isSameDay(formik.values.eventEndTime as unknown as Date, formik.values.eventStartTime as unknown as Date)
                ? (formik.values.eventStartTime as unknown as Date)
                : undefined
            } // + 15 mins
            maxTime={
              formik.values.eventStartTime &&
              formik.values.eventEndTime &&
              isSameDay(formik.values.eventEndTime as unknown as Date, formik.values.eventStartTime as unknown as Date)
                ? endOfDay(formik.values.eventStartTime as unknown as Date)
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="streetAddress"
            label="Street Address"
            required
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.streetAddress && formik.errors.streetAddress ? formik.errors.streetAddress : undefined
            }
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="city"
            label="City"
            required
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
          />
          <Select
            containerProps={inputContainerProps}
            options={[
              { label: "Off", value: "Off" },
              { label: "On", value: "On" },
            ]}
            disabled={allFieldsDisabled || loading}
            name="premise"
            label="Premise"
            required
            value={formik.values.premise}
            onChange={(val) => formik.setFieldValue("premise", val)}
            onBlur={formik.handleBlur}
            error={formik.touched.premise && formik.errors.premise ? formik.errors.premise : undefined}
          />
        </FormRow>
        <FormRow>
          <Select
            containerProps={inputContainerProps}
            options={STATE_OPTIONS}
            disabled={allFieldsDisabled || loading}
            name="state"
            label="State"
            required
            value={formik.values.state}
            onChange={(val) => formik.setFieldValue("state", val)}
            onBlur={formik.handleBlur}
            error={formik.touched.state && formik.errors.state ? formik.errors.state : undefined}
          />
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="zipCode"
            label="Zip Code"
            required
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zipCode && formik.errors.zipCode ? formik.errors.zipCode : undefined}
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="dayofEventAccountContact"
            label="Day of Event Account Contact"
            value={formik.values.dayofEventAccountContact}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.dayofEventAccountContact && formik.errors.dayofEventAccountContact
                ? formik.errors.dayofEventAccountContact
                : undefined
            }
          />
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="brand"
            label="Brand"
            required
            value={formik.values.brand}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.brand && formik.errors.brand ? formik.errors.brand : undefined}
          />
        </FormRow>
        <FormRow>
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="activationPlusUp"
            label="Activation Plus Up"
            required
            value={formik.values.activationPlusUp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.activationPlusUp && formik.errors.activationPlusUp
                ? formik.errors.activationPlusUp
                : undefined
            }
          />
          <Input
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="typeofEventPromotion"
            label="Type of Event / Promotion"
            value={formik.values.typeofEventPromotion}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.typeofEventPromotion && formik.errors.typeofEventPromotion
                ? formik.errors.typeofEventPromotion
                : undefined
            }
          />
        </FormRow>
        <FormRow>
          <TextArea
            containerProps={inputContainerProps}
            disabled={allFieldsDisabled || loading}
            name="eventNotes"
            label="Event Notes"
            rows={5}
            value={formik.values.eventNotes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.eventNotes && formik.errors.eventNotes ? formik.errors.eventNotes : undefined}
          />
        </FormRow>
      </Box>
      <Flex
        sx={{
          button: {
            marginX: 1,
            "&:first-of-type": {
              marginLeft: "0px",
            },
            "&:last-of-type": {
              marginRight: "0px",
            },
          },
        }}
      >
        {formik.dirty && (
          <Button type="submit" disabled={loading} variant={loading ? "primary.disabled" : "primary"}>
            {!loading ? "Update Event" : <Loader height="15px" width="15px" m="0 auto" />}
          </Button>
        )}
        {!loading && formik.dirty && (
          <Button type="button" variant="secondary" onClick={formik.handleReset}>
            Reset Form
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default EventDetailsForm;
