import { SET_AUTHENTICATED, SET_USER_DATA } from './auth.constants';

export default (state: AuthState, action: AuthAction<any>) => {
    switch (action.type) {
        case SET_AUTHENTICATED: {
            return {
                ...state,
                authenticated: action.payload,
            };
        }
        case SET_USER_DATA: {
            return {
                ...state,
                user: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
