/** Lib */
import React from "react";
import * as firebase from "firebase/app";
import { useFormik } from "formik";

/** Hooks */
import useSubscribeToAccounts from "@/hooks/useSubscribeToAccounts";

/** Stores */
import { useStore as useAuthStore } from "@stores/auth/auth.store";

/** Types */
import {
  firestoreEventFormSchema,
  firestoreEventProtectedSchema,
} from "@schemas/events/events.schemas";
import { serializeUpdateFormEvent } from "@/services/events/events.serializer";
import eventsService from "@/services/events/events.service";
import {
  TFirestoreEvent,
  TFirestoreEventProtected,
  TFirestoreFormEvent,
} from "@typings/events/events.types";

/** Constants */
import { USER_ROLES } from "@constants/users.constants";
import { convertToTimeZone } from "date-fns-timezone";
import { statuses } from "@/stores/events/events.constants";

const getEventValueString = (ev: any, key: any): string => {
  return ev && typeof ev[key] === "string" ? ev[key] : "";
};

const getEventValueSelect = (
  ev: any,
  key: any
): { label: string; value: string } | undefined => {
  return ev && typeof ev[key] === "string"
    ? { label: ev[key], value: ev[key] }
    : undefined;
};

export default (
  props: {
    theEvent: TFirestoreEvent;
    eventProtected?: TFirestoreEventProtected;
  } & Router.RouteComponentProps<{
    id: string;
  }>
) => {
  /**
   * State
   */
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>();
  const [submitError, setSubmitError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  /**
   * Stores/Hooks
   */
  const { accounts } = useSubscribeToAccounts();
  const {
    state: { user },
  } = useAuthStore();

  /**
   * Props
   */
  const { theEvent, eventProtected } = props;

  const isWithinTenDayWindow = React.useMemo(() => {
    const now = firebase.firestore.Timestamp.now().seconds;
    const start = theEvent.eventStartTime.seconds;
    return start > now && start - now >= 864000; // 864000 seconds == 10 Days.
  }, [theEvent]);

  const isPastEvent = React.useMemo(() => {
    return theEvent && new Date(theEvent.eventStartTime.seconds * 1000) < new Date();
  }, [theEvent]);

  const allFieldsDisabled = React.useMemo(() => {
    if (!user) {
      return true;
    }
    if (theEvent.status === statuses.executed || isPastEvent) {
      return true;
    }
    if (
      ![
        USER_ROLES.admin,
        USER_ROLES.superAdmin,
        USER_ROLES.eventCreator,
      ].includes(user.claims.role)
    ) {
      return true;
    }
    if ([USER_ROLES.admin, USER_ROLES.superAdmin].includes(user.claims.role)) {
      return false;
    }
    if (!isWithinTenDayWindow) {
      return true;
    }
    return false;
  }, [user, isWithinTenDayWindow, theEvent, isPastEvent]);

  const formik = useFormik({
    initialValues: {
      accountName: getEventValueSelect(theEvent, "accountName"),
      fundingSource: getEventValueString(theEvent, "fundingSource"),
      requestersName: getEventValueString(theEvent, "requestersName"),
      emailAddress: getEventValueString(theEvent, "emailAddress"),
      phoneNumber: getEventValueString(theEvent, "phoneNumber"),
      accountSpend: getEventValueString(theEvent, "accountSpend"),
      wodLocation: getEventValueString(theEvent, "wodLocation"),
      eventStartTime: theEvent
        ? convertToTimeZone(theEvent.eventStartTime.toDate(), {
            timeZone: theEvent.timezone,
          })
        : "",
      eventEndTime: theEvent
        ? convertToTimeZone(theEvent.eventEndTime.toDate(), {
            timeZone: theEvent.timezone,
          })
        : "",
      streetAddress: getEventValueString(theEvent, "streetAddress"),
      city: getEventValueString(theEvent, "city"),
      state: getEventValueSelect(theEvent, "state"),
      premise: getEventValueSelect(theEvent, "premise"),
      zipCode: getEventValueString(theEvent, "zipCode"),
      dayofEventAccountContact: getEventValueString(
        theEvent,
        "dayofEventAccountContact"
      ),
      brand: getEventValueString(theEvent, "brand"),
      activationPlusUp: getEventValueString(theEvent, "activationPlusUp"),
      typeofEventPromotion: getEventValueString(
        theEvent,
        "typeofEventPromotion"
      ),
      eventNotes: getEventValueString(theEvent, "eventNotes"),
      status: getEventValueString(theEvent, "status"),
      budget: getEventValueString(eventProtected, "brandAmbassadorCount"),
      brandAmbassadorCount: getEventValueString(
        eventProtected,
        "brandAmbassadorCount"
      ),
      actualBarSpend: getEventValueString(eventProtected, "actualBarSpend"),
    },
    validationSchema: !eventProtected
      ? firestoreEventFormSchema
      : firestoreEventFormSchema.concat(firestoreEventProtectedSchema),
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      setLoading(true);
      const response = await firebase
        .functions()
        .httpsCallable("getTimezones")([{ zip: values.zipCode }])
        .catch((err) => {
          console.error("EventDetailsForm.hooks: formik.onSubmit", err);
        });

      if (!response || !response.data.length || !response.data[0].timezone) {
        return formikHelpers.setFieldError("city", "City is invalid.");
      }

      if (eventProtected) {
        /**
         * * Update protected and event.
         */
        const {
          budget,
          brandAmbassadorCount,
          actualBarSpend,
          ...restEventFields
        } = values as TFirestoreEventProtected;

        const sEvent = serializeUpdateFormEvent({
          ...(restEventFields as TFirestoreFormEvent),
          id: theEvent.id,
          state: values.state!.value,
          accountName: values.accountName!.value,
          premise: values.premise!.value,
          createdBy: theEvent.createdBy,
          eventStartTime: (values.eventStartTime as unknown) as Date,
          eventEndTime: (values.eventEndTime as unknown) as Date,
          timezone: response.data[0].timezone,
        });

        const diffDays =
          (sEvent.eventStartTime - (new Date() as any)) / 1000 / 60 / 60 / 24;
        const isInTenDayWindow = diffDays >= 10;

        if (!isInTenDayWindow && user?.claims.role !== USER_ROLES.admin) {
          setLoading(false);
          return formikHelpers.setFieldError(
            "eventStartTime",
            "Start Date/Time must be at least 10 days in the future"
          );
        }

        if (sEvent.eventStartTime > sEvent.eventEndTime) {
          setLoading(false);
          return formikHelpers.setFieldError(
            "eventEndTime",
            "End Date/Time must be after Start Date/Time"
          );
        }

        try {
          await eventsService.updateEventProtected(theEvent.id, {
            budget,
            brandAmbassadorCount,
            actualBarSpend,
          });
          await eventsService.update(theEvent.id, sEvent);
          setSubmitSuccess(true);
          return setLoading(false);
        } catch (err) {
          setLoading(false);
          setSubmitSuccess(false);
          setSubmitError(err.toString());
          return console.error("Error Updating Event: ", err);
        }
      }

      /**
       * * Or just update event.
       */

      const sEvent = serializeUpdateFormEvent({
        ...values,
        id: theEvent.id,
        state: values.state!.value,
        accountName: values.accountName!.value,
        premise: values.premise!.value,
        createdBy: theEvent.createdBy,
        eventStartTime: (values.eventStartTime as unknown) as Date,
        eventEndTime: (values.eventEndTime as unknown) as Date,
        timezone: response.data[0].timezone,
        business: ''
      });

      const diffDays =
        (sEvent.eventStartTime - (new Date() as any)) / 1000 / 60 / 60 / 24;
      const isInTenDayWindow = diffDays >= 10;

      if (!isInTenDayWindow && user?.claims.role !== USER_ROLES.admin) {
        setLoading(false);
        return formikHelpers.setFieldError(
          "eventStartTime",
          "Start Date/Time must be at least 10 days in the future"
        );
      }

      if (sEvent.eventStartTime > sEvent.eventEndTime) {
        setLoading(false);
        return formikHelpers.setFieldError(
          "eventEndTime",
          "End Date/Time must be after Start Date/Time"
        );
      }

      try {
        await eventsService.update(theEvent.id, sEvent);
        setSubmitSuccess(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setSubmitSuccess(false);
        setSubmitError(err.toString());
        console.error("Error Updating Event: ", err);
      }
    },
  });

  React.useEffect(() => {
    if (theEvent) {
      const account = accounts.find(
        (acc) =>
          acc.accountName.toLowerCase() === theEvent.accountName.toLowerCase()
      );

      formik.resetForm({
        ...theEvent,
        eventStartTime: new Date(),
        eventEndTime: new Date(),
        accountName: account ? account.accountName : "",
      } as any);
    }
    // eslint-disable-next-line
  }, [theEvent, accounts]);

  const resetForm = React.useCallback(() => {
    setSubmitSuccess(undefined);
    setSubmitError(undefined);
    formik.handleReset(null);
  }, [formik]);

  return {
    formik,
    accounts,
    submitError,
    submitSuccess,
    resetForm,
    loading,
    allFieldsDisabled,
    isWithinTenDayWindow,
    isPastEvent,
    user,
  };
};
