import React from "react";

export type TIconComponent = React.NamedExoticComponent<{ title?: string }>;

export const IconHome: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
    </svg>
  );
});

export const IconCalendar: TIconComponent = React.memo(props => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M2 26h4.5v-4.5h-4.5v4.5zM7.5 26h5v-4.5h-5v4.5zM2 20.5h4.5v-5h-4.5v5zM7.5 20.5h5v-5h-5v5zM2 14.5h4.5v-4.5h-4.5v4.5zM13.5 26h5v-4.5h-5v4.5zM7.5 14.5h5v-4.5h-5v4.5zM19.5 26h4.5v-4.5h-4.5v4.5zM13.5 20.5h5v-5h-5v5zM8 7v-4.5c0-0.266-0.234-0.5-0.5-0.5h-1c-0.266 0-0.5 0.234-0.5 0.5v4.5c0 0.266 0.234 0.5 0.5 0.5h1c0.266 0 0.5-0.234 0.5-0.5zM19.5 20.5h4.5v-5h-4.5v5zM13.5 14.5h5v-4.5h-5v4.5zM19.5 14.5h4.5v-4.5h-4.5v4.5zM20 7v-4.5c0-0.266-0.234-0.5-0.5-0.5h-1c-0.266 0-0.5 0.234-0.5 0.5v4.5c0 0.266 0.234 0.5 0.5 0.5h1c0.266 0 0.5-0.234 0.5-0.5zM26 6v20c0 1.094-0.906 2-2 2h-22c-1.094 0-2-0.906-2-2v-20c0-1.094 0.906-2 2-2h2v-1.5c0-1.375 1.125-2.5 2.5-2.5h1c1.375 0 2.5 1.125 2.5 2.5v1.5h6v-1.5c0-1.375 1.125-2.5 2.5-2.5h1c1.375 0 2.5 1.125 2.5 2.5v1.5h2c1.094 0 2 0.906 2 2z"></path>
    </svg>
  );
});

export const IconCalendarAdd: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M24 4c1.094 0 2 .906 2 2v20c0 1.094-.906 2-2 2H2c-1.094 0-2-.906-2-2V6c0-1.094.906-2 2-2h2V2.5C4 1.125 5.125 0 6.5 0h1C8.875 0 10 1.125 10 2.5V4h6V2.5C16 1.125 17.125 0 18.5 0h1C20.875 0 22 1.125 22 2.5V4h2zm-6-1.5V7c0 .281.219.5.5.5h1c.281 0 .5-.219.5-.5V2.5c0-.281-.219-.5-.5-.5h-1c-.281 0-.5.219-.5.5zm-12 0V7c0 .281.219.5.5.5h1c.281 0 .5-.219.5-.5V2.5c0-.281-.219-.5-.5-.5h-1c-.281 0-.5.219-.5.5zM24 26V10H2v16h22zm-10-9h3.5c.281 0 .5.219.5.5v1c0 .281-.219.5-.5.5H14v3.5c0 .281-.219.5-.5.5h-1a.494.494 0 01-.5-.5V19H8.5a.494.494 0 01-.5-.5v-1c0-.281.219-.5.5-.5H12v-3.5c0-.281.219-.5.5-.5h1c.281 0 .5.219.5.5V17z"></path>
    </svg>
  );
});

export const IconUsers: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M15.984 12.984q1.313 0 2.859.375t2.859 1.219 1.313 1.922v2.484h-6V16.5q0-2.063-1.969-3.469.328-.047.938-.047zm-7.968 0q1.313 0 2.859.375t2.836 1.219T15 16.5v2.484H.984V16.5q0-1.078 1.313-1.922t2.859-1.219 2.859-.375zm0-1.968q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891 2.086.891.867 2.109-.867 2.109-2.086.891zm7.968 0q-1.219 0-2.109-.891t-.891-2.109.891-2.109 2.109-.891 2.109.891.891 2.109-.891 2.109-2.109.891z"></path>
    </svg>
  );
});

export const IconLeftArrow: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M15.422 16.594L14.016 18l-6-6 6-6 1.406 1.406L10.828 12z"></path>
    </svg>
  );
});

export const IconDownArrow: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M7.406 8.578L12 13.172l4.594-4.594L18 9.984l-6 6-6-6z"></path>
    </svg>
  );
});

export const IconAccounts: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path fill="none" d="M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z"></path>
      <path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z"></path>
    </svg>
  );
});

export const IconBusiness: TIconComponent = React.memo(props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    {props.title && <title>{props.title}</title>}
    <path d="M18 15v2.016h-2.016V15H18zm0-3.984v1.969h-2.016v-1.969H18zm2.016 7.968V9H12v2.016h2.016v1.969H12v2.016h2.016v2.016H12v1.969h8.016zm-10.032-12V5.015H8.015v1.969h1.969zm0 4.032V9H8.015v2.016h1.969zm0 3.984v-2.016H8.015V15h1.969zm0 3.984v-1.969H8.015v1.969h1.969zM6 6.984V5.015H3.984v1.969H6zm0 4.032V9H3.984v2.016H6zM6 15v-2.016H3.984V15H6zm0 3.984v-1.969H3.984v1.969H6zm6-12h9.984V21H2.015V3h9.984v3.984z"></path>
  </svg>
  );
});

export const IconCheck: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M9 16.172L19.594 5.578 21 6.984l-12 12-5.578-5.578L4.828 12z"></path>
    </svg>
  );
});

export const IconClear: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
    </svg>
  );
});

export const IconPeopleOutline: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M16.5 13c-1.2 0-3.07.34-4.5 1-1.43-.67-3.3-1-4.5-1C5.33 13 1 14.08 1 16.25V19h22v-2.75c0-2.17-4.33-3.25-6.5-3.25zm-4 4.5h-10v-1.25c0-.54 2.56-1.75 5-1.75s5 1.21 5 1.75v1.25zm9 0H14v-1.25c0-.46-.2-.86-.52-1.22.88-.3 1.96-.53 3.02-.53 2.44 0 5 1.21 5 1.75v1.25zM7.5 12c1.93 0 3.5-1.57 3.5-3.5S9.43 5 7.5 5 4 6.57 4 8.5 5.57 12 7.5 12zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 5.5c1.93 0 3.5-1.57 3.5-3.5S18.43 5 16.5 5 13 6.57 13 8.5s1.57 3.5 3.5 3.5zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"></path>
    </svg>
  );
});

export const IconHamburger: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </svg>
  );
});

export const IconEdit: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
    </svg>
  );
});

export const IconEmail: TIconComponent = React.memo(props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.title && <title>{props.title}</title>}
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
    </svg>
  );
});
