/** Lib */
import React from "react";
import * as yup from "yup";
import { useTable, useSortBy } from "react-table";
import ReactTooltip from "react-tooltip";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useFormik } from "formik";

/** UI */
import { Button } from "rebass";

/** Services */
import UsersService from "@services/users/users.service";
import { Row } from "react-table";
import formatPhoneNumber from "@util/formatPhone";

/** Hooks */
import useSubscribeToAllUsers from "@/hooks/useSubscribeToAllUsers";
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";
import { TUserFirestore } from "@typings/users/users.types";
import { IconClear, IconEmail } from "@/ui/common/icon/Icons.common";
import { USER_ROLES } from "@constants/users.constants";
import Loader from "@/ui/common/loader/Loader.common";
import { TSelectVal } from "@/ui/common/form-elements/select/Select.common.new";

const roleMap: { [key: string]: string } = {
  brandAmbassador: "Brand Ambassador",
  admin: "Admin",
  superAdmin: "Super Admin",
  eventCreator: "Event Creator",
};

//
// ──────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: M A N A G E   U S E R S   S C R E E N   H O O KS : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────
//

export default (props: Router.RouteComponentProps) => {
  // const { history } = props;

  //
  // ─── SUBSCRIBE HOOKS ────────────────────────────────────────────────────────────
  //

  const { allUsers } = useSubscribeToAllUsers();
  const { business } = useSubscribeToBusiness();

  /**
   * @STATE
   */
  const [activeUsers, setActiveUsers] = React.useState<Array<TUserFirestore>>(
    []
  );
  const [pendingUsers, setPendingUsers] = React.useState<Array<TUserFirestore>>(
    []
  );
  const [inviteLoading, setInviteLoading] = React.useState(false);
  const [resendLoading, setResendLoading] = React.useState<{
    [key: string]: boolean;
  }>({});

  /**
   * @FORMIK
   */
  const formik = useFormik({
    initialValues: {
      email: "",
      role: undefined as TSelectVal,
      business: ""
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
          "Email invalid."
        )
        .required("Email is required."),
      role: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup
            .string()
            .oneOf([
              USER_ROLES.admin,
              USER_ROLES.brandAmbassador,
              USER_ROLES.eventCreator,
            ])
            .required("Role is required."),
        })

        .required("Role is required."),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        setInviteLoading(true);
        const exists = await UsersService.getByEmail(values.email);

        if (exists.docs.length) {
          toast('User already exists.');
          formikHelpers.resetForm();
          setInviteLoading(false);
          return;
        }

        await UsersService.inviteUser(
          values.email,
          values.role!.value as AuthUserRoles,
          values.business,
        );
        toast(`Invite sent to ${values.email}`);
        setInviteLoading(false);
        formikHelpers.resetForm();
      } catch (err) {
        toast(`Sending Invite Failed: ${err}`);
        setInviteLoading(false);
        console.error("ManageUsers.hooks: onSubmit: Error Inviting Email", err);
      }
    },
  });

  //
  // ─── EFFECTS ────────────────────────────────────────────────────────────────────
  //

  /**
   * Effect parse pending and active users
   */
  React.useEffect(() => {
    const users = allUsers.reduce(
      (pUsers, usr) => {
        return usr.active
          ? {
              activeUsers: [...pUsers.activeUsers, usr],
              pendingUsers: pUsers.pendingUsers,
            }
          : {
              activeUsers: pUsers.activeUsers,
              pendingUsers: [...pUsers.pendingUsers, usr],
            };
      },
      { activeUsers: [], pendingUsers: [] } as {
        activeUsers: Array<firebase.firestore.DocumentData>;
        pendingUsers: Array<firebase.firestore.DocumentData>;
      }
    );
    setActiveUsers(users.activeUsers as Array<TUserFirestore>);
    setPendingUsers(users.pendingUsers as Array<TUserFirestore>);
  }, [allUsers]);

  //
  // ─── HANDLERS/CALLBACKS ───────────────────────────────────────────────────────────────────
  //

  /**
   * <tr> Row Click callback, load user route.
   */
  const onRowClick = React.useCallback(
    (row: Row<TUserFirestore>) => (ev: React.MouseEvent) => {
      // history.push(`/users/${row.original.id}`, row.original);
    },
    // [history]
    []
  );
  /**
   * On delete click.
   */
  const onDeleteClick = React.useCallback(
    (id: string) => async (ev: React.MouseEvent) => {
      // stop propagation so it doesn't trigger row <tr> click.
      ev.stopPropagation();
      confirmAlert({
        title: "Are you sure you want to Delete this User?",
        message: "This will delete this user entirely from the platform.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                await UsersService.delete(id);
                toast("User has been deleted.");
              } catch (err) {
                toast("Error deleting User: " + err.toString());
                console.error(
                  "AdminDashboard.hook: onDenyClick: Error Updating Event."
                );
              }
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    []
  );
  /**
   * On delete click.
   */
  const onResendEmailClick = React.useCallback(
    (id: string, role: AuthUserRoles, email: string) => async (
      ev: React.MouseEvent
    ) => {
      // stop propagation so it doesn't trigger row <tr> click.
      ev.stopPropagation();
      setResendLoading({ [id]: true });
      try {
        await UsersService.resendInviteUser(id, email, role);
        setResendLoading({ [id]: false });
        toast("Invite re-sent.");
      } catch (err) {
        setResendLoading({ [id]: false });
        toast("Error resending " + err.toString());
        console.error("Error resending");
      }
    },
    []
  );

  const onBusinessSelection = React.useCallback(
    (ev: any) => {
      formik.setFieldValue('business', ev.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //
  // ─── MEMOS ──────────────────────────────────────────────────────────────────────
  //

  const activeColumns = React.useMemo(() => {
    return [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Business Entity",
        accessor: "business",
        Cell: (context: any) => {
          const businessId = context.row.original.business;
          
          return business?.find(item=>item.id === businessId)?.accountName || ''
        }
      },
      {
        Header: "Phone",
        accessor: (row: TUserFirestore) =>
          formatPhoneNumber(row.cellNumber) as string,
      },
      {
        Header: "Role",
        accessor: (row: TUserFirestore) => roleMap[row.role] as string,
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }: { row: { original: TUserFirestore } }) => {
          return (
            <>
              <Button
                type="button"
                variant="blank"
                onClick={onDeleteClick(row.original.id)}
                data-tip="Delete User"
                sx={{
                  svg: {
                    fill: "danger",
                    width: "16px",
                  },
                }}
              >
                <ReactTooltip />
                <IconClear />
              </Button>
            </>
          );
        },
      },
    ];
  }, [onDeleteClick, business]);
  /**
   * Define columns for inactive user table.
   */
  const inactiveColumns = React.useMemo(() => {
    return [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Business Entity",
        accessor: "business",
        Cell: (context: any) => {
          const businessId = context.row.original.business;
          
          return business?.find(item=>item.id === businessId)?.accountName || ''
        }
      },
      {
        Header: "Phone",
        accessor: (row: TUserFirestore) =>
          formatPhoneNumber(row.cellNumber) as string,
      },
      {
        Header: "Role",
        accessor: (row: TUserFirestore) => roleMap[row.role],
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }: { row: { original: TUserFirestore } }) => {
          return (
            <>
              {!resendLoading[row.original.id] ? (
                <>
                  <Button
                    type="button"
                    variant="blank"
                    onClick={onResendEmailClick(
                      row.original.id,
                      row.original.role as AuthUserRoles,
                      row.original.email
                    )}
                    data-tip="Resend Invite"
                    sx={{
                      mr: 3,
                      svg: {
                        fill: "#999",
                        width: "16px",
                      },
                    }}
                  >
                    <ReactTooltip />
                    <IconEmail />
                  </Button>
                  <Button
                    type="button"
                    variant="blank"
                    onClick={onDeleteClick(row.original.id)}
                    data-tip="Delete User"
                    sx={{
                      svg: {
                        fill: "danger",
                        width: "16px",
                      },
                    }}
                  >
                    <ReactTooltip />
                    <IconClear />
                  </Button>
                </>
              ) : (
                <Loader
                  width="100%"
                  sx={{
                    "& > div": {
                      right: 0,
                      left: "auto",
                    },
                  }}
                />
              )}
            </>
          );
        },
      },
    ];
  }, [onDeleteClick, onResendEmailClick, resendLoading, business]);

  //
  // ─── TABLE HOOKS ────────────────────────────────────────────────────────────────
  //

  /**
   * activeUsers table hooks
   */
  const activeTable = useTable<TUserFirestore>(
    {
      columns: activeColumns,
      data: activeUsers || [],
      initialState: {
        sortBy: [{ id: "lastName", desc: false }],
      } as any,
    },
    useSortBy
  );

  /**
   * pendingUsers table hooks
   */
  const inactiveTable = useTable<TUserFirestore>(
    {
      columns: inactiveColumns,
      data: pendingUsers || [],
      initialState: {
        sortBy: [{ id: "lastName", desc: false }],
      } as any,
    },
    useSortBy
  );

  return {
    activeUsers,
    pendingUsers,
    activeTable,
    inactiveTable,
    onRowClick,
    inviteLoading,
    formik,
    onBusinessSelection
  };
};
