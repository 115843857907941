/** Lib */
import React from "react";

/** UI */
import { Box, Heading, Flex, Button } from "rebass";

/** Hooks */
import hooks from "./ManageUsers.hooks";
import useSubscribeToBusiness from "@/hooks/useSubscribeToAllBusiness";

/** Styles */
import { tableStyles } from "./ManageUsers.styles";
import { Container } from "@common/responsive";
import FormRow from "@/ui/common/form-elements/form-row/FormRow.common";
import Select from "@/ui/common/form-elements/select/Select.common.new";
import { USER_ROLES } from "@/stores/auth/auth.constants";
import Input from "@/ui/common/form-elements/input/Input.common";
import Loader from "@/ui/common/loader/Loader.common";

const ManageUsers: React.FC<Router.RouteComponentProps> = (props) => {
  const {
    activeUsers,
    pendingUsers,
    activeTable,
    inactiveTable,
    onRowClick,
    formik,
    inviteLoading,
    onBusinessSelection,
  } = hooks(props);
  const { business } = useSubscribeToBusiness();

  const { handleSubmit } = formik;

  if (!activeUsers && !pendingUsers) {
    return (
      <Container>
        <Flex justifyContent="center" alignItems="center">
          <Loader className="la-3x" />
        </Flex>
      </Container>
    );
  }

  const businessLabel = business.find((bu) => bu.id === formik.values.business)?.accountName ?? ``;

  return (
    <Container pt={4}>
      {/**
       * Active Users Table
       */}
      <Flex mb={4} justifyContent="space-between">
        <Heading as="h1" variant="medium.center">
          Manage Users
        </Heading>
        <Box as="form" onSubmit={handleSubmit as any}>
          <Flex>
            <FormRow
              sx={{
                alignItems: "center",
                mb: 0,
              }}
            >
              <Input
                containerProps={{
                  sx: {
                    position: "relative",
                    ".input-error": {
                      position: "absolute",
                    },
                  },
                }}
                name="email"
                label="Email Address"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
              />

              <Select
                containerProps={{ sx: { minWidth: "150px", mx: 2 } }}
                options={[
                  { label: "Admin", value: USER_ROLES.admin },
                  { label: "Event Creator", value: USER_ROLES.eventCreator },
                  { label: "Brand Ambassador", value: USER_ROLES.brandAmbassador },
                ]}
                name="role"
                label="Role"
                required
                value={formik.values.role}
                onChange={(val) => formik.setFieldValue("role", val)}
                onBlur={formik.handleBlur}
                error={formik.touched.role && formik.errors.role ? formik.errors.role : undefined}
              />
              {formik.values.role?.value === USER_ROLES.eventCreator && (
                <Select
                  name="business"
                  containerProps={{ sx: { minWidth: "150px", mr: 2 } }}
                  label="Business Entity"
                  options={business.map((item) => ({
                    label: item.accountName,
                    value: item.id,
                  }))}
                  required
                  value={{
                    value: formik.values.business,
                    label: businessLabel,
                  }}
                  onChange={(ev) => onBusinessSelection(ev)}
                />
              )}
              <Button
                alignSelf="flex-end"
                type="submit"
                disabled={inviteLoading || !formik.dirty}
                variant={inviteLoading || !formik.dirty ? "primary.disabled" : "primary"}
              >
                {inviteLoading ? <Loader height="15px" width="15px" m="0 auto" /> : "Invite User"}
              </Button>
            </FormRow>
          </Flex>
        </Box>
      </Flex>
      <Heading variant="small" as="h2" mb={3}>
        Active Users
      </Heading>
      <Box overflowX="scroll" maxWidth="100%">
        <Box as="table" {...activeTable.getTableProps()} sx={tableStyles}>
          <thead>
            {activeTable.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...activeTable.getTableBodyProps()}>
            {activeTable.rows.map((row, i) => {
              activeTable.prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={onRowClick(row)}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Box>
      </Box>
      {/**
       * Disabled Users Table
       */}
      {!!pendingUsers.length && (
        <>
          <Heading variant="small" as="h2" mb={3}>
            Pending/Inactive Users
          </Heading>
          <Box overflowX="scroll" maxWidth="100%">
            <Box as="table" {...inactiveTable.getTableProps()} sx={tableStyles}>
              <thead>
                {inactiveTable.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...inactiveTable.getTableBodyProps()}>
                {inactiveTable.rows.map((row, i) => {
                  inactiveTable.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} onClick={onRowClick(row)}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ManageUsers;
