/** Lib */
import React from "react";
import { useFormik } from "formik";
import stringSimilarity from "string-similarity";

/** Services */
import AccountsService from "@services/accounts/accounts.service";

/** Hooks */
import useSubscribeToAccounts from "@/hooks/useSubscribeToAccounts";

/** Types */
import { accountFormSchema } from "@schemas/accounts/accounts.schemas";

/** Constants */
const ACCOUNT_SIMILARITY_THRESHOLD = 0.8;

export default () => {
  /** State */
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean | null>(
    null
  );
  const [submitError, setSubmitError] = React.useState<boolean | null>(null);
  const [possibleDuplicate, setPossibleDuplicate] = React.useState<
    string | boolean | null
  >(null);
  const [loading, setLoading] = React.useState(false);

  const { accounts } = useSubscribeToAccounts();

  /** Refs */
  const formRef = React.useRef<HTMLFormElement>();
  const formik = useFormik({
    initialValues: {
      accountName: ""
    },
    validationSchema: accountFormSchema,
    onSubmit: async values => {
      try {
        setLoading(true);
        await AccountsService.create(values);
        setSubmitSuccess(true);
        setLoading(false);
      } catch (err) {
        setSubmitSuccess(false);
        setSubmitError(err.toString());
        setLoading(false);
        console.error(
          "AddSingleAccountForm.hooks: onSubmit: Error Adding Single Event",
          err
        );
      }
    }
  });

  const checkForPossibleDuplicate = React.useCallback(
    (accountName: string, accountValues: string[]) => {
      const possibleDupe = stringSimilarity.findBestMatch(
        accountName,
        accountValues
      );
      if (possibleDupe.bestMatch.rating > ACCOUNT_SIMILARITY_THRESHOLD) {
        return possibleDupe.bestMatch.target;
      }
      return false;
    },
    []
  );

  const handleSubmit = React.useCallback(
    (submitAnyway?: boolean) => (
      ev: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
    ) => {
      ev.preventDefault();
      if (!submitAnyway) {
        const possible = checkForPossibleDuplicate(
          formik.values.accountName,
          accounts.map(account => account.accountName)
        ) as string | boolean;
        if (possible) {
          return setPossibleDuplicate(possible);
        }
        return formik.handleSubmit();
      }
      return formik.handleSubmit();
    },
    [accounts, checkForPossibleDuplicate, formik]
  );

  const onTryAgainClick = React.useCallback(
    ev => {
      formik.handleReset(ev);
      setSubmitSuccess(null);
      setSubmitError(null);
      setPossibleDuplicate(null);
    },
    [formik]
  );

  return {
    formik,
    submitSuccess,
    submitError,
    onTryAgainClick,
    loading,
    accounts,
    possibleDuplicate,
    formRef,
    handleSubmit
  };
};
